// import React, { useState } from 'react';
// import axios from 'axios';

// const OPENAI_API_KEY =''; 
// const BASE_URL = 'https://api.openai.com/v1/chat/completions';

// const ChatPage = () => {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');
//   const [loading, setLoading] = useState(false);

//   const handleSend = async () => {
//     if (!input.trim()) return;

//     // Add user message
//     const userMessage = { role: 'user', content: input };
//     setMessages([...messages, userMessage]);
//     setInput('');
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         BASE_URL,
//         {
//           model: 'gpt-4o',
//           messages: [...messages, userMessage],
//           max_tokens: 150,
//           temperature: 0.7,
//         },
//         {
//           headers: {
//             'Authorization': `Bearer ${OPENAI_API_KEY}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const aiMessage = response.data.choices[0].message.content.trim();
//       setMessages([...messages, userMessage, { role: 'assistant', content: aiMessage }]);
//     } catch (error) {
//       console.error('Error fetching response:', error);
//       setMessages([...messages, userMessage, { role: 'assistant', content: 'Error: Unable to get response' }]);
//     }

//     setLoading(false);
//   };

//   return (
//   <>
  

//         <h1>Ask Your Query</h1>
//       <div style={{ height: '400px', overflowY: 'scroll', border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}>
//         {messages.map((msg, index) => (
//           <div key={index} style={{ margin: '10px 0', textAlign: msg.role === 'user' ? 'right' : 'left' }}>
//             <div style={{ display: 'inline-block', padding: '10px', borderRadius: '5px', backgroundColor: msg.role === 'user' ? '#d1e7dd' : '#f8f9fa' }}>
//               {msg.content}
//             </div>
//           </div>
//         ))}
//         {loading && <div style={{ textAlign: 'center' }}>Loading...</div>}
//       </div>
//       <textarea
//         rows="3"
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         placeholder="Type your message here..."
//         style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', marginTop: '10px' }}
//       />
//       <button onClick={handleSend} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: '#fff', marginTop: '10px' }}>
//         Send
//       </button>
//       </>

//   );
// };

// export default ChatPage;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';

// const OPENAI_API_KEY = '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'; 
// const BASE_URL = 'https://api.openai.com/v1/chat/completions';

// const ChatPage = () => {
//   const [messages, setMessages] = useState([]);
//   const [input, setInput] = useState('');
//   const [loading, setLoading] = useState(false);
//   const messagesEndRef = useRef(null);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

//   useEffect(scrollToBottom, [messages]);

//   const handleSend = async () => {
//     if (!input.trim()) return;

//     // Add user message
//     const userMessage = { role: 'user', content: input };
//     setMessages([...messages, userMessage]);
//     setInput('');
//     setLoading(true);

//     try {
//       const response = await axios.post(
//         BASE_URL,
//         {
//           model: 'gpt-4',
//           messages: [...messages, userMessage],
//           max_tokens: 4096,
//           temperature: 0.7,
//         },
//         {
//           headers: {
//             'Authorization': `Bearer ${OPENAI_API_KEY}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       const aiMessage = response.data.choices[0].message.content.trim();
//       setMessages([...messages, userMessage, { role: 'assistant', content: aiMessage }]);
//     } catch (error) {
//       console.error('Error fetching response:', error);
//       setMessages([...messages, userMessage, { role: 'assistant', content: 'Error: Unable to get response' }]);
//     }

//     setLoading(false);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === 'Enter' && !e.shiftKey) {
//       e.preventDefault();
//       handleSend();
//     }
//   };

//   return (
//     <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '20px', boxSizing: 'border-box' }}>
//       <h1>Ask Your Query</h1>
//       <div style={{ flex: 1, overflowY: 'auto', border: '1px solid #ddd', padding: '10px', borderRadius: '5px', marginBottom: '10px', backgroundColor: '#f0f0f0' }}>
//         {messages.map((msg, index) => (
//           <div key={index} style={{ margin: '10px 0', textAlign: msg.role === 'user' ? 'right' : 'left' }}>
//             <div style={{ display: 'inline-block', padding: '10px', borderRadius: '5px', backgroundColor: msg.role === 'user' ? '#d1e7dd' : '#fff', boxShadow: '0px 0px 5px rgba(0,0,0,0.1)' }}>
//               {msg.content.split('\n').map((line, i) => (
//                 <div key={i}>{line}</div>
//               ))}
//             </div>
//           </div>
//         ))}
//         {loading && <div style={{ textAlign: 'center' }}>Loading...</div>}
//         <div ref={messagesEndRef} />
//       </div>
//       <textarea
//         rows="3"
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         onKeyDown={handleKeyDown}
//         placeholder="Type your message here..."
//         style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ddd', marginBottom: '10px' }}
//       />
//       <button onClick={handleSend} style={{ width: '100%', padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: '#fff' }}>
//         Send
//       </button>
//     </div>
//   );
// };

// export default ChatPage;


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

const OPENAI_API_KEY = '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'; 
const BASE_URL = 'https://api.openai.com/v1/chat/completions';

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSend = async () => {
    if (!input.trim()) return;

    // Add user message
    const userMessage = { role: 'user', content: input };
    setMessages([...messages, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const response = await axios.post(
        BASE_URL,
        {
          model: 'gpt-4',
          messages: [...messages, userMessage],
          max_tokens: 150,
          temperature: 0.7,
        },
        {
          headers: {
            'Authorization': `Bearer ${OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const aiMessage = response.data.choices[0].message.content.trim();
      setMessages([...messages, userMessage, { role: 'assistant', content: aiMessage }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setMessages([...messages, userMessage, { role: 'assistant', content: 'Error: Unable to get response' }]);
    }

    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div style={{ height: '600px' , borderRadius:'40px' }}>
      <MainContainer>
        <ChatContainer>
          <MessageList
            typingIndicator={loading && <TypingIndicator content="Assistant is typing" />}
          >
            {messages.map((msg, index) => (
              <Message
                key={index}
                model={{
                  message: msg.content,
                  sentTime: "just now",
                  sender: msg.role === 'user' ? 'You' : 'Assistant',
                  direction: msg.role === 'user' ? 'outgoing' : 'incoming',
                }}
              />
            ))}
            <div ref={messagesEndRef} />
          </MessageList>
          <MessageInput
            placeholder="Type your message here..."
            value={input}
            onChange={(value) => setInput(value)}
            onSend={handleSend}
            attachButton={false}
            autoFocus
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default ChatPage;
