import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authcontext';

const ProtectedRoute = ({ roles, children }) => {
  const { authData } = useAuth();

  if (!authData.user) {
    return <Navigate to="/login" replace />;
  }

  if (!roles.includes(authData.role)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
