import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useDomain } from '../component/context/DomainContext';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { TrendingPages, DecliningPages } from './TrendingPages';
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import TrendingCountries from './Trendingcountry';

const IndexedPages = () => {
  const { selectedLink } = useDomain();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState('Last 3 months');
  const [indexedPages, setIndexedPages] = useState([]);
  const [nonIndexedPages, setNonIndexedPages] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [trendingPages, setTrendingPages] = useState([]);
  const [decliningPages, setDecliningPages] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPages, setFilteredPages] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [pieChartData, setPieChartData] = useState({ data: [], totalCount: 0 });

  useEffect(() => {
    updateDateRange('Last 3 months');
  }, []);

  useEffect(() => {
    if (selectedLink && startDate && endDate) {
      setLoading(true); // Set loading to true when fetching data
      fetchSitemapPages(selectedLink);
    }
  }, [selectedLink, startDate, endDate]);

  useEffect(() => {
    filterPages();
  }, [searchQuery, indexedPages, nonIndexedPages]);

  const fetchSitemapPages = async (siteUrl) => {
    try {
      const response = await axios.get('https://brank.supportplus.app/v1/api/sitemap', {
        params: { siteUrl },
      });
      const sitemap = response.data || [];

      if (sitemap.length > 0) {
        await fetchIndexedPages(siteUrl, sitemap);
        await fetchClickData(siteUrl, sitemap);
      }
    } catch (error) {
      console.error('Error fetching sitemap pages', error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const fetchIndexedPages = async (siteUrl, sitemap) => {
    try {
      const response = await axios.get('https://brank.supportplus.app/v1/api/indexedpages', {
        params: { siteUrl, startDate, endDate },
      });

      if (Array.isArray(response.data.rows)) {
        const indexedData = response.data.rows.map(row => ({
          page: row.keys[0],
          clicks: row.clicks || 0,
          impressions: row.impressions || 0,
          ctr: row.ctr || 0,
          position: row.position || 0,
        }));

        const indexedPages = sitemap.filter(page => indexedData.some(data => data.page === page)).map((page, index) => {
          const data = indexedData.find(data => data.page === page);
          return {
            id: index,
            page: page,
            status: 'Indexed',
            clicks: data.clicks,
            impressions: data.impressions,
            ctr: data.ctr,
            position: data.position,
          };
        });

        const nonIndexedPages = sitemap.filter(page => !indexedData.some(data => data.page === page)).map((page, index) => ({
          id: index + indexedPages.length,
          page: page,
          status: 'Not Indexed',
          clicks: 0,
          impressions: 0,
          ctr: 0,
          position: 0,
        }));

        setIndexedPages(indexedPages);
        setNonIndexedPages(nonIndexedPages);
        updatePieChartData(indexedPages.length, nonIndexedPages.length);
       
      } else {
        console.error('Invalid data format received from backend');
      }
    } catch (error) {
      console.error('Error fetching indexed pages', error);
    }
  };

  const fetchClickData = async (siteUrl, sitemap) => {
    try {
      const responseNewClicks = await axios.get('https://brank.supportplus.app/v1/api/indexedpages', {
        params: { siteUrl, startDate, endDate },
      });
  
      const [oldStartDate, oldEndDate] = calculateOldDateRange(startDate, endDate);
  
      const responseOldClicks = await axios.get('https://brank.supportplus.app/v1/api/indexedpages', {
        params: { siteUrl, startDate: oldStartDate, endDate: oldEndDate },
      });
  
      const newClicksData = (Array.isArray(responseNewClicks.data.rows) ? responseNewClicks.data.rows : []).map((row, index) => ({
        id: `new-${index}`,
        page: row.keys && row.keys.length > 0 ? row.keys[0] : 'Unknown Page',
        clicks: row.clicks || 0,
        impressions: row.impressions || 0,
        ctr: row.ctr || 0,
        position: row.position || 0,
      }));
  
      const oldClicksData = (Array.isArray(responseOldClicks.data.rows) ? responseOldClicks.data.rows : []).map((row, index) => ({
        id: `old-${index}`,
        page: row.keys && row.keys.length > 0 ? row.keys[0] : 'Unknown Page',
        clicks: row.clicks || 0,
      }));
  
      const indexedPages = sitemap.map((page, index) => {
        const newData = newClicksData.find(data => data.page === page) || {};
        const oldData = oldClicksData.find(data => data.page === page) || {};
        return {
          id: `indexed-${index}`,
          page: page,
          status: newData.page ? 'Indexed' : 'Not Indexed',
          oldClicks: oldData.clicks || 0,
          newClicks: newData.clicks || 0,
          impressions: newData.impressions || 0,
          ctr: newData.ctr || 0,
          position: newData.position || 0,
        };
      });
  
      const nonIndexedPages = sitemap.filter(page => !newClicksData.some(data => data.page === page)).map((page, index) => ({
        id: `non-indexed-${index + indexedPages.length}`,
        page: page,
        status: 'Not Indexed',
        clicks: 0,
        impressions: 0,
        ctr: 0,
        position: 0,
      }));
  
      setIndexedPages(indexedPages);
      setNonIndexedPages(nonIndexedPages);
      updatePieChartData(indexedPages.length, nonIndexedPages.length);
      
      const { trending, declining } = calculateTrendingDecliningPages(sitemap, indexedPages);
      setTrendingPages(trending);
      setDecliningPages(declining);
    } catch (error) {
      console.error('Error fetching click data', error);
    }
  };
  
  const calculateOldDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    const diff = end.getTime() - start.getTime();
    
    const oldEndDate = new Date(start.getTime() - 1);
    const oldStartDate = new Date(oldEndDate.getTime() - diff);
  
    return [oldStartDate.toISOString().split('T')[0], oldEndDate.toISOString().split('T')[0]];
  };
  
  const calculateTrendingDecliningPages = (sitemap, indexedPages) => {
    const trending = [];
    const declining = [];
  
    indexedPages.forEach(page => {
      const oldClicks = page.oldClicks || 0;
      const newClicks = page.newClicks || 0;
  
      if (oldClicks > 0) {
        const trend = ((newClicks - oldClicks) / oldClicks) * 100;
        const trendData = Array.from({ length: 10 }, (_, index) => oldClicks + (index * (newClicks - oldClicks) / 10));
  
        const pageData = {
          page: page.page,
          oldClicks,
          newClicks,
          trend,
          trendData,
        };
  
        if (trend > 0) {
          trending.push(pageData);
        } else if (trend < 0) {
          declining.push(pageData);
        }
      }
    });
  
    trending.sort((a, b) => b.trend - a.trend);
    declining.sort((a, b) => a.trend - b.trend);
  
    return {
      trending: trending.slice(0, 10000),
      declining: declining.slice(0, 10000),
    };
  };
  
  const updatePieChartData = (indexedCount, nonIndexedCount) => {
    const totalCount = indexedCount + nonIndexedCount;
    const chartData = [
      { name: 'Indexed', value: indexedCount, color: '#4caf50' },
      // { name: 'Duplicate Canonical', value: 0, color: '#9e9e9e' },
      // { name: 'Soft 404', value: 0, color: '#f44336' },
      { name: 'Crawled, Not Indexed', value: nonIndexedCount, color: 'red' },
    ];
    setPieChartData({ data: chartData, totalCount });
  };

  const updateDateRange = (range) => {
    const end = new Date();
    let start;

    switch (range) {
      case 'Last 7 days':
        start = new Date(end.setDate(end.getDate() - 7));
        break;
      case 'Last 28 days':
        start = new Date(end.setDate(end.getDate() - 28));
        break;
      case 'Last 3 months':
        start = new Date(end.setMonth(end.getMonth() - 3));
        break;
      case 'Last 6 months':
        start = new Date(end.setMonth(end.getMonth() - 6));
        break;
      case 'Last 12 months':
        start = new Date(end.setFullYear(end.getFullYear() - 1));
        break;
      case 'Last 16 months':
        start = new Date(end.setMonth(end.getMonth() - 16));
        break;
      default:
        start = new Date(end.setMonth(end.getMonth() - 3));
    }

    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(new Date().toISOString().split('T')[0]);
    setDateRange(range);
  };

  const filterPages = () => {
    const allPages = [...indexedPages, ...nonIndexedPages];
    if (searchQuery) {
      setFilteredPages(
        allPages.filter(page => page.page.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    } else {
      setFilteredPages(allPages);
    }
  };

  const handlePieChartClick = (data) => {
    if (data.name === 'Indexed') {
      setFilteredPages(indexedPages);
    } else if (data.name === 'Crawled, Not Indexed') {
      setFilteredPages(nonIndexedPages);
    }
  };

  const columns = [
    {
      field: 'page',
      headerName: 'Page',
      width: 950,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <a href={params.value} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>
            {params.value}
          </a>
        </Box>
      ),
    },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  return (
    <Box>
      <Box sx={{ position: 'fixed',
    top: '60px', 
    left: '300px', 
    right: '0px',
    zIndex: 1000, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    padding: '10px 20px', 
    backgroundColor: '#f0f3f8' }}>
        <FormControl variant="outlined" sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
          <Select
            value={dateRange}
            onChange={(e) => updateDateRange(e.target.value)}
            // label="Date Range"
            sx={{
              backgroundColor: 'rgb(211, 227, 253)',
              borderRadius: '25px',
              '& .MuiSelect-select': {
                padding: '10px',
                borderRadius: '25px',
              },
            }}
          >
            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
            <MenuItem value="Last 28 days">Last 28 days</MenuItem>
            <MenuItem value="Last 3 months">Last 3 months</MenuItem>
            <MenuItem value="Last 6 months">Last 6 months</MenuItem>
            <MenuItem value="Last 12 months">Last 12 months</MenuItem>
            <MenuItem value="Last 16 months">Last 16 months</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Select a date range to filter the data." placement="top">
          <HelpOutlineIcon />
        </Tooltip>
        {/* <IconButton onClick={() => setSearchOpen(!searchOpen)}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      <Divider style={{ marginBottom: '20px' }} />

      {searchOpen && (
        <Box sx={{ marginBottom: '20px' }}>
          <Autocomplete
            freeSolo
            value={searchQuery}
            onChange={(event, newValue) => setSearchQuery(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Pages"
                variant="outlined"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            )}
          />
        </Box>
      )}
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px' , marginTop:'50px' }}>
   
        <Box sx={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieChartData.data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120}
                innerRadius={80}
                onClick={handlePieChartClick}
              >
                {pieChartData.data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
                <Label value={` ${pieChartData.totalCount} Pages`} position="center" />
              </Pie>
              <RechartsTooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '40px', marginTop: '50px' }}>
        <Box sx={{ position: 'relative', width: '100%', height: 300 }}>
          {loading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieChartData.data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  innerRadius={80}
                  onClick={handlePieChartClick}
                >
                  {pieChartData.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                  <Label value={`${pieChartData.totalCount} Pages`} position="center" />
                </Pie>
                <RechartsTooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Box>
      </Box>
      {loading ? (
        <Box>
          <Skeleton variant="rectangular" height={400} />
          <Skeleton variant="rectangular" height={300} style={{ marginTop: '20px' }} />
          <Skeleton variant="rectangular" height={300} style={{ marginTop: '20px' }} />
          <Skeleton variant="text" style={{ marginTop: '20px' }} />
          <Skeleton variant="text" style={{ marginTop: '20px' }} />
        </Box>
      ) : (
        <>
          <Box sx={{ height: 400, marginBottom: '40px' }}>
            <DataGrid
              rows={filteredPages}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              disableSelectionOnClick
            />
          </Box>

          <Divider style={{ marginBottom: '40px' }} />

          <TrendingPages trendingPages={trendingPages}  />
          <DecliningPages decliningPages={decliningPages} />
          <Box>
            <TrendingCountries dateRange={dateRange} className="mt-3"/>
          </Box>
        </>
      )}
    </Box>
  );
};

export default IndexedPages;
