// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { Table, FormControl, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
// import { CSVLink } from 'react-csv';

// const  InstallData = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());
//   const [filteredData, setFilteredData] = useState([]);
//   const [productSearchTerm, setProductSearchTerm] = useState('');
//   const [countrySearchTerm, setCountrySearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [dataPerPage] = useState(10);
//   const [itemsPerPage, setItemsPerPage] = useState(10); 

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = generateData();
//     setFilteredData(data);
//   }, [installData, uninstallData, selectedStartDate, selectedEndDate, productSearchTerm, countrySearchTerm]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://brdash.supportplus.app/install');
//       const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
//       // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
//       // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
//       // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
//       // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } 
//   };


//   const filterDataByDateRange = (data, startDate, endDate) => {
//         return data.filter(detail => {
//           const detailDate = new Date(detail.datetime);
//           const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
//           return utcDetailDate >= startDate && utcDetailDate < endDate;
//         });
//       };

//   const generateData = () => {
//         const data = [];
//         const startDate = new Date(selectedStartDate);
//         let endDate = new Date(selectedEndDate);
    
//         switch ('') {
//           case 'day':
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'week':
//             startDate.setDate(startDate.getDate() - 6);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'month':
//             startDate.setDate(1);
//             endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'year':
//             startDate.setMonth(0);
//             startDate.setDate(1);
//             endDate.setMonth(11);
//             endDate.setDate(31);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           default:
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//         }
    
//         const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
//         const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
    
//         // Filter data based on selected date range
//         const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
//         const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);
    
//         // Group installation data by product and country
//         const groupedData = {};
//         filteredInstallData.forEach(detail => {
//           const { pname, cname } = detail;
//           const key = `${pname}-${cname}`;
//           if (!groupedData[key]) {
//             groupedData[key] = { date: startDate.toLocaleDateString(), product: pname, country: cname, installations: 0, uninstallations: 0 };
//           }
//           groupedData[key].installations++;
//         });
    
//         // Update grouped data with uninstallations
//         filteredUninstallData.forEach(detail => {
//           const { pname, cname } = detail;
//           const key = `${pname}-${cname}`;
//           if (groupedData[key]) {
//             groupedData[key].uninstallations++;
//           }
//         });
    
//         // Transform grouped data into chart data format
//         Object.keys(groupedData).forEach(key => {
//           data.push(groupedData[key]);
//         });
    
//         return data;
//       };
      
    
//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//   };

//   const handleEndDateChange = (date) => {
//     setSelectedEndDate(date);
//   };

//   const handleProductSearchTermChange = (event) => {
//     setProductSearchTerm(event.target.value);
//   };

//   const handleCountrySearchTermChange = (event) => {
//     setCountrySearchTerm(event.target.value);
//   };

  
//   const sortedData = () => {
//     const sorted = [...filteredData];
//     if (sortConfig.key) {
//       sorted.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sorted;
//   };

//   // Filter data based on search terms
//   const filteredDataBySearch = () => {
//     return sortedData().filter(item =>
//       item.product.toLowerCase().includes(productSearchTerm.toLowerCase()) &&
//       item.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//     );
//   };

//   const handleItemsPerPageChange = (value) => {
//     setItemsPerPage(value);
//     setCurrentPage(1); // Reset to first page when changing items per page
//   };

//   // Pagination logic
//   const indexOfLastData = currentPage * dataPerPage;
//   const indexOfFirstData = indexOfLastData - dataPerPage;
//   const currentData = filteredDataBySearch().slice(indexOfFirstData, indexOfLastData);
//   const totalPages = Math.ceil(filteredDataBySearch().length / dataPerPage);

//   const handlePagination = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };


//   const handleSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   const csvData = [['Product', 'Country', 'Installations', 'Uninstallations'], ...currentData.map(item => [item.product, item.country, item.installations, item.uninstallations])];

//   const totalInstallations = currentData.reduce((total, entry) => total + entry.installations, 0);
//   const totalUninstallations = currentData.reduce((total, entry) => total + entry.uninstallations, 0);

//   return (

// <>
// <div className="row g-3">
//   <div className="col-md-5">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-primary text-white">
//         Date Range Filter
//       </div>
//       <div className="card-body">
//         <div className="form-group">
//           <label className="fw-semibold">Start Date:</label>
//           <DatePicker className="form-control mt-2" selected={selectedStartDate} onChange={handleStartDateChange} />
//         </div>
//         <div className="form-group mt-3">
//           <label className="fw-semibold">End Date:</label>
//           <DatePicker className="form-control mt-2" selected={selectedEndDate} onChange={handleEndDateChange} />
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="col-md-5 ms-md-5">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-success text-white">
//         Search Filter
//       </div>
//       <div className="card-body">
//         <div className="form-group">
//           <label className="fw-semibold">Search by Product:</label>
//           <input className="form-control mt-2" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
//         </div>
//         <div className="form-group mt-3">
//           <label className="fw-semibold">Search by Country:</label>
//           <input className="form-control mt-2" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row mt-5">
//   <div className="col-12">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-dark text-white">
//         Data Table
//       </div>
//       <div className="card-body">
//         <table className="table table-striped table-hover">
//           <thead className="bg-light">
//             <tr>
//               <th onClick={() => handleSort('product')}>Product</th>
//               <th onClick={() => handleSort('country')}>Country</th>
//               <th onClick={() => handleSort('installations')}>Installations</th>
//               <th onClick={() => handleSort('uninstallations')}>Uninstallations</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.product}</td>
//                 <td>{item.country}</td>
//                 <td>{item.installations}</td>
//                 <td>{item.uninstallations}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row mt-4">
//   <div className="col-12 d-flex justify-content-between">
//     <div>
//       <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//       <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//     </div>
//     <div>
//       <nav aria-label="Page navigation example">
//         <ul className="pagination">
//           <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//             <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
//           </li>
//           <li className={`page-item`}>
//             <button className="page-link" disabled>{currentPage}</button>
//           </li>
//           <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//             <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
//           </li>
//         </ul>
//       </nav>
//     </div>
//     <div>
//       <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
//     </div>
//   </div>
// </div>
// </>
//   );
// };

// export default InstallData;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { CSVLink } from 'react-csv';

// const InstallData = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());
//   const [groupedData, setGroupedData] = useState({});
//   const [expanded, setExpanded] = useState(null);
//   const [productSearchTerm, setProductSearchTerm] = useState('');
//   const [countrySearchTerm, setCountrySearchTerm] = useState('');
//   const [dataPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = generateData();
//     setGroupedData(data);
//   }, [installData, uninstallData, selectedStartDate, selectedEndDate]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://brdash.supportplus.app/install');
//       const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } 
//   };

//   const filterDataByDateRange = (data, startDate, endDate) => {
//     return data.filter(detail => {
//       const detailDate = new Date(detail.datetime);
//       const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate()));
//       return utcDetailDate >= startDate && utcDetailDate < endDate;
//     });
//   };

//   const generateData = () => {
//     const data = {};
//     const startDate = new Date(selectedStartDate);
//     let endDate = new Date(selectedEndDate);

//     if (startDate > endDate) {
//       endDate = startDate;
//     }

//     endDate.setDate(endDate.getDate() + 1);
//     const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
//     const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

//     const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
//     const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);

//     filteredInstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (!data[pname]) {
//         data[pname] = { product: pname, totalInstallations: 0, totalUninstallations: 0, details: [] };
//       }
//       data[pname].totalInstallations++;
//       data[pname].details.push({ country: cname, installations: 1, uninstallations: 0 });
//     });

//     filteredUninstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (data[pname]) {
//         data[pname].totalUninstallations++;
//         const countryDetail = data[pname].details.find(d => d.country === cname);
//         if (countryDetail) {
//           countryDetail.uninstallations++;
//         } else {
//           data[pname].details.push({ country: cname, installations: 0, uninstallations: 1 });
//         }
//       }
//     });

//     return data;
//   };

//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//   };

//   const handleEndDateChange = (date) => {
//     setSelectedEndDate(date);
//   };

//   const handleProductSearchTermChange = (event) => {
//     setProductSearchTerm(event.target.value);
//   };

//   const handleCountrySearchTermChange = (event) => {
//     setCountrySearchTerm(event.target.value);
//   };

//   const handlePagination = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleSort = (key) => {
//     // Sorting logic if needed, currently not used as we display grouped data
//   };

//   const csvData = [
//     ['Product', 'Country', 'Installations', 'Uninstallations'],
//     ...Object.values(groupedData).flatMap(item => 
//       item.details.map(detail => [item.product, detail.country, detail.installations, detail.uninstallations])
//     )
//   ];

//   const totalInstallations = Object.values(groupedData).reduce((total, item) => total + item.totalInstallations, 0);
//   const totalUninstallations = Object.values(groupedData).reduce((total, item) => total + item.totalUninstallations, 0);

//   return (
//     <>
//       <div className="row g-3">
//          <div className="col-md-5">
//       <div className="card shadow-sm border-0">
//         <div className="card-header bg-primary text-white">
//           Date Range Filter
//         </div>
//         <div className="card-body">
//           <div className="form-group mb-3">
//             <label className="fw-semibold">Start Date:</label>
//             <DatePicker
//               className="form-control form-control-md mt-2"
//               selected={selectedStartDate}
//               onChange={handleStartDateChange}
//               dateFormat="yyyy/MM/dd"
//               placeholderText="Select start date"
//             />
//           </div>
//           <div className="form-group">
//             <label className="fw-semibold">End Date:</label>
//             <DatePicker
//               className="form-control form-control-md mt-2"
//               selected={selectedEndDate}
//               onChange={handleEndDateChange}
//               dateFormat="yyyy/MM/dd"
//               placeholderText="Select end date"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//       </div>

// <div className="row mt-5">
//         <div className="col-12">
//           <div className="card shadow-sm border-0">
//             <div className="card-header bg-dark text-white">
//               Data Overview
//             </div>
//             <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <div>
//                   <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//                   <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//                 </div>
//                 <div className="d-flex flex-column">
//                   <div className="form-group mb-2">
//                     <label className="fw-semibold">Search by Product:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={productSearchTerm}
//                       onChange={handleProductSearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label className="fw-semibold">Search by Country:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={countrySearchTerm}
//                       onChange={handleCountrySearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                 </div>
//               </div>

//               {Object.values(groupedData).filter(item =>
//                 item.product.toLowerCase().includes(productSearchTerm.toLowerCase())
//               ).map(item => (
//                 <Accordion
//                   key={item.product}
//                   expanded={expanded === item.product}
//                   onChange={() => setExpanded(expanded === item.product ? null : item.product)}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls={`${item.product}-content`}
//                     id={`${item.product}-header`}
//                     sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
//                   >
//                     <Typography className='fw-bold'>{item.product}</Typography>
//                     <Typography sx={{ ml: 2 }}>
//                       Total Installations: {item.totalInstallations}, Total Uninstallations: {item.totalUninstallations}
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <List>
//                       {item.details.filter(detail =>
//                         detail.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//                       ).map((detail, index) => (
//                         <ListItem key={index}>
//                           <ListItemText
//                             primary={detail.country}
//                             secondary={`Installations: ${detail.installations}, Uninstallations: ${detail.uninstallations}`}
//                           />
//                         </ListItem>
//                       ))}
//                     </List>
//                   </AccordionDetails>
//                 </Accordion>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12 d-flex justify-content-between">
//           <div>
//           </div>
//           <div>
//           </div>
//           <div>
//             <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default InstallData;













































// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { CSVLink } from 'react-csv';

// const InstallData = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());
//   const [groupedData, setGroupedData] = useState({});
//   const [expanded, setExpanded] = useState(null);
//   const [productSearchTerm, setProductSearchTerm] = useState('');
//   const [countrySearchTerm, setCountrySearchTerm] = useState('');
//   const [dataPerPage] = useState(10);
//   const [currentPage, setCurrentPage] = useState(1);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = generateData();
//     setGroupedData(data);
//   }, [installData, uninstallData, selectedStartDate, selectedEndDate]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://brdash.supportplus.app/install');
//       const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } 
//   };

//   const filterDataByDateRange = (data, startDate, endDate) => {
//     return data.filter(detail => {
//       const detailDate = new Date(detail.datetime);
//       const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate()));
//       return utcDetailDate >= startDate && utcDetailDate < endDate;
//     });
//   };

//   const generateData = () => {
//     const data = {};
//     const startDate = new Date(selectedStartDate);
//     let endDate = new Date(selectedEndDate);

//     if (startDate > endDate) {
//       endDate = startDate;
//     }

//     endDate.setDate(endDate.getDate() + 1);
//     const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
//     const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

//     const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
//     const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);

//     filteredInstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (!data[pname]) {
//         data[pname] = { product: pname, totalInstallations: 0, totalUninstallations: 0, details: [] };
//       }
//       data[pname].totalInstallations++;
//       data[pname].details.push({ country: cname, installations: 1, uninstallations: 0 });
//     });

//     filteredUninstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (data[pname]) {
//         data[pname].totalUninstallations++;
//         const countryDetail = data[pname].details.find(d => d.country === cname);
//         if (countryDetail) {
//           countryDetail.uninstallations++;
//         } else {
//           data[pname].details.push({ country: cname, installations: 0, uninstallations: 1 });
//         }
//       }
//     });

//     return data;
//   };

//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//   };

//   const handleEndDateChange = (date) => {
//     setSelectedEndDate(date);
//   };

//   const handleProductSearchTermChange = (event) => {
//     setProductSearchTerm(event.target.value);
//   };

//   const handleCountrySearchTermChange = (event) => {
//     setCountrySearchTerm(event.target.value);
//   };

//   const handlePagination = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   const handleSort = (key) => {
//     // Sorting logic if needed, currently not used as we display grouped data
//   };

//   const csvData = [
//     ['Product', 'Country', 'Installations', 'Uninstallations'],
//     ...Object.values(groupedData).flatMap(item => 
//       item.details.map(detail => [item.product, detail.country, detail.installations, detail.uninstallations])
//     )
//   ];

//   const totalInstallations = Object.values(groupedData).reduce((total, item) => total + item.totalInstallations, 0);
//   const totalUninstallations = Object.values(groupedData).reduce((total, item) => total + item.totalUninstallations, 0);

//   return (
//     <>
//       <div className="row g-3">
//         {/* <div className="col-md-5">
//           <div className="card shadow-sm border-0">
//             <div className="card-header bg-primary text-white">
//               Date Range Filter
//             </div>
//             <div className="card-body">
//               <div className="form-group">
//                 <label className="fw-semibold">Start Date:</label>
//                 <DatePicker className="form-control mt-2" selected={selectedStartDate} onChange={handleStartDateChange} />
//               </div>
//               <div className="form-group mt-3">
//                 <label className="fw-semibold">End Date:</label>
//                 <DatePicker className="form-control mt-2" selected={selectedEndDate} onChange={handleEndDateChange} />
//               </div>
//             </div>
//           </div>
//         </div> */}
//          <div className="col-md-5">
//       <div className="card shadow-sm border-0">
//         <div className="card-header bg-primary text-white">
//           Date Range Filter
//         </div>
//         <div className="card-body">
//           <div className="form-group mb-3">
//             <label className="fw-semibold">Start Date:</label>
//             <DatePicker
//               className="form-control form-control-md mt-2"
//               selected={selectedStartDate}
//               onChange={handleStartDateChange}
//               dateFormat="yyyy/MM/dd"
//               placeholderText="Select start date"
//             />
//           </div>
//           <div className="form-group">
//             <label className="fw-semibold">End Date:</label>
//             <DatePicker
//               className="form-control form-control-md mt-2"
//               selected={selectedEndDate}
//               onChange={handleEndDateChange}
//               dateFormat="yyyy/MM/dd"
//               placeholderText="Select end date"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//       </div>

//       {/* <div className="row mt-5">
//         <div className="col-12">
//           <div className="card shadow-sm border-0">
//             <div className="card-header bg-dark text-white">
//               Data Overview
//             </div>
//             <div className='ms-4 , mt-3'>

//             <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//             <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//             </div>
//             <div className="form-group float-end">
//                 <label className="fw-semibold">Search by Product:</label>
//                 <input className="form-control mt-2" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
//               </div>
//               <div className="form-group mt-3">
//                 <label className="fw-semibold">Search by Country:</label>
//                 <input className="form-control mt-2" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
//               </div>
//             <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
//               {Object.values(groupedData).filter(item =>
//                 item.product.toLowerCase().includes(productSearchTerm.toLowerCase())
//               ).map(item => (
//                 <Accordion
//                   key={item.product}
//                   expanded={expanded === item.product}
//                   onChange={() => setExpanded(expanded === item.product ? null : item.product)}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls={`${item.product}-content`}
//                     id={`${item.product}-header`}
//                   >
//                     <Typography className='fw-bold'>{item.product}</Typography>
//                     <Typography sx={{ ml: 2  }} >
//                       Total Installations: {item.totalInstallations}, Total Uninstallations: {item.totalUninstallations}
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <List>
//                       {item.details.filter(detail =>
//                         detail.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//                       ).map((detail, index) => (
//                         <ListItem key={index}>
//                           <ListItemText
//                             primary={detail.country}
//                             secondary={`Installations: ${detail.installations}, Uninstallations: ${detail.uninstallations}`}
//                           />
//                         </ListItem>
//                       ))}
//                     </List>
//                   </AccordionDetails>
//                 </Accordion>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div> */}


// <div className="row mt-5">
//         <div className="col-12">
//           <div className="card shadow-sm border-0">
//             <div className="card-header bg-dark text-white">
//               Data Overview
//             </div>
//             <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <div>
//                   <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//                   <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//                 </div>
//                 <div className="d-flex flex-column">
//                   <div className="form-group mb-2">
//                     <label className="fw-semibold">Search by Product:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={productSearchTerm}
//                       onChange={handleProductSearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label className="fw-semibold">Search by Country:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={countrySearchTerm}
//                       onChange={handleCountrySearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                 </div>
//               </div>

//               {Object.values(groupedData).filter(item =>
//                 item.product.toLowerCase().includes(productSearchTerm.toLowerCase())
//               ).map(item => (
//                 <Accordion
//                   key={item.product}
//                   expanded={expanded === item.product}
//                   onChange={() => setExpanded(expanded === item.product ? null : item.product)}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls={`${item.product}-content`}
//                     id={`${item.product}-header`}
//                     sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
//                   >
//                     <Typography className='fw-bold'>{item.product}</Typography>
//                     <Typography sx={{ ml: 2 }}>
//                       Total Installations: {item.totalInstallations}, Total Uninstallations: {item.totalUninstallations}
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <List>
//                       {item.details.filter(detail =>
//                         detail.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//                       ).map((detail, index) => (
//                         <ListItem key={index}>
//                           <ListItemText
//                             primary={detail.country}
//                             secondary={`Installations: ${detail.installations}, Uninstallations: ${detail.uninstallations}`}
//                           />
//                         </ListItem>
//                       ))}
//                     </List>
//                   </AccordionDetails>
//                 </Accordion>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12 d-flex justify-content-between">
//           <div>
//             {/* <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//             <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p> */}
//           </div>
//           <div>
//             {/* <nav aria-label="Page navigation example">
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
//                 </li>
//                 <li className={`page-item`}>
//                   <button className="page-link" disabled>{currentPage}</button>
//                 </li>
//                 <li className={`page-item ${currentPage === Math.ceil(Object.values(groupedData).length / dataPerPage) ? 'disabled' : ''}`}>
//                   <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
//                 </li>
//               </ul>
//             </nav> */}
//           </div>
//           <div>
//             <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default InstallData;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { Table, FormControl, InputGroup, Button, Dropdown, DropdownButton } from 'react-bootstrap';
// import { CSVLink } from 'react-csv';

// const  InstallData = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());
//   const [filteredData, setFilteredData] = useState([]);
//   const [productSearchTerm, setProductSearchTerm] = useState('');
//   const [countrySearchTerm, setCountrySearchTerm] = useState('');
//   const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
//   const [currentPage, setCurrentPage] = useState(1);
//   const [dataPerPage] = useState(10);
//   const [itemsPerPage, setItemsPerPage] = useState(10); 

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = generateData();
//     setFilteredData(data);
//   }, [installData, uninstallData, selectedStartDate, selectedEndDate, productSearchTerm, countrySearchTerm]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://brdash.supportplus.app/install');
//       const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
//       // const installResponse = await axios.get('https://brdash.supportplus.app/m-install');
//       // const uninstallResponse = await axios.get('https://brdash.supportplus.app/m-uninstall');
//       // const installResponse = await axios.get('https://dash.bitrecover.com/m-install');
//       // const uninstallResponse = await axios.get('https://dash.bitrecover.com/m-uninstall');

//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } 
//   };


//   const filterDataByDateRange = (data, startDate, endDate) => {
//         return data.filter(detail => {
//           const detailDate = new Date(detail.datetime);
//           const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate(), detailDate.getHours(), detailDate.getMinutes(), detailDate.getSeconds()));
//           return utcDetailDate >= startDate && utcDetailDate < endDate;
//         });
//       };

//   const generateData = () => {
//         const data = [];
//         const startDate = new Date(selectedStartDate);
//         let endDate = new Date(selectedEndDate);
    
//         switch ('') {
//           case 'day':
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'week':
//             startDate.setDate(startDate.getDate() - 6);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'month':
//             startDate.setDate(1);
//             endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           case 'year':
//             startDate.setMonth(0);
//             startDate.setDate(1);
//             endDate.setMonth(11);
//             endDate.setDate(31);
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//           default:
//             endDate.setDate(endDate.getDate() + 1);
//             break;
//         }
    
//         const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
//         const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));
    
//         // Filter data based on selected date range
//         const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
//         const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);
    
//         // Group installation data by product and country
//         const groupedData = {};
//         filteredInstallData.forEach(detail => {
//           const { pname, cname } = detail;
//           const key = `${pname}-${cname}`;
//           if (!groupedData[key]) {
//             groupedData[key] = { date: startDate.toLocaleDateString(), product: pname, country: cname, installations: 0, uninstallations: 0 };
//           }
//           groupedData[key].installations++;
//         });
    
//         // Update grouped data with uninstallations
//         filteredUninstallData.forEach(detail => {
//           const { pname, cname } = detail;
//           const key = `${pname}-${cname}`;
//           if (groupedData[key]) {
//             groupedData[key].uninstallations++;
//           }
//         });
    
//         // Transform grouped data into chart data format
//         Object.keys(groupedData).forEach(key => {
//           data.push(groupedData[key]);
//         });
    
//         return data;
//       };
      
    
//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//   };

//   const handleEndDateChange = (date) => {
//     setSelectedEndDate(date);
//   };

//   const handleProductSearchTermChange = (event) => {
//     setProductSearchTerm(event.target.value);
//   };

//   const handleCountrySearchTermChange = (event) => {
//     setCountrySearchTerm(event.target.value);
//   };

  
//   const sortedData = () => {
//     const sorted = [...filteredData];
//     if (sortConfig.key) {
//       sorted.sort((a, b) => {
//         if (a[sortConfig.key] < b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? -1 : 1;
//         }
//         if (a[sortConfig.key] > b[sortConfig.key]) {
//           return sortConfig.direction === 'ascending' ? 1 : -1;
//         }
//         return 0;
//       });
//     }
//     return sorted;
//   };

//   // Filter data based on search terms
//   const filteredDataBySearch = () => {
//     return sortedData().filter(item =>
//       item.product.toLowerCase().includes(productSearchTerm.toLowerCase()) &&
//       item.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//     );
//   };

//   const handleItemsPerPageChange = (value) => {
//     setItemsPerPage(value);
//     setCurrentPage(1); // Reset to first page when changing items per page
//   };

//   // Pagination logic
//   const indexOfLastData = currentPage * dataPerPage;
//   const indexOfFirstData = indexOfLastData - dataPerPage;
//   const currentData = filteredDataBySearch().slice(indexOfFirstData, indexOfLastData);
//   const totalPages = Math.ceil(filteredDataBySearch().length / dataPerPage);

//   const handlePagination = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };


//   const handleSort = (key) => {
//     let direction = 'ascending';
//     if (sortConfig.key === key && sortConfig.direction === 'ascending') {
//       direction = 'descending';
//     }
//     setSortConfig({ key, direction });
//   };

//   const csvData = [['Product', 'Country', 'Installations', 'Uninstallations'], ...currentData.map(item => [item.product, item.country, item.installations, item.uninstallations])];

//   const totalInstallations = currentData.reduce((total, entry) => total + entry.installations, 0);
//   const totalUninstallations = currentData.reduce((total, entry) => total + entry.uninstallations, 0);

//   return (
// //     <>
// //       <div className='row'>
 
       
      

// // <div className='col-md-5'>
// //   {/* <div className="card shadow"> */}
// //     <div className="card">
// //       Date Range Filter
// //     </div>
// //     <div className="card-body">
// //       <div className="form-group">
// //         <label>Start Date:</label>
// //         <DatePicker className="form-control ms-3" selected={selectedStartDate} onChange={handleStartDateChange} />
// //       </div>
// //       <div className="form-group">
// //         <label>End Date:</label>
// //         <DatePicker className="form-control ms-3 mt-3" selected={selectedEndDate} onChange={handleEndDateChange} />
// //       </div>
// //     </div>
// //   {/* </div> */}
// // </div>

// //         <div className='col-md-5 ms-5 px-5'>
        
// //             <p className="card">Search Filter</p>
// //             <div className="card-body">
// //               <div className="form-group">
// //                 <label>Search by Product:</label>
// //                 <input className="form-control" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
// //               </div>
// //               <div className="form-group">
// //                 <label>Search by Country:</label>
// //                 <input className="form-control" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
// //               </div>
// //             </div>
      
// //         </div>
// //       </div>


// //       {/* <div className='col-lg-3 mt-5'>
// // <DropdownButton
// //           title={`Show ${itemsPerPage} entries`}
// //           variant="primary"
// //           id="dropdown-basic-button"
// //         >
// //           <Dropdown.Item onClick={() => handleItemsPerPageChange(10)}>10</Dropdown.Item>
// //           <Dropdown.Item onClick={() => handleItemsPerPageChange(25)}>25</Dropdown.Item>
// //           <Dropdown.Item onClick={() => handleItemsPerPageChange(50)}>50</Dropdown.Item>
// //           <Dropdown.Item onClick={() => handleItemsPerPageChange(100)}>100</Dropdown.Item>
// //         </DropdownButton>
// // </div> */}
     
// //       <div className='row'>
// //         <div className='col'>
// //           {/* <p className='fw-bold'>Data Table</p> */}
// //           <table className='table table-striped'>
// //             <thead>
// //               <tr>
// //                 <th onClick={() => handleSort('product')}>Product</th>
// //                 <th onClick={() => handleSort('country')}>Country</th>
// //                 <th onClick={() => handleSort('installations')}>Installations</th>
// //                 <th onClick={() => handleSort('uninstallations')}>Uninstallations</th>
// //               </tr>
// //             </thead>
// //             <tbody>
// //               {currentData.map((item, index) => (
// //                 <tr key={index}>
// //                   <td>{item.product}</td>
// //                   <td>{item.country}</td>
// //                   <td>{item.installations}</td>
// //                   <td>{item.uninstallations}</td>
// //                 </tr>
// //               ))}
// //             </tbody>
// //           </table>
// //         </div>
// //       </div>

// //       <div className='row'>
// //         <div className='col'>
// //           <p>Total Installations: {totalInstallations}</p>
// //           <p>Total Uninstallations: {totalUninstallations}</p>
// //         </div>
// //       </div>

// //       <div className='row'>
// //         <div className='col'>
// //           <div className="d-flex justify-content-between align-items-center mb-3">
// //             <div className="">
// //               <nav aria-label="Page navigation example">
// //                 <ul className="pagination">
// //                   <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
// //                     <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
// //                   </li>
// //                   <li className={`page-item`}>
// //                     <button className="page-link" disabled>{currentPage}</button>
// //                   </li>
// //                   <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
// //                     <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
// //                   </li>
// //                 </ul>
// //                 </nav>
// //             </div>
// //             <div className="d-flex justify-content-end">
// //               <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary">Export to CSV</CSVLink>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </>
// <>
// <div className="row g-3">
//   <div className="col-md-5">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-primary text-white">
//         Date Range Filter
//       </div>
//       <div className="card-body">
//         <div className="form-group">
//           <label className="fw-semibold">Start Date:</label>
//           <DatePicker className="form-control mt-2" selected={selectedStartDate} onChange={handleStartDateChange} />
//         </div>
//         <div className="form-group mt-3">
//           <label className="fw-semibold">End Date:</label>
//           <DatePicker className="form-control mt-2" selected={selectedEndDate} onChange={handleEndDateChange} />
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="col-md-5 ms-md-5">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-success text-white">
//         Search Filter
//       </div>
//       <div className="card-body">
//         <div className="form-group">
//           <label className="fw-semibold">Search by Product:</label>
//           <input className="form-control mt-2" type="text" value={productSearchTerm} onChange={handleProductSearchTermChange} />
//         </div>
//         <div className="form-group mt-3">
//           <label className="fw-semibold">Search by Country:</label>
//           <input className="form-control mt-2" type="text" value={countrySearchTerm} onChange={handleCountrySearchTermChange} />
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row mt-5">
//   <div className="col-12">
//     <div className="card shadow-sm border-0">
//       <div className="card-header bg-dark text-white">
//         Data Table
//       </div>
//       <div className="card-body">
//         <table className="table table-striped table-hover">
//           <thead className="bg-light">
//             <tr>
//               <th onClick={() => handleSort('product')}>Product</th>
//               <th onClick={() => handleSort('country')}>Country</th>
//               <th onClick={() => handleSort('installations')}>Installations</th>
//               <th onClick={() => handleSort('uninstallations')}>Uninstallations</th>
//             </tr>
//           </thead>
//           <tbody>
//             {currentData.map((item, index) => (
//               <tr key={index}>
//                 <td>{item.product}</td>
//                 <td>{item.country}</td>
//                 <td>{item.installations}</td>
//                 <td>{item.uninstallations}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row mt-4">
//   <div className="col-12 d-flex justify-content-between">
//     <div>
//       <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//       <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//     </div>
//     <div>
//       <nav aria-label="Page navigation example">
//         <ul className="pagination">
//           <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//             <button className="page-link" onClick={() => handlePagination(currentPage - 1)}>Previous</button>
//           </li>
//           <li className={`page-item`}>
//             <button className="page-link" disabled>{currentPage}</button>
//           </li>
//           <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//             <button className="page-link" onClick={() => handlePagination(currentPage + 1)}>Next</button>
//           </li>
//         </ul>
//       </nav>
//     </div>
//     <div>
//       <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
//     </div>
//   </div>
// </div>
// </>
//   );
// };

// export default InstallData;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import "react-datepicker/dist/react-datepicker.css";
// import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText, FormControl, Select, MenuItem, TextField } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { CSVLink } from 'react-csv';

// const InstallData = () => {
//   const [installData, setInstallData] = useState([]);
//   const [uninstallData, setUninstallData] = useState([]);
//   const [selectedStartDate, setSelectedStartDate] = useState(new Date());
//   const [selectedEndDate, setSelectedEndDate] = useState(new Date());
//   const [groupedData, setGroupedData] = useState({});
//   const [expanded, setExpanded] = useState(null);
//   const [productSearchTerm, setProductSearchTerm] = useState('');
//   const [countrySearchTerm, setCountrySearchTerm] = useState('');
//   const [dateRange, setDateRange] = useState('Today');
//   const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
//   const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   useEffect(() => {
//     const data = generateData();
//     setGroupedData(data);
//   }, [installData, uninstallData, selectedStartDate, selectedEndDate]);

//   const fetchData = async () => {
//     try {
//       const installResponse = await axios.get('https://brdash.supportplus.app/install');
//       const uninstallResponse = await axios.get('https://brdash.supportplus.app/uninstall');
//       setInstallData(installResponse.data);
//       setUninstallData(uninstallResponse.data);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } 
//   };

//   const filterDataByDateRange = (data, startDate, endDate) => {
//     return data.filter(detail => {
//       const detailDate = new Date(detail.datetime);
//       const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate()));
//       return utcDetailDate >= startDate && utcDetailDate < endDate;
//     });
//   };

//   const generateData = () => {
//     const data = {};
//     const startDate = new Date(selectedStartDate);
//     let endDate = new Date(selectedEndDate);

//     if (startDate > endDate) {
//       endDate = startDate;
//     }

//     endDate.setDate(endDate.getDate() + 1);
//     const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
//     const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

//     const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
//     const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);

//     filteredInstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (!data[pname]) {
//         data[pname] = { product: pname, totalInstallations: 0, totalUninstallations: 0, details: [] };
//       }
//       data[pname].totalInstallations++;
//       data[pname].details.push({ country: cname, installations: 1, uninstallations: 0 });
//     });

//     filteredUninstallData.forEach(detail => {
//       const { pname, cname } = detail;
//       if (data[pname]) {
//         data[pname].totalUninstallations++;
//         const countryDetail = data[pname].details.find(d => d.country === cname);
//         if (countryDetail) {
//           countryDetail.uninstallations++;
//         } else {
//           data[pname].details.push({ country: cname, installations: 0, uninstallations: 1 });
//         }
//       }
//     });

//     return data;
//   };

//   const updateDateRange = (range) => {
//     const today = new Date();
//     const end = today.toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Today':
//         start = end;
//         break;
//       case 'Yesterday':
//         start = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setSelectedStartDate(new Date(start));
//     setSelectedEndDate(new Date(end));
//     setDateRange(range);
//   };

//   const handleStartDateChange = (date) => {
//     setSelectedStartDate(date);
//   };

//   const handleEndDateChange = (date) => {
//     setSelectedEndDate(date);
//   };

//   const handleProductSearchTermChange = (event) => {
//     setProductSearchTerm(event.target.value);
//   };

//   const handleCountrySearchTermChange = (event) => {
//     setCountrySearchTerm(event.target.value);
//   };

// //   const handlePagination = (pageNumber) => {
// //     setCurrentPage(pageNumber);
// //   };

// //   const handleSort = (key) => {
// //     // Sorting logic if needed, currently not used as we display grouped data
// //   };

//   const csvData = [
//     ['Product', 'Country', 'Installations', 'Uninstallations'],
//     ...Object.values(groupedData).flatMap(item => 
//       item.details.map(detail => [item.product, detail.country, detail.installations, detail.uninstallations])
//     )
//   ];

//   const totalInstallations = Object.values(groupedData).reduce((total, item) => total + item.totalInstallations, 0);
//   const totalUninstallations = Object.values(groupedData).reduce((total, item) => total + item.totalUninstallations, 0);

//   return (
//     <>
//       <div className="row g-3">
        
    
          
//             <div className="card-body">
//               <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
//                 <Select
//                   labelId="date-range-label"
//                   id="date-range-select"
//                   value={dateRange}
//                   onChange={(e) => updateDateRange(e.target.value)}
//                   sx={{
//                     backgroundColor: 'rgb(211, 227, 253)',
//                     borderRadius: '25px',
//                     '& .MuiSelect-select': {
//                       padding: '10px',
//                       borderRadius: '25px',
//                     },
//                   }}
//                 >
//                   <MenuItem value="Today">Today</MenuItem>
//                   <MenuItem value="Yesterday">Yesterday</MenuItem>
//                   <MenuItem value="Last 7 days">Last 7 days</MenuItem>
//                   <MenuItem value="Last 28 days">Last 28 days</MenuItem>
//                   <MenuItem value="Last 3 months">Last 3 months</MenuItem>
//                   <MenuItem value="Last 6 months">Last 6 months</MenuItem>
//                   <MenuItem value="Last 12 months">Last 12 months</MenuItem>
//                   <MenuItem value="Last 16 months">Last 16 months</MenuItem>
//                   <MenuItem value="Custom">Custom</MenuItem>
//                 </Select>
//               </FormControl>
//               {dateRange === 'Custom' && (
//                 <>
//                   <TextField
//                     label="Start Date"
//                     type="date"
//                     value={startDate}
//                     onChange={(e) => {
//                       setStartDate(e.target.value);
//                       setSelectedStartDate(new Date(e.target.value));
//                     }}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     sx={{ minWidth: 250 }}
//                   />
//                   <TextField
//                     label="End Date"
//                     type="date"
//                     value={endDate}
//                     onChange={(e) => {
//                       setEndDate(e.target.value);
//                       setSelectedEndDate(new Date(e.target.value));
//                     }}
//                     InputLabelProps={{
//                       shrink: true,
//                     }}
//                     required
//                     sx={{ minWidth: 250, ml: 4 }}
//                   />
//                 </>
//               )}
//             </div>
         
    

//         <div className="col-md-12">
//           <div className="card shadow-sm border-0">
//             <div className="card-header bg-dark text-white">
//               Data Overview
//             </div>
//             <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
//               <div className="d-flex justify-content-between align-items-center mb-3">
//                 <div>
//                   <p className="fw-semibold">Total Installations: {totalInstallations}</p>
//                   <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
//                 </div>
//                 <div className="d-flex flex-column">
//                   <div className="form-group mb-2">
//                     <label className="fw-semibold">Search by Product:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={productSearchTerm}
//                       onChange={handleProductSearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                   <div className="form-group">
//                     <label className="fw-semibold">Search by Country:</label>
//                     <input
//                       className="form-control form-control-sm rounded"
//                       type="text"
//                       value={countrySearchTerm}
//                       onChange={handleCountrySearchTermChange}
//                       style={{ borderRadius: '5px' }}
//                     />
//                   </div>
//                 </div>
//               </div>

//               {Object.values(groupedData).filter(item =>
//                 item.product.toLowerCase().includes(productSearchTerm.toLowerCase())
//               ).map(item => (
//                 <Accordion
//                   key={item.product}
//                   expanded={expanded === item.product}
//                   onChange={() => setExpanded(expanded === item.product ? null : item.product)}
//                 >
//                   <AccordionSummary
//                     expandIcon={<ExpandMoreIcon />}
//                     aria-controls={`${item.product}-content`}
//                     id={`${item.product}-header`}
//                     sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
//                   >
//                     <Typography className='fw-bold'>{item.product}</Typography>
//                     <Typography sx={{ ml: 2 }}>
//                       Total Installations: {item.totalInstallations}, Total Uninstallations: {item.totalUninstallations}
//                     </Typography>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <List>
//                       {item.details.filter(detail =>
//                         detail.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
//                       ).map((detail, index) => (
//                         <ListItem key={index}>
//                           <ListItemText
//                             primary={detail.country}
//                             secondary={`Installations: ${detail.installations}, Uninstallations: ${detail.uninstallations}`}
//                           />
//                         </ListItem>
//                       ))}
//                     </List>
//                   </AccordionDetails>
//                 </Accordion>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12 d-flex justify-content-between">
//           <div>
//             {/* Placeholder for potential future controls */}
//           </div>
//           <div>
//             {/* Placeholder for potential future controls */}
//           </div>
//           <div>
//             <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default InstallData;





import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText, FormControl, Select, MenuItem, TextField, CircularProgress, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CSVLink } from 'react-csv';

// Function to fetch install data
const fetchInstallData = async () => {
  const response = await axios.get('https://brdash.supportplus.app/install');
  return response.data;
};

// Function to fetch uninstall data
const fetchUninstallData = async () => {
  const response = await axios.get('https://brdash.supportplus.app/uninstall');
  return response.data;
};

const InstallData = () => {
  const [groupedData, setGroupedData] = useState({});
  const [expanded, setExpanded] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [countrySearchTerm, setCountrySearchTerm] = useState('');
  const [dateRange, setDateRange] = useState('Today');
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  // Use React Query to fetch install and uninstall data
  const { data: installData, isLoading: installLoading } = useQuery({
    queryKey: ['installData'],
    queryFn: fetchInstallData,
  });
  const { data: uninstallData, isLoading: uninstallLoading } = useQuery({
    queryKey: ['uninstallData'],
    queryFn: fetchUninstallData,
  });

  const loading = installLoading || uninstallLoading;

  useEffect(() => {
    if (installData && uninstallData) {
      const data = generateData();
      setGroupedData(data);
    }
  }, [installData, uninstallData, selectedStartDate, selectedEndDate]);

  const filterDataByDateRange = (data, startDate, endDate) => {
    return data.filter(detail => {
      const detailDate = new Date(detail.datetime);
      const utcDetailDate = new Date(Date.UTC(detailDate.getFullYear(), detailDate.getMonth(), detailDate.getDate()));
      return utcDetailDate >= startDate && utcDetailDate < endDate;
    });
  };

  const generateData = () => {
    const data = {};
    const startDate = new Date(selectedStartDate);
    let endDate = new Date(selectedEndDate);

    if (startDate > endDate) {
      endDate = startDate;
    }

    endDate.setDate(endDate.getDate() + 1);
    const utcStartDate = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
    const utcEndDate = new Date(Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()));

    const filteredInstallData = filterDataByDateRange(installData, utcStartDate, utcEndDate);
    const filteredUninstallData = filterDataByDateRange(uninstallData, utcStartDate, utcEndDate);

    filteredInstallData.forEach(detail => {
      const { pname, cname } = detail;
      if (!data[pname]) {
        data[pname] = { product: pname, totalInstallations: 0, totalUninstallations: 0, details: [] };
      }
      data[pname].totalInstallations++;
      data[pname].details.push({ country: cname, installations: 1, uninstallations: 0 });
    });

    filteredUninstallData.forEach(detail => {
      const { pname, cname } = detail;
      if (data[pname]) {
        data[pname].totalUninstallations++;
        const countryDetail = data[pname].details.find(d => d.country === cname);
        if (countryDetail) {
          countryDetail.uninstallations++;
        } else {
          data[pname].details.push({ country: cname, installations: 0, uninstallations: 1 });
        }
      }
    });

    return data;
  };

  const updateDateRange = (range) => {
    const today = new Date();
    const end = today.toISOString().split('T')[0];
    let start;

    switch (range) {
      case 'Today':
        start = end;
        break;
      case 'Yesterday':
        start = new Date(today.setDate(today.getDate() - 1)).toISOString().split('T')[0];
        break;
      case 'Last 7 days':
        start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Last 28 days':
        start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
        break;
      case 'Last 12 months':
        start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
        break;
      default:
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedStartDate(new Date(start));
    setSelectedEndDate(new Date(end));
    setDateRange(range);
  };

  const handleProductSearchTermChange = (event) => {
    setProductSearchTerm(event.target.value);
  };

  const handleCountrySearchTermChange = (event) => {
    setCountrySearchTerm(event.target.value);
  };

  const csvData = [
    ['Product', 'Country', 'Installations', 'Uninstallations'],
    ...Object.values(groupedData).flatMap(item => 
      item.details.map(detail => [item.product, detail.country, detail.installations, detail.uninstallations])
    )
  ];

  const totalInstallations = Object.values(groupedData).reduce((total, item) => total + item.totalInstallations, 0);
  const totalUninstallations = Object.values(groupedData).reduce((total, item) => total + item.totalUninstallations, 0);

  return (
    <>
      <div className="row g-3">
        {/* Loader when data is being fetched */}
          <>
            <div className="card-body">
              <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
                <Select
                  labelId="date-range-label"
                  id="date-range-select"
                  value={dateRange}
                  onChange={(e) => updateDateRange(e.target.value)}
                  sx={{
                    backgroundColor: 'rgb(211, 227, 253)',
                    borderRadius: '25px',
                    '& .MuiSelect-select': {
                      padding: '10px',
                      borderRadius: '25px',
                    },
                  }}
                >
                  <MenuItem value="Today">Today</MenuItem>
                  <MenuItem value="Yesterday">Yesterday</MenuItem>
                  <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                  <MenuItem value="Last 28 days">Last 28 days</MenuItem>
                  <MenuItem value="Last 3 months">Last 3 months</MenuItem>
                  <MenuItem value="Last 6 months">Last 6 months</MenuItem>
                  <MenuItem value="Last 12 months">Last 12 months</MenuItem>
                  <MenuItem value="Custom">Custom</MenuItem>
                </Select>
              </FormControl>

              {dateRange === 'Custom' && (
                <>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setSelectedStartDate(new Date(e.target.value));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    sx={{ minWidth: 250 }}
                  />
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setSelectedEndDate(new Date(e.target.value));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    sx={{ minWidth: 250, ml: 4 }}
                  />
                </>
              )}
            </div>

            <div className="col-md-12">
              <div className="card shadow-sm border-0">
                <div className="card-header bg-dark text-white">
                  Data Overview
                </div>
                <div className="card-body" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <p className="fw-semibold">Total Installations: {totalInstallations}</p>
                      <p className="fw-semibold">Total Uninstallations: {totalUninstallations}</p>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="form-group mb-2">
                        <label className="fw-semibold">Search by Product:</label>
                        <input
                          className="form-control form-control-sm rounded"
                          type="text"
                          value={productSearchTerm}
                          onChange={handleProductSearchTermChange}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="fw-semibold">Search by Country:</label>
                        <input
                          className="form-control form-control-sm rounded"
                          type="text"
                          value={countrySearchTerm}
                          onChange={handleCountrySearchTermChange}
                          style={{ borderRadius: '5px' }}
                        />
                      </div>
                    </div>
                  </div>

                  {Object.values(groupedData).filter(item =>
                    item.product.toLowerCase().includes(productSearchTerm.toLowerCase())
                  ).map(item => (
                    <Accordion
                      key={item.product}
                      expanded={expanded === item.product}
                      onChange={() => setExpanded(expanded === item.product ? null : item.product)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${item.product}-content`}
                        id={`${item.product}-header`}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <Typography className='fw-bold'>{item.product}</Typography>
                        <Typography sx={{ ml: 2 }}>
                          Total Installations: {item.totalInstallations}, Total Uninstallations: {item.totalUninstallations}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          {item.details.filter(detail =>
                            detail.country.toLowerCase().includes(countrySearchTerm.toLowerCase())
                          ).map((detail, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={detail.country}
                                secondary={`Installations: ${detail.installations}, Uninstallations: ${detail.uninstallations}`}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </div>
          </>
     
      </div>

      <div className="row mt-4">
        <div className="col-12 d-flex justify-content-between">
          <div>
            {/* Placeholder for potential future controls */}
          </div>
          <div>
            {/* Placeholder for potential future controls */}
          </div>
          <div>
            <CSVLink data={csvData} filename={"data.csv"} className="btn btn-primary shadow-sm">Export to CSV</CSVLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstallData;
