import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDomain } from '../context/DomainContext';
import { Card, CardContent, Typography, Container, Grid, Button, TextField, CircularProgress, Alert } from '@mui/material';
import NotFoundPage from '../Notfound';

const VideoPages = () => {
  const { selectedLink } = useDomain(); // Get the selectedLink from context
  const [videos, setVideos] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Calculate default dates (last three months)
  useEffect(() => {
    const today = new Date();
    const end = today.toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
    const start = new Date(today.setMonth(today.getMonth() - 3)).toISOString().split('T')[0]; // Three months ago

    setStartDate(start);
    setEndDate(end);
  }, []);

  // Fetch videos
  const fetchVideos = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('http://localhost:8080/api/videos', { params: { siteUrl: selectedLink, startDate, endDate } });

      // Check if response data is an array
      if (Array.isArray(response.data)) {
        setVideos(response.data);
      } else {
        setError('Unexpected data format received');
      }
    } catch (error) {
      console.error('Error fetching videos:', error);

      // Enhanced error handling
      if (error.response) {
        // Handle server-side errors
        setError(`Server error: ${error.response.data || error.response.statusText}`);
      } else if (error.request) {
        // Handle network errors
        setError('Network error: Unable to reach the server');
      } else {
        // Handle other errors
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedLink) {
      fetchVideos();
    }
  }, [selectedLink, startDate, endDate]);

  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        Video Pages
      </Typography>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={fetchVideos}>
        Fetch Videos
      </Button>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {videos.length === 0 && !loading && !error && <Typography>No videos found</Typography>}
      <Grid container spacing={3} marginTop={3}>
        {videos.map((video, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Video {index + 1}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {video}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <NotFoundPage/>
    </Container>
  );
};

export default VideoPages;
