import React from 'react';
import { Sparklines, SparklinesLine } from 'react-sparklines';

const Sparkline = ({ data }) => {
  return (
    <Sparklines data={data} width={100} height={20} margin={0}>
      <SparklinesLine color={data[data.length - 1] > data[0] ? 'green' : 'red'} />
    </Sparklines>
  );
};

export default Sparkline;
