// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import axios from 'axios';
// import CustomDataGrid from './CustomDataGrid';
// import { useDomain } from './context/DomainContext';

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// export default function CustomTabs() {
//     const { selectedLink } = useDomain();
//     const [startDate, setStartDate] = useState('');
//     const [endDate, setEndDate] = useState('');
//   const [value, setValue] = useState(0);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [dateRange, setDateRange] = useState('Last 3 months');

//   useEffect(() => {
//     updateDateRange(dateRange);
//   }, []);

  

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//         fetchData(selectedLink, startDate, endDate);
//     }
//   }, [selectedLink, startDate, endDate]);

//   const fetchData = async (endpoint) => {
//     try {
//       const response = await axios.get(`http://localhost:8080${endpoint}` ,{
//         params:{siteUrl:link , startDate: start ,endDate: end},
//       });
//       const fetchedData = response.data.rows || response.data;

//       setData(
//         fetchedData.map((row, index) => ({
//           id: index + 1,
//           name: row.query || row.page || row.country || row.device || row.searchAppearance || 'Unknown',
//           clicks: row.clicks || 0,
//           impressions: row.impressions || 0,
//         }))
//       );

//       setColumns([
//         { field: 'name', headerName: 'Name', width: 200 },
//         { field: 'clicks', headerName: 'Clicks', width: 150 },
//         { field: 'impressions', headerName: 'Impressions', width: 150 },
//       ]);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const updateDateRange = (range) => {
//     const end = new Date().toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };


//   useEffect(() => {
//     const endpoints = [
//       '/api/keywords',             // For Queries
//       '/api/indexedpages',         // For Pages
//       '/api/countries',            // For Countries
//       '/api/devices',              // For Devices
//       '/api/search-appearance',    // For Search Appearance
//       '/api/product-snippets',     // For Product Snippets
//     ];
//     fetchData(endpoints[value]);
//   }, [value]);

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//         <Tabs value={value} onChange={handleChange} aria-label="custom tabs">
//           <Tab label="Queries" {...a11yProps(0)} />
//           <Tab label="Pages" {...a11yProps(1)} />
//           <Tab label="Countries" {...a11yProps(2)} />
//           <Tab label="Devices" {...a11yProps(3)} />
//           <Tab label="Search Appearance" {...a11yProps(4)} />
//           <Tab label="Product Snippets" {...a11yProps(5)} />
//         </Tabs>
//       </Box>
//       <CustomTabPanel value={value} index={0}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={1}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={2}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={3}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={4}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={5}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//     </Box>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import axios from 'axios';
// import CustomDataGrid from './CustomDataGrid';
// import { useDomain } from './context/DomainContext';

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       style={{ display: value === index ? 'block' : 'none' , color:'black' }}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// export default function CustomTabs() {
//   const { selectedLink } = useDomain();
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [value, setValue] = useState(0);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [dateRange, setDateRange] = useState('Last 3 months');

//   useEffect(() => {
//     updateDateRange(dateRange);
//   }, []);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//       fetchData(selectedLink, startDate, endDate);
//     }
//   }, [selectedLink, startDate, endDate]);

//   const fetchData = async (link, start, end) => {
//     try {
//       const response = await axios.get(`http://localhost:8080${endpoints[value]}`, {
//         params: { siteUrl: link, startDate: start, endDate: end },
//       });

//       const fetchedData = response.data.rows || response.data;
//       console.log("fetchedData :" , fetchedData)

//       setData(
//         fetchedData.map((row, index) => ({
//           id: index + 1,
//           name: row.keys || row.keys || row.keys || row.keys|| row.keys || 'Unknown',
//           clicks: row.clicks || 0,
//           impressions: row.impressions || 0,
//         }))
//       );

//       setColumns([
//         { field: 'name', headerName: 'Name', width: 200 },
//         { field: 'clicks', headerName: 'Clicks', width: 150 },
//         { field: 'impressions', headerName: 'Impressions', width: 150 },
//       ]);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const updateDateRange = (range) => {
//     const end = new Date().toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };

//   const endpoints = [
//     '/api/keywords',             // For Queries
//     '/api/indexedpages',         // For Pages
//     '/api/countries',            // For Countries
//     '/api/devices',              // For Devices
//     '/api/search-appearance',    // For Search Appearance
//     '/api/product-snippets',     // For Product Snippets
//   ];

//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//       fetchData(selectedLink, startDate, endDate);
//     }
//   }, [value]);

//   return (
//     <Box sx={{ width: '100%' }}>
//       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//         <Tabs value={value} onChange={handleChange} aria-label="custom tabs">
//           <Tab label="Queries" {...a11yProps(0)} />
//           <Tab label="Pages" {...a11yProps(1)} />
//           <Tab label="Countries" {...a11yProps(2)} />
//           <Tab label="Devices" {...a11yProps(3)} />
//           <Tab label="Search Appearance" {...a11yProps(4)} />
//           <Tab label="Product Snippets" {...a11yProps(5)} />
//         </Tabs>
//       </Box>
//       <CustomTabPanel value={value} index={0}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={1}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={2}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={3}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={4}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={5}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//     </Box>
//   );
// }



// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import axios from 'axios';
// import CustomDataGrid from './CustomDataGrid';
// import { useDomain } from './context/DomainContext';
// import './CustomTabs.css'; 

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box className="tabs-content">{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// export default function CustomTabs() {
//   const { selectedLink } = useDomain();
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [value, setValue] = useState(0);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [dateRange, setDateRange] = useState('Last 3 months');

//   useEffect(() => {
//     updateDateRange(dateRange);
//   }, [dateRange]);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//       fetchData(selectedLink, startDate, endDate);
//     }
//   }, [selectedLink, startDate, endDate, value]);

//   const fetchData = async (link, start, end) => {
//     try {
//       const response = await axios.get(`https://brank.supportplus.app${endpoints[value]}`, {
//     //   const response = await axios.get(`http://localhost:8080${endpoints[value]}`, {
//         params: { siteUrl: link, startDate: start, endDate: end },
//       });

//       const fetchedData = response.data.rows || response.data;
//       console.log("fetchedData :", fetchedData);

// setData(
//     fetchedData.map((row, index) => ({
//       id: index + 1,
//       name: row.keys || 'Unknown',
//       clicks: row.clicks || 0,
//       impressions: row.impressions || 0,
//       ctr: (row.ctr * 100).toFixed(2) || '0.00',  // Convert CTR to percentage and format
//       position: row.position.toFixed(1) || '0.0', // Format position to one decimal place
//     }))
//   );
  
//   setColumns([
//     { field: 'name', headerName: 'Name', width: 690 },
//     { field: 'clicks', headerName: 'Clicks', width: 100 },
//     { field: 'impressions', headerName: 'Impressions', width: 100 },
//     { field: 'ctr', headerName: 'CTR (%)', width: 100 },          // Added CTR column
//     { field: 'position', headerName: 'Position', width: 100 },    // Added Position column
//   ]);
  
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const updateDateRange = (range) => {
//     const end = new Date().toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };

//   const endpoints = [
//     '/api/keywords',             // For Queries
//     '/api/indexedpages',         // For Pages
//     '/api/countries',            // For Countries
//     '/api/devices',              // For Devices
//     '/api/search-appearance',    // For Search Appearance
//     '/api/product-snippets',     // For Product Snippets
//   ];

//   return (
//     <Box className="tabs-container">
//       <Box className="tabs-header">
//         <Tabs 
//           value={value} 
//           onChange={handleChange} 
//           aria-label="custom tabs"
//         >
//           <Tab label="Queries" {...a11yProps(0)} />
//           <Tab label="Pages" {...a11yProps(1)} />
//           <Tab label="Countries" {...a11yProps(2)} />
//           <Tab label="Devices" {...a11yProps(3)} />
//           <Tab label="Search Appearance" {...a11yProps(4)} />
//           <Tab label="Product Snippets" {...a11yProps(5)} />
//         </Tabs>
  
//       <CustomTabPanel value={value} index={0}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={1}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={2}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={3}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={4}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       <CustomTabPanel value={value} index={5}>
//         <CustomDataGrid rows={data} columns={columns} />
//       </CustomTabPanel>
//       </Box>
//     </Box>
//   );
// }



// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import axios from 'axios';
// import CustomDataGrid from './CustomDataGrid';
// import { useDomain } from './context/DomainContext';
// import { getNames } from 'country-list';  // Import country-list library
// import './CustomTabs.css';

// // Function to map country codes to country names
// // const countryNames = getNames().reduce((acc, { code, name }) => {
// //   acc[code.toLowerCase()] = name; // Map country code to full name
// //   return acc;
// // }, {});

// function CustomTabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && <Box className="tabs-content">{children}</Box>}
//     </div>
//   );
// }

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// export default function CustomTabs() {
//   const { selectedLink } = useDomain();
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [value, setValue] = useState(0);
//   const [data, setData] = useState([]);
//   const [columns, setColumns] = useState([]);
//   const [dateRange, setDateRange] = useState('Last 3 months');

//   useEffect(() => {
//     updateDateRange(dateRange);
//   }, [dateRange]);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//       fetchData(selectedLink, startDate, endDate);
//     }
//   }, [selectedLink, startDate, endDate, value]);

//   const fetchData = async (link, start, end) => {
//     try {
//       const response = await axios.get(`https://brank.supportplus.app${endpoints[value]}`, {
//         // const response = await axios.get(`http://localhost:8080${endpoints[value]}`, {
//         params: { siteUrl: link, startDate: start, endDate: end },
//       });

//       const fetchedData = response.data.rows || response.data;

//       setData(
//         fetchedData.map((row, index) => {
//           const countryCode = row.keys[0]?.toLowerCase();  // Get country code
//           return {
//             id: index + 1,
//             name: value === 2 ? (countryCode ? [countryCode] || 'Unknown' : 'Unknown') : row.keys[0] || 'Unknown',
//             clicks: row.clicks || 0,
//             impressions: row.impressions || 0,
//             ctr: (row.ctr * 100).toFixed(2) || '0.00',  // Convert CTR to percentage and format
//             position: row.position.toFixed(1) || '0.0', // Format position to one decimal place
//           };
//         })
//       );

//       setColumns([
//         {
//           field: 'name',
//           headerName: 'Name',
//           width: 690,
//           renderCell: (params) => {
//             if (value === 1) {
//               // For 'Pages' tab
//               return (
//                 <a href={params.value} target="_blank" rel="noopener noreferrer">
//                   {params.value}
//                 </a>
//               );
//             }
//             return params.value;
//           },
//         },
//         { field: 'clicks', headerName: 'Clicks', width: 100 },
//         { field: 'impressions', headerName: 'Impressions', width: 100 },
//         { field: 'ctr', headerName: 'CTR (%)', width: 100 },          // Added CTR column
//         { field: 'position', headerName: 'Position', width: 100 },    // Added Position column
//       ]);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };

//   const updateDateRange = (range) => {
//     const end = new Date().toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };

//   const endpoints = [
//     '/api/keywords',             // For Queries
//     '/api/indexedpages',         // For Pages
//     '/api/countries',            // For Countries
//     '/api/devices',              // For Devices
//     '/api/search-appearance',    // For Search Appearance
//     '/api/product-snippets',     // For Product Snippets
//   ];

//   return (
//     <Box className="tabs-container">
//       <Box className="tabs-header">
//         <Tabs 
//           value={value} 
//           onChange={handleChange} 
//           aria-label="custom tabs"
//         >
//           <Tab label="Queries" {...a11yProps(0)} />
//           <Tab label="Pages" {...a11yProps(1)} />
//           <Tab label="Countries" {...a11yProps(2)} />
//           <Tab label="Devices" {...a11yProps(3)} />
//           <Tab label="Search Appearance" {...a11yProps(4)} />
//           <Tab label="Product Snippets" {...a11yProps(5)} />
//         </Tabs>

//         <CustomTabPanel value={value} index={0}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={1}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={2}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={3}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={4}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//         <CustomTabPanel value={value} index={5}>
//           <CustomDataGrid rows={data} columns={columns} />
//         </CustomTabPanel>
//       </Box>
//     </Box>
//   );
// }


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import axios from 'axios';
import CustomDataGrid from './CustomDataGrid';
import { useDomain } from './context/DomainContext';
import { getNames } from 'country-list';  // Import country-list library
import './CustomTabs.css';
import TextField from '@mui/material/TextField';

// Function to map country codes to country names
// const countryNames = getNames().reduce((acc, { code, name }) => {
//   acc[code.toLowerCase()] = name; // Map country code to full name
//   return acc;
// }, {});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tabs-content">{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs() {
  const { selectedLink } = useDomain();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [value, setValue] = useState(0);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dateRange, setDateRange] = useState('Last 3 months');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    updateDateRange(dateRange);
  }, [dateRange]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (selectedLink && startDate && endDate) {
      fetchData(selectedLink, startDate, endDate);
    }
  }, [selectedLink, startDate, endDate, value]);

  // const fetchData = async (link, start, end) => {
  //   try {
  //     const response = await axios.get(`https://brank.supportplus.app${endpoints[value]}`, {
  //       params: { siteUrl: link, startDate: start, endDate: end },
  //     });

  //     const fetchedData = response.data.rows || response.data;

  //     // Apply search filter
  //     const filteredData = fetchedData.filter(row => {
  //       const name = value === 2 ? (row.keys[0]?.toLowerCase() || 'Unknown') : row.keys[0] || 'Unknown';
  //       return name.toLowerCase().includes(searchTerm.toLowerCase());
  //     });

  //     setData(
  //       filteredData.map((row, index) => {
  //         const countryCode = row.keys[0]?.toLowerCase();  // Get country code
  //         return {
  //           id: index + 1,
  //           name: value === 2 ? (countryCode ? [countryCode] || 'Unknown' : 'Unknown') : row.keys[0] || 'Unknown',
  //           clicks: row.clicks || 0,
  //           impressions: row.impressions || 0,
  //           ctr: (row.ctr * 100).toFixed(2) || '0.00',  // Convert CTR to percentage and format
  //           position: row.position.toFixed(1) || '0.0', // Format position to one decimal place
  //         };
  //       })
  //     );

  //     setColumns([
  //       {
  //         field: 'name',
  //         headerName: 'Name',
  //         width: 690,
  //         renderCell: (params) => {
  //           if (value === 1) {
  //             // For 'Pages' tab
  //             return (
  //               <a href={params.value} target="_blank" rel="noopener noreferrer">
  //                 {params.value}
  //               </a>
  //             );
  //           }
  //           return params.value;
  //         },
  //       },
  //       { field: 'clicks', headerName: 'Clicks', width: 100 },
  //       { field: 'impressions', headerName: 'Impressions', width: 100 },
  //       { field: 'ctr', headerName: 'CTR (%)', width: 100 },          // Added CTR column
  //       { field: 'position', headerName: 'Position', width: 100 },    // Added Position column
  //     ]);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };


  const fetchData = async (link, start, end) => {
    try {
        // const response = await axios.get(`http://localhost:8080/v1${endpoints[value]}`, {
        const response = await axios.get(`https://brank.supportplus.app/v1${endpoints[value]}`, {
            params: { siteUrl: link, startDate: start, endDate: end },
        });

        const fetchedData = response.data.rows || response.data;

        // Apply search filter
        const filteredData = fetchedData.filter(row => {
            const name = value === 2 ? (row.keys[0] ? row.keys[0].toLowerCase() : 'Unknown') : row.keys[0] || 'Unknown';
            return name.toLowerCase().includes(searchTerm.toLowerCase());
        });

        setData(
            filteredData.map((row, index) => {
                const countryCode = row.keys[0]?.toLowerCase();  // Get country code
                return {
                    id: index + 1,
                    name: value === 2 ? (countryCode ? [countryCode] || 'Unknown' : 'Unknown') : row.keys[0] || 'Unknown',
                    clicks: row.clicks || 0,
                    impressions: row.impressions || 0,
                    ctr: (row.ctr * 100).toFixed(2) || '0.00',  // Convert CTR to percentage and format
                    position: row.position.toFixed(1) || '0.0', // Format position to one decimal place
                };
            })
        );

        setColumns([
            {
                field: 'name',
                headerName: 'Name',
                width: 690,
                renderCell: (params) => {
                    if (value === 1) {
                        // For 'Pages' tab
                        return (
                            <a href={params.value} target="_blank" rel="noopener noreferrer">
                                {params.value}
                            </a>
                        );
                    }
                    return params.value;
                },
            },
            { field: 'clicks', headerName: 'Clicks', width: 100 },
            { field: 'impressions', headerName: 'Impressions', width: 100 },
            { field: 'ctr', headerName: 'CTR (%)', width: 100 },          // Added CTR column
            { field: 'position', headerName: 'Position', width: 100 },    // Added Position column
        ]);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


  const updateDateRange = (range) => {
    const end = new Date().toISOString().split('T')[0];
    let start;

    switch (range) {
      case 'Last 7 days':
        start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Last 28 days':
        start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
        break;
      case 'Last 12 months':
        start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
        break;
      case 'Last 16 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
        break;
      default:
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
    }

    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  const endpoints = [
    '/api/keywords',             // For Queries
    '/api/indexedpages',         // For Pages
    '/api/countries',            // For Countries
    '/api/devices',              // For Devices
    '/api/search-appearance',    // For Search Appearance
    '/api/product-snippets',     // For Product Snippets
  ];

  return (
    <Box className="tabs-container">
      <Box className="tabs-header">
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="custom tabs"
        >
          <Tab label="Queries" {...a11yProps(0)} />
          <Tab label="Pages" {...a11yProps(1)} />
          <Tab label="Countries" {...a11yProps(2)} />
          <Tab label="Devices" {...a11yProps(3)} />
          <Tab label="Search Appearance" {...a11yProps(4)} />
          <Tab label="Product Snippets" {...a11yProps(5)} />
        </Tabs>

        
        <CustomTabPanel value={value} index={0}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <CustomDataGrid rows={data} columns={columns} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
