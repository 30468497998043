import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDomain } from '../context/DomainContext';
import { Card, CardContent, Typography, Container, Grid, Button, TextField, CircularProgress, Alert } from '@mui/material';
import NotFoundPage from '../Notfound';

const BreadcrumbsPage = () => {
  const { selectedLink } = useDomain(); // Get the selectedLink from context
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedLink) {
      fetchBreadcrumbs(selectedLink);
    }
  }, [selectedLink]);

  const fetchBreadcrumbs = async (siteUrl) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('http://localhost:8080/api/breadcrumbs', { params: { siteUrl, startDate, endDate } });

      // Check if response data is an array or the expected format
      if (Array.isArray(response.data)) {
        setBreadcrumbs(response.data);
      } else {
        setError('Unexpected data format received');
      }
    } catch (error) {
      console.error('Error fetching breadcrumbs:', error);

      // Enhanced error handling
      if (error.response) {
        // Handle server-side errors
        setError(`Server error: ${error.response.data || error.response.statusText}`);
      } else if (error.request) {
        // Handle network errors
        setError('Network error: Unable to reach the server');
      } else {
        // Handle other errors
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        Breadcrumbs
      </Typography>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={() => selectedLink && fetchBreadcrumbs(selectedLink)}>
        Fetch Breadcrumbs
      </Button>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {breadcrumbs.length === 0 && !loading && !error && <Typography>No breadcrumbs found</Typography>}
      <Grid container spacing={3} marginTop={3}>
        {breadcrumbs.map((breadcrumb, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  {breadcrumb.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {breadcrumb.url}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <NotFoundPage/>
    </Container>
  );
};

export default BreadcrumbsPage;
