// import React from "react";
// import { useAuth } from "../component/context/authcontext";

// const ActivityLog = () => {
//   const { authData } = useAuth();

//   return (
//     <div className="activity-log">
//       <h1>User Activity Log</h1>
//       <ul>
//         {authData.activityLog.map((activity, index) => (
//           <li key={index}>
//             <strong>{activity.name}</strong> - <strong>{activity.type}</strong>
//             <br />
//             {activity.type === "page visit" && (
//               <>
//                 Page: {activity.page}
//                 <br />
//               </>
//             )}
//             Time: {new Date(activity.timestamp).toLocaleString()}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default ActivityLog;

import React from "react";
import { useAuth } from "../component/context/authcontext";

const ActivityLog = () => {
  const { authData } = useAuth();

  // Guard clause to handle null or undefined authData
  if (!authData || !authData.user) {
    return (
      <div style={styles.container}>
        <p style={styles.error}>No user is logged in or no activity log available.</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>User Activity Log</h1>
      <div style={styles.logContainer}>
        {authData.activityLog.map((activity, index) => (
          <div key={index} style={styles.card}>
            <h2 style={styles.cardTitle}>{activity.name}</h2>
            <p style={styles.cardType}><strong>Type:</strong> {activity.type}</p>
            {activity.type === "page visit" && (
              <p style={styles.cardPage}><strong>Page:</strong> {activity.page}</p>
            )}
            <p style={styles.cardTime}><strong>Time:</strong> {new Date(activity.timestamp).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  heading: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '32px',
    color: '#333',
  },
  logContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid #e0e0e0',
    transition: 'transform 0.2s',
  },
  cardTitle: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#555',
  },
  cardType: {
    fontSize: '18px',
    marginBottom: '10px',
    color: '#777',
  },
  cardPage: {
    fontSize: '16px',
    marginBottom: '10px',
    color: '#888',
  },
  cardTime: {
    fontSize: '14px',
    color: '#999',
  },
  cardHover: {
    transform: 'scale(1.02)',
  },
  error: {
    fontSize: '20px',
    color: 'red',
    textAlign: 'center',
  },
};

export default ActivityLog;


