import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDomain } from '../context/DomainContext'; 
import { Card, CardContent, Typography, Container, Grid, CircularProgress, Box, Alert } from '@mui/material';
import NotFoundPage from '../Notfound';

const RemovalsPage = () => {
  const { selectedLink } = useDomain(); // Get the selectedLink from context
  const [removals, setRemovals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedLink) {
      fetchRemovals(selectedLink);
    }
  }, [selectedLink]);

  const fetchRemovals = async (siteUrl) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('http://localhost:8080/api/removals', { params: { siteUrl } });
      
      // Check if response data is an array or the expected format
      if (Array.isArray(response.data)) {
        setRemovals(response.data);
      } else {
        setError('Unexpected data format received');
      }
    } catch (error) {
      console.error('Error fetching removals:', error);

      // Enhanced error handling
      if (error.response) {
        // Handle server-side errors
        setError(`Server error: ${error.response.data || error.response.statusText}`);
      } else if (error.request) {
        // Handle network errors
        setError('Network error: Unable to reach the server');
      } else {
        // Handle other errors
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        Removals
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {removals.length === 0 && !loading && !error && <Typography>No removals found</Typography>}
      <Grid container spacing={3}>
        {removals.map((removal, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="div">
                  Removal {index + 1}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {removal}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid> */}
      <NotFoundPage/>
    </Container>
  );
};

export default RemovalsPage;
