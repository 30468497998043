import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const PropertyContext = createContext();

export const PropertyProvider = ({ children }) => {
  const [propertyName, setPropertyName] = useState('');
  const [propertyId, setPropertyId] = useState('');
  const [properties, setProperties] = useState([]);
  const [message, setMessage] = useState('');

  // Fetch properties on component mount
  useEffect(() => {
    fetchProperties();
  }, []);

  // Fetch the list of properties
  const fetchProperties = async () => {
    try {
      const response = await axios.get('https://brank.supportplus.app/analytics/v2/api/properties');
    //   const response = await axios.get('http://localhost:8080/analytics/v2/api/properties');
      // Handle the response based on the structure you provided
      if (response.data && Array.isArray(response.data)) {
        setProperties(response.data);
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Failed to fetch properties', error);
    }
  };

  // Add a new property
  const addProperty = async ( propertyName, id) => {
    try {
      const response = await axios.post('https://brank.supportplus.app/analytics/v2/api/properties', {
    //   const response = await axios.post('http://localhost:8080/analytics/v2/api/properties', {
        propertyName,
        propertyId: id,
      });

      if (response.status === 201) {
        setMessage('Property added successfully');
        fetchProperties(); // Refresh the list after adding
      }
    } catch (error) {
      setMessage('Failed to add property');
      console.error('Failed to add property', error);
    }
  };

  // Fetch property details by property ID
  const fetchPropertyById = async (id) => {
    try {
      const response = await axios.get(`https://brank.supportplus.app/analytics/v2/api/properties/${id}`);
    //   const response = await axios.get(`http://localhost:8080/analytics/v2/api/properties/${id}`);
      setPropertyId(response.data.propertyId);
      setPropertyName(response.data.propertyName);
      setMessage('Property fetched successfully');
    } catch (error) {
      setMessage('Failed to fetch property');
      console.error('Failed to fetch property', error);
    }
  };

  return (
    <PropertyContext.Provider
      value={{
        propertyName,
        setPropertyName,
        propertyId,
        setPropertyId,
        properties,
        addProperty,
        fetchPropertyById,
        message,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export default PropertyContext;
