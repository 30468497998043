// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


// function App() {
//   return (
//     <Router>
//       <div>
//         <nav>
//           <ul>
//             <li><Link to="/">Home</Link></li>
//             <li><Link to="/dashboard">Dashboard</Link></li>
//           </ul>
//         </nav>

//         <Routes>
//           <Route path="/" element={<div><h1>Welcome</h1><a href="http://localhost:8080/auth/google">Login with Google</a></div>} />
//           <Route path="/dashboard" element={<Dashboard />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Line } from 'react-chartjs-2';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

// ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// const Dashboard = () => {
//   const [chartData, setChartData] = useState({ labels: [], datasets: [] });
//   const [keywordsData, setKeywordsData] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const accessToken = new URLSearchParams(window.location.search).get('access_token');
//         const response = await axios.get('http://localhost:8080/api/searchconsole', {
//           params: { access_token: accessToken },
//         });

//         if (Array.isArray(response.data.rows)) {
//           const dates = response.data.rows.map(row => row.keys[0]);
//           const clicks = response.data.rows.map(row => row.clicks);
          
//           // Prepare keyword data
//           const keywordsData = response.data.rows.map(row => ({
//             date: row.keys[0],
//             keyword: row.keys[1],
//             clicks: row.clicks,
//           }));

//           setChartData({
//             labels: dates,
//             datasets: [
//               {
//                 label: 'Clicks Over Time',
//                 data: clicks,
//                 borderColor: 'rgba(75,192,192,1)',
//                 backgroundColor: 'rgba(75,192,192,0.2)',
//               },
//             ],
//           });

//           setKeywordsData(keywordsData);
//         } else {
//           console.error('Invalid data format received from backend');
//         }
//       } catch (error) {
//         console.error('Error fetching data', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <h1>Site Data Dashboard</h1>
//       <div style={{ height: '300px', width: '80%' }}>
//         <Line data={chartData} />
//       </div>
//       <div style={{marginTop:'380px'}}>
//         <h2>Keywords with Daily Hits</h2>
//         <table>
//           <thead>
//             <tr>
//               <th>Date</th>
//               <th>Keyword</th>
//               <th>Clicks</th>
//             </tr>
//           </thead>
//           <tbody>
//             {keywordsData.map((data, index) => (
//               <tr key={index}>
//                 <td>{data.date}</td>
//                 <td style={{marginLeft:'500px'}}>{data.keyword}</td>
//                 <td>{data.clicks}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// function App() {
//   return (
//     <Router>
//       <div>
//         <nav>
//           <ul>
//             <li><Link to="/">Home</Link></li>
//             <li><Link to="/dashboard">Dashboard</Link></li>
//           </ul>
//         </nav>

//         <Routes>
//           <Route path="/" element={<div><h1>Welcome</h1><a href="http://localhost:8080/auth/google">Login with Google</a></div>} />
//           <Route path="/dashboard" element={<Dashboard />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;



import React from 'react';
import Dashboard from './component/Dashboard';
import { BrowserRouter as Router, Route, Routes , Navigate} from 'react-router-dom';
import KeywordHits from './component/KeywordHits';
import IndexedPages from './component/IndexedPages';
import SiteData from './component/SiteData';
import { useAuth } from './component/context/authcontext';
import { AuthProvider } from './component/context/authcontext';
import Login from './component/auth/login';


function App() {

  const { authData, login } = useAuth();
  const handleLogin = (userData) => {
    login(userData); 
  };

  
  return (
   
    
      <Dashboard>
             
  
      </Dashboard>
          
   
  );
}

export default App;
