// import React from 'react';
// import ReactDOM from 'react-dom/client';
// // import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { AuthProvider} from './component/context/authcontext';
// import { DomainProvider } from './component/context/DomainContext';
// // import './index.css';
// import './fonts.css'; 
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { createTheme, MantineProvider } from '@mantine/core';


// const root = ReactDOM.createRoot(document.getElementById('root'));

// const queryClient = new QueryClient();
// root.render(

//   <React.StrictMode>
//     <MantineProvider theme={''}>
//       <QueryClientProvider client={queryClient}>
//     <DomainProvider>
//      <AuthProvider>
//       <App />
//      </AuthProvider>
//     </DomainProvider>
//     </QueryClientProvider>,
//     </MantineProvider>
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './component/context/authcontext';
import { DomainProvider } from './component/context/DomainContext';
import './fonts.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme, MantineProvider } from '@mantine/core';
import { BrowserRouter as Router } from 'react-router-dom';  
import { PropertyProvider } from './component/context/PropertyContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <MantineProvider theme={''}>
      <QueryClientProvider client={queryClient}>
    
      <Router> {/* Ensure Router wraps the AuthProvider */}
      <PropertyProvider>
          <DomainProvider>
            <AuthProvider>
              <App />  {/* AuthProvider is now at a higher level */}
            </AuthProvider>
          </DomainProvider>
          </PropertyProvider>
        </Router>
    
      </QueryClientProvider>
    </MantineProvider>
  </React.StrictMode>
);

reportWebVitals();

