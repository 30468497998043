import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NotFoundPage from '../Notfound';

const CoreWebVitalsPage = () => {
  const [vitals, setVitals] = useState([]);
  const [siteUrl, setSiteUrl] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchVitals = async () => {
    try {
      const response = await axios.get('/api/core-web-vitals', { params: { siteUrl, startDate, endDate } });
      setVitals(response.data);
    } catch (error) {
      console.error('Error fetching core web vitals:', error);
    }
  };

  return (
    <div>
      {/* <h1>Core Web Vitals</h1>
      <input
        type="text"
        placeholder="Enter site URL"
        value={siteUrl}
        onChange={(e) => setSiteUrl(e.target.value)}
      />
      <input
        type="date"
        placeholder="Start Date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <input
        type="date"
        placeholder="End Date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
      <button onClick={fetchVitals}>Fetch Core Web Vitals</button>
      <ul>
        {vitals.map((vital, index) => (
          <li key={index}>{vital}</li>
        ))}
      </ul> */}
      <NotFoundPage/>
    </div>
  );
};

export default CoreWebVitalsPage;
