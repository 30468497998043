import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDomain } from '../context/DomainContext'; 
import { Card, CardContent, Typography, Container, Grid, CircularProgress, Alert } from '@mui/material';
import NotFoundPage from '../Notfound';

const HttpsPage = () => {
  const { selectedLink } = useDomain(); // Get the selectedLink from context
  const [httpsStatus, setHttpsStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedLink) {
      fetchHttps(selectedLink);
    }
  }, [selectedLink]);

  const fetchHttps = async (siteUrl) => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get('http://localhost:8080/api/https', { params: { siteUrl } });
      
      // Check if response data contains the expected field
      if (response.data && typeof response.data.isHttps === 'boolean') {
        setHttpsStatus(response.data.isHttps);
      } else {
        setError('Unexpected data format received');
      }
    } catch (error) {
      console.error('Error fetching HTTPS status:', error);

      // Enhanced error handling
      if (error.response) {
        // Handle server-side errors
        setError(`Server error: ${error.response.data || error.response.statusText}`);
      } else if (error.request) {
        // Handle network errors
        setError('Network error: Unable to reach the server');
      } else {
        // Handle other errors
        setError(`Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {/* <Typography variant="h4" gutterBottom>
        HTTPS Status
      </Typography>
      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {httpsStatus !== null && !loading && !error && (
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Protocol Status
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The site uses {httpsStatus ? 'HTTPS' : 'HTTP'}.
            </Typography>
          </CardContent>
        </Card>
      )}
      {httpsStatus === null && !loading && !error && <Typography>No HTTPS status available</Typography>} */}
      <NotFoundPage/>
    </Container>
  );
};

export default HttpsPage;
