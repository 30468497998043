// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import FormControl from '@mui/material/FormControl';
// import { useDomain } from './context/DomainContext';
// import { Box, Grid } from '@mui/material';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import DownloadIcon from '@mui/icons-material/Download';
// import ListItemText from '@mui/material/ListItemText';
// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import { Divider ,   Typography, } from '@mui/material';
// import Button from '@mui/material/Button';

// const KeywordHits = () => {
//   const { selectedLink } = useDomain();
//   const [startDate, setStartDate] = useState('');
//   const [endDate, setEndDate] = useState('');
//   const [keywords, setKeywords] = useState([]);
//   const [topKeywords, setTopKeywords] = useState([]);
//   const [bottomKeywords, setBottomKeywords] = useState([]);
//   const [topKeywordsByClicks, setTopKeywordsByClicks] = useState([]);
//   const [topKeywordsByImpressions, setTopKeywordsByImpressions] = useState([]);
//   const [dateRange, setDateRange] = useState('Last 3 months');

//   useEffect(() => {
//     updateDateRange(dateRange);
//   }, [dateRange]);

//   useEffect(() => {
//     if (selectedLink && startDate && endDate) {
//       fetchKeywords(selectedLink, startDate, endDate);
//     }
//   }, [selectedLink, startDate, endDate]);

//   const fetchKeywords = async (link, start, end) => {
//     try {
//       const response = await axios.get('https://brank.supportplus.app/v1/api/keywords', {
//         params: { siteUrl: link, startDate: start, endDate: end },
//       });
//       // console.log(" Keyword response :", response);

//       if (Array.isArray(response.data.rows)) {
//         const rowsWithId = response.data.rows.map((row, index) => ({
//           id: index,
//           date: row.date || new Date().toISOString().split('T')[0], // Check if the API returns a date
//           keyword: row.keys[0],
//           clicks: row.clicks,
//           impressions: row.impressions,
//           ctr: (row.ctr * 100).toFixed(2) + '%',
//           position: row.position.toFixed(1),
//         }));

//         // Sort rows alphabetically by keyword
//         rowsWithId.sort((a, b) => a.keyword.localeCompare(b.keyword));

//         // Filter out rows that fall outside the selected date range
//         const filteredRows = rowsWithId.filter(
//           (row) => row.date >= start && row.date <= end
//         );

//         setKeywords(filteredRows);

//         // Sort by position to find top and bottom keywords
//         const sortedByPosition = [...filteredRows].sort((a, b) => a.position - b.position);
//         setTopKeywords(sortedByPosition.slice(0, 10));
//         setBottomKeywords(sortedByPosition.slice(-10).reverse());

//         // Sort by clicks and impressions
//         const sortedByClicks = [...filteredRows].sort((a, b) => b.clicks - a.clicks);
//         const sortedByImpressions = [...filteredRows].sort((a, b) => b.impressions - a.impressions);
        
//         setTopKeywordsByClicks(sortedByClicks.slice(0, 10));
//         setTopKeywordsByImpressions(sortedByImpressions.slice(0, 10));
//       } else {
//         console.error('Invalid data format received from backend');
//       }
//     } catch (error) {
//       console.error('Error fetching data', error);
//     }
//   };

//   const updateDateRange = (range) => {
//     const end = new Date().toISOString().split('T')[0];
//     let start;

//     switch (range) {
//       case 'Last 7 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
//         break;
//       case 'Last 28 days':
//         start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
//         break;
//       case 'Last 3 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//         break;
//       case 'Last 6 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
//         break;
//       case 'Last 12 months':
//         start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
//         break;
//       case 'Last 16 months':
//         start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
//         break;
//       default:
//         start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
//     }

//     setStartDate(start);
//     setEndDate(end);
//   };

//   const columns = [
//     // { field: 'date', headerName: 'Date', width: 150 },
//     { field: 'keyword', headerName: 'Keyword', width: 250 },
//     { field: 'clicks', headerName: 'Clicks', width: 150 },
//     { field: 'impressions', headerName: 'Impressions', width: 150 },
//     { field: 'ctr', headerName: 'CTR (%)', width: 150 },
//     { field: 'position', headerName: 'Position', width: 150 },
//   ];

  
//   return (
//     <>
//       <Box
//        sx={{
//                   position: 'fixed',
//                   top: '79px',
//                   left: '300px',
//                   right: '0px',
//                   zIndex: 1000,
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   padding: '10px 20px',
//                   backgroundColor: '#f0f3f8',

//                 }}
//       >
//         <ListItem disablePadding>
//              <ListItemButton>
//                <ListItemText
//                 primary="Keywords on Search result"
//                 primaryTypographyProps={{
//                   sx: {
//                     color: 'rgb(31, 31, 31)',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '20px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//         <Button
//           variant="outlined"
//           startIcon={<DownloadIcon />}
//           sx={{
//             color: '#1f1f1f',
//             borderColor: '#1f1f1f',
//             textTransform: 'none',
//           }}
//         >
//           Export
//         </Button>
//       </Box>

//       <Divider sx={{ my: 2 }} />

//       <Box
//           sx={{
//                       position: 'fixed',
//                       top: '140px',
//                       left: '300px',
//                       right: '0px',
//                       zIndex: 1000,
//                       display: 'flex',
//                       alignItems: 'center',
//                       justifyContent: 'space-between',
//                       // padding: '10px 20px',s
//                       backgroundColor: '#f0f3f8',
//                     }}
//       >
//         <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
//             <Select
//               labelId="date-range-label"
//               id="date-range-select"
//               value={dateRange}
//               // label="Date Range"
//               onChange={(e) => {
//                 const newRange = e.target.value;
//                 setDateRange(newRange);
//                 updateDateRange(newRange);
//               }}
//               sx={{
//                 backgroundColor: 'rgb(211, 227, 253)',
//                 borderRadius: '25px',
//                 // marginRight:'60px',
//                 '& .MuiSelect-select': {
//                   padding: '10px',
//                   borderRadius: '25px',
//                 },
//               }}
//             >
//               <MenuItem value="Last 7 days">Last 7 days</MenuItem>
//               <MenuItem value="Last 28 days">Last 28 days</MenuItem>
//               <MenuItem value="Last 3 months">Last 3 months</MenuItem>
//               <MenuItem value="Last 6 months">Last 6 months</MenuItem>
//               <MenuItem value="Last 12 months">Last 12 months</MenuItem>
//               <MenuItem value="Last 16 months">Last 16 months</MenuItem>
//               <MenuItem value="Custom">Custom</MenuItem>
//             </Select>
//           </FormControl>
//           {dateRange === 'Custom' && (
//             <>
//               <TextField
//                 label="Start Date"
//                 type="date"
//                 value={startDate}
//                 onChange={(e) => setStartDate(e.target.value)}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 required
//                 sx={{ minWidth: 250 }}
//               />
//               <TextField
//                 label="End Date"
//                 type="date"
//                 value={endDate}
//                 onChange={(e) => setEndDate(e.target.value)}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 required
//                 sx={{ minWidth: 250, ms: 4 }}
//               />
//             </>
//           )}
//         <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
//           <ListItem disablePadding>
//             <ListItemText
//               primary="Last updated: 3 hours ago"
//               primaryTypographyProps={{
//                 sx: {
//                   color: 'grey',
//                   fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   fontSize: { xs: '12px', md: '15px' },
//                   lineHeight: '20px',
//                 },
//               }}
//             />
//             <Tooltip title="All dates recorded and displayed in Pacific Time Zone (PT)">
//               <IconButton>
//                 <HelpOutlineIcon sx={{ color: 'grey' }} />
//               </IconButton>
//             </Tooltip>
//           </ListItem>
//         </Box>
//       </Box>

//       <Box
//         sx={{
//           height: 400,
//           width: '100%',
//           mt: { xs: '100px', md: '150px' },
//           borderRadius: '10px',
//           backgroundColor: '#ffffff',
//           boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//         }}
//       >
//         <DataGrid rows={keywords} columns={columns} pageSize={5} />
//       </Box>

//       <Grid container spacing={3} sx={{ mt: 4 }}>
//         <Grid item xs={12} md={12}>
//           <Box padding={2}>
//             <Typography variant="h6" component="h3">
//               Top 10 Keywords by Position
//             </Typography>
//             <Box
//               sx={{
//                 height: 400,
//                 width: '100%',
//                 backgroundColor: '#ffffff',
//                 borderRadius: '10px',
//                 boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//                 mt: 2,
//               }}
//             >
//               <DataGrid rows={topKeywords} columns={columns} pageSize={5} />
//             </Box>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={12}>
//           <Box padding={2}>
//             <Typography variant="h6" component="h3">
//               Bottom 10 Keywords by Position
//             </Typography>
//             <Box
//               sx={{
//                 height: 400,
//                 width: '100%',
//                 backgroundColor: '#ffffff',
//                 borderRadius: '10px',
//                 boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//                 mt: 2,
//               }}
//             >
//               <DataGrid rows={bottomKeywords} columns={columns} pageSize={5} />
//             </Box>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={12}>
//           <Box padding={2}>
//             <Typography variant="h6" component="h3">
//               Top 10 Keywords by Clicks
//             </Typography>
//             <Box
//               sx={{
//                 height: 400,
//                 width: '100%',
//                 backgroundColor: '#ffffff',
//                 borderRadius: '10px',
//                 boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//                 mt: 2,
//               }}
//             >
//               <DataGrid rows={topKeywordsByClicks} columns={columns} pageSize={5} />
//             </Box>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={12}>
//           <Box padding={2}>
//             <Typography variant="h6" component="h3">
//               Top 10 Keywords by Impressions
//             </Typography>
//             <Box
//               sx={{
//                 height: 400,
//                 width: '100%',
//                 backgroundColor: '#ffffff',
//                 borderRadius: '10px',
//                 boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
//                 mt: 2,
//               }}
//             >
//               <DataGrid rows={topKeywordsByImpressions} columns={columns} pageSize={5} />
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default KeywordHits;




import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useDomain } from './context/DomainContext';
import { Box, Grid, Divider, Typography, Button, Tooltip, IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const fetchKeywords = async (link, startDate, endDate) => {
  const response = await axios.get('https://brank.supportplus.app/v1/api/keywords', {
    params: { siteUrl: link, startDate, endDate },
  });

  if (Array.isArray(response.data.rows)) {
    const rowsWithId = response.data.rows.map((row, index) => ({
      id: index,
      date: row.date || new Date().toISOString().split('T')[0],
      keyword: row.keys[0],
      clicks: row.clicks,
      impressions: row.impressions,
      ctr: (row.ctr * 100).toFixed(2) + '%',
      position: row.position.toFixed(1),
    }));

    rowsWithId.sort((a, b) => a.keyword.localeCompare(b.keyword));

    return rowsWithId;
  } else {
    throw new Error('Invalid data format received from backend');
  }
};

const KeywordHits = () => {
  const { selectedLink } = useDomain();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState('Last 3 months');

  const [topKeywords, setTopKeywords] = useState([]);
  const [bottomKeywords, setBottomKeywords] = useState([]);
  const [topKeywordsByClicks, setTopKeywordsByClicks] = useState([]);
  const [topKeywordsByImpressions, setTopKeywordsByImpressions] = useState([]);

  const queryKey = ['keywords', selectedLink, startDate, endDate];
  const queryFn = () => fetchKeywords(selectedLink, startDate, endDate);

  const { data: keywords, isLoading, refetch } = useQuery({
    queryKey,
    queryFn,
    enabled: !!selectedLink && !!startDate && !!endDate,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    updateDateRange(dateRange);
  }, [dateRange]);

  const updateDateRange = (range) => {
    const end = new Date().toISOString().split('T')[0];
    let start;

    switch (range) {
      case 'Last 7 days':
        start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Last 28 days':
        start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
        break;
      case 'Last 12 months':
        start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
        break;
      case 'Last 16 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
        break;
      default:
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
    }

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (keywords) {
      const sortedByPosition = [...keywords].sort((a, b) => a.position - b.position);
      setTopKeywords(sortedByPosition.slice(0, 10));
      setBottomKeywords(sortedByPosition.slice(-10).reverse());

      const sortedByClicks = [...keywords].sort((a, b) => b.clicks - a.clicks);
      const sortedByImpressions = [...keywords].sort((a, b) => b.impressions - a.impressions);

      setTopKeywordsByClicks(sortedByClicks.slice(0, 10));
      setTopKeywordsByImpressions(sortedByImpressions.slice(0, 10));
    }
  }, [keywords]);

  const columns = [
    { field: 'keyword', headerName: 'Keyword', width: 250 },
    { field: 'clicks', headerName: 'Clicks', width: 150 },
    { field: 'impressions', headerName: 'Impressions', width: 150 },
    { field: 'ctr', headerName: 'CTR (%)', width: 150 },
    { field: 'position', headerName: 'Position', width: 150 },
  ];

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '79px',
          left: '300px',
          right: '0px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 20px',
          backgroundColor: '#f0f3f8',
        }}
      >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemText
              primary="Keywords on Search result"
              primaryTypographyProps={{
                sx: {
                  color: 'rgb(31, 31, 31)',
                  fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                  fontWeight: 500,
                  fontSize: '20px',
                  lineHeight: '20px',
                },
              }}
            />
          </ListItemButton>
        </ListItem>
        <Button
          variant="outlined"
          startIcon={<DownloadIcon />}
          sx={{
            color: '#1f1f1f',
            borderColor: '#1f1f1f',
            textTransform: 'none',
          }}
        >
          Export
        </Button>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box
        sx={{
          position: 'fixed',
          top: '140px',
          left: '300px',
          right: '0px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#f0f3f8',
        }}
      >
        <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
          <Select
            labelId="date-range-label"
            id="date-range-select"
            value={dateRange}
            onChange={(e) => {
              const newRange = e.target.value;
              setDateRange(newRange);
              updateDateRange(newRange);
            }}
            sx={{
              backgroundColor: 'rgb(211, 227, 253)',
              borderRadius: '25px',
              '& .MuiSelect-select': {
                padding: '10px',
                borderRadius: '25px',
              },
            }}
          >
            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
            <MenuItem value="Last 28 days">Last 28 days</MenuItem>
            <MenuItem value="Last 3 months">Last 3 months</MenuItem>
            <MenuItem value="Last 6 months">Last 6 months</MenuItem>
            <MenuItem value="Last 12 months">Last 12 months</MenuItem>
            <MenuItem value="Last 16 months">Last 16 months</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>
        {dateRange === 'Custom' && (
          <>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{ minWidth: 250 }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{ minWidth: 250 }}
            />
          </>
        )}
      </Box>

      <Box
        sx={{
          height: 400,
          width: '100%',
          mt: { xs: '100px', md: '150px' },
          borderRadius: '10px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <DataGrid rows={keywords || []} columns={columns} pageSize={5} loading={isLoading} />
      </Box>

      <Grid container spacing={3} sx={{ mt: 4 }}>
        <Grid item xs={12} md={12}>
          <Box padding={2}>
            <Typography variant="h6" component="h3">
              Top 10 Keywords by Position
            </Typography>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                mt: 2,
              }}
            >
              <DataGrid rows={topKeywords} columns={columns} pageSize={5} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box padding={2}>
            <Typography variant="h6" component="h3">
              Bottom 10 Keywords by Position
            </Typography>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                mt: 2,
              }}
            >
              <DataGrid rows={bottomKeywords} columns={columns} pageSize={5} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box padding={2}>
            <Typography variant="h6" component="h3">
              Top 10 Keywords by Clicks
            </Typography>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                mt: 2,
              }}
            >
              <DataGrid rows={topKeywordsByClicks} columns={columns} pageSize={5} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box padding={2}>
            <Typography variant="h6" component="h3">
              Top 10 Keywords by Impressions
            </Typography>
            <Box
              sx={{
                height: 400,
                width: '100%',
                backgroundColor: '#ffffff',
                borderRadius: '10px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                mt: 2,
              }}
            >
              <DataGrid rows={topKeywordsByImpressions} columns={columns} pageSize={5} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default KeywordHits;
