// import React, { createContext, useState, useContext, useEffect } from "react";
// import { Toaster, toast } from "react-hot-toast";

// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//   const [authData, setAuthData] = useState(() => {
//     const storedAuthData = localStorage.getItem("authData");
//     return storedAuthData
//       ? JSON.parse(storedAuthData)
//       : { user: null, role: null, email: null, sessionTimeout: null };
//   });

//   let sessionTimeout;



//   const login = async (userData) => {
//     const { token, role, email, name } = userData;
//     const newAuthData = { user: token, role, email, name };

//     const sessionTimeoutValue = Date.now() + 2 * 60 * 60 * 1000;
//     setAuthData({ ...newAuthData, sessionTimeout: sessionTimeoutValue });
//     localStorage.setItem("authData", JSON.stringify({ ...newAuthData, sessionTimeout: sessionTimeoutValue }));

//     clearTimeout(sessionTimeout);
//     sessionTimeout = setTimeout(logout, 2 * 60 * 60 * 1000);

    

//     toast.success(`Welcome back, ${name}!`, {
//       position: 'top-right',
//     });
//   };

//   const logout = () => {
//     setAuthData({ user: null, role: null, email: null, sessionTimeout: null });
//     localStorage.removeItem("authData");
//     clearTimeout(sessionTimeout);
//   };

//   useEffect(() => {
//     const storedAuthData = localStorage.getItem("authData");
//     if (storedAuthData) {
//       const parsedAuthData = JSON.parse(storedAuthData);
//       let { sessionTimeout } = parsedAuthData;

//       if (sessionTimeout && sessionTimeout > Date.now()) {
//         const timeLeft = sessionTimeout - Date.now();
//         sessionTimeout = setTimeout(logout, timeLeft);
//         setAuthData(parsedAuthData);
//       } else {
//         logout();
//       }
//     }
//   }, []);

//   const isAuthenticated = authData.user !== null;

//   return (
//     <AuthContext.Provider value={{ authData, login, logout, isAuthenticated }}>
//       {children}
//       <Toaster />
//     </AuthContext.Provider>
//   );
// };




// import React, { createContext, useState, useContext, useEffect } from "react";
// import { Toaster, toast } from "react-hot-toast";
// import { useLocation } from "react-router-dom"; 


// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//   const [authData, setAuthData] = useState(() => {
//     const storedAuthData = localStorage.getItem("authData");
//     return storedAuthData
//       ? JSON.parse(storedAuthData)
//       : { user: null, role: null, email: null, name: null, sessionTimeout: null, activityLog: [] };
//   });

//   let sessionTimeout;
//   const location = useLocation(); 

//   const login = async (userData) => {
//     const { token, role, email, name } = userData;

//     const newAuthData = {
//       user: token,
//       role,
//       email,
//       name,
//       activityLog: [
//         ...authData.activityLog,
//         { type: "login", timestamp: new Date(), name }
//       ]
//     };

//     const sessionTimeoutValue = Date.now() + 2 * 60 * 60 * 1000;
//     setAuthData({ ...newAuthData, sessionTimeout: sessionTimeoutValue });
//     localStorage.setItem(
//       "authData",
//       JSON.stringify({ ...newAuthData, sessionTimeout: sessionTimeoutValue })
//     );

//     clearTimeout(sessionTimeout);
//     sessionTimeout = setTimeout(logout, 2 * 60 * 60 * 1000);

//     toast.success(`Welcome back, ${name}!`, {
//       position: "top-right",
//     });
//   };

//   const logout = () => {
//     const logoutTime = new Date();
//     const updatedAuthData = {
//       ...authData,
//       activityLog: [
//         ...authData.activityLog,
//         { type: "logout", timestamp: logoutTime, name: authData.name }
//       ]
//     };
//     setAuthData({ user: null, role: null, email: null, name: null, sessionTimeout: null, activityLog: updatedAuthData.activityLog });
//     localStorage.removeItem("authData");
//     clearTimeout(sessionTimeout);
//   };

//   const trackPageVisit = (pathname) => {
//     const updatedAuthData = {
//       ...authData,
//       activityLog: [
//         ...authData.activityLog,
//         { type: "page visit", page: pathname, timestamp: new Date(), name: authData.name }
//       ]
//     };
//     setAuthData(updatedAuthData);
//     localStorage.setItem("authData", JSON.stringify(updatedAuthData));
//   };

//   useEffect(() => {
//     const storedAuthData = localStorage.getItem("authData");
//     if (storedAuthData) {
//       const parsedAuthData = JSON.parse(storedAuthData);
//       let { sessionTimeout } = parsedAuthData;

//       if (sessionTimeout && sessionTimeout > Date.now()) {
//         const timeLeft = sessionTimeout - Date.now();
//         sessionTimeout = setTimeout(logout, timeLeft);
//         setAuthData(parsedAuthData);
//       } else {
//         logout();
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (authData.user) {
//       trackPageVisit(location.pathname); // Track the page visit whenever the location changes
//     }
//   }, [location]);

//   const isAuthenticated = authData.user !== null;

//   return (
//     <AuthContext.Provider value={{ authData, login, logout, isAuthenticated }}>
//       {children}
//       <Toaster />
//     </AuthContext.Provider>
//   );
// };
import React, { createContext, useState, useContext, useEffect } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const storedAuthData = localStorage.getItem("authData");
    return storedAuthData ? JSON.parse(storedAuthData) : null;
  });

  const [allUserActivities, setAllUserActivities] = useState(() => {
    const storedActivities = localStorage.getItem("allUserActivities");
    return storedActivities ? JSON.parse(storedActivities) : [];
  });

  const location = useLocation();
  let sessionTimeoutId;

  const login = async (userData) => {
    const { token, role, email, name } = userData;
    const sessionTimeoutValue = Date.now() + 2 * 60 * 60 * 1000; // 2 hours timeout

    const newAuthData = {
      user: token,
      role,
      email,
      name,
      sessionTimeout: sessionTimeoutValue,
      activityLog: [
        {
          type: "login",
          timestamp: new Date(),
          page: location.pathname,
          name,
        },
      ],
    };

    setAuthData(newAuthData);
    localStorage.setItem("authData", JSON.stringify(newAuthData));

    // Update all user activities
    if (role === 'developer') {
      const updatedAllActivities = [
        ...allUserActivities,
        {
          type: "developer_login",
          timestamp: new Date(),
          page: location.pathname,
          name,
        },
      ];
      setAllUserActivities(updatedAllActivities);
      localStorage.setItem("allUserActivities", JSON.stringify(updatedAllActivities));
    }

    clearTimeout(sessionTimeoutId);
    sessionTimeoutId = setTimeout(logout, 2 * 60 * 60 * 1000);

    toast.success(`Welcome back, ${name}!`, {
      position: "top-right",
    });
  };

  const logout = () => {
    if (authData) {
      const updatedAuthData = {
        ...authData,
        activityLog: [
          ...authData.activityLog,
          {
            type: "logout",
            timestamp: new Date(),
            page: location.pathname,
            name: authData.name,
          },
        ],
      };

      localStorage.setItem("authData", JSON.stringify(updatedAuthData));
    }

    setAuthData(null);
    localStorage.removeItem("authData");
    clearTimeout(sessionTimeoutId);
  };

  const trackPageVisit = (pathname) => {
    if (!authData) return;

    const updatedAuthData = {
      ...authData,
      activityLog: [
        ...authData.activityLog,
        {
          type: "page visit",
          page: pathname,
          timestamp: new Date(),
          name: authData.name,
        },
      ],
    };

    setAuthData(updatedAuthData);
    localStorage.setItem("authData", JSON.stringify(updatedAuthData));

    // Update all user activities
    if (authData.role === 'admin') {
      const updatedAllActivities = [
        ...allUserActivities,
        {
          type: "page_visit",
          timestamp: new Date(),
          page: pathname,
          name: authData.name,
        },
      ];
      setAllUserActivities(updatedAllActivities);
      localStorage.setItem("allUserActivities", JSON.stringify(updatedAllActivities));
    }
  };

  useEffect(() => {
    const storedAuthData = localStorage.getItem("authData");
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      const { sessionTimeout } = parsedAuthData;

      if (sessionTimeout && sessionTimeout > Date.now()) {
        const timeLeft = sessionTimeout - Date.now();
        sessionTimeoutId = setTimeout(logout, timeLeft);
        setAuthData(parsedAuthData);
      } else {
        logout();
      }
    }
  }, []);

  useEffect(() => {
    if (authData && authData.user) {
      trackPageVisit(location.pathname);
    }
  }, [location]);

  const isAuthenticated = authData !== null;
  const isAdmin = authData && authData.role === 'admin';

  return (
    <AuthContext.Provider value={{ authData, login, logout, isAuthenticated, isAdmin, allUserActivities }}>
      {children}
      <Toaster />
    </AuthContext.Provider>
  );
};
