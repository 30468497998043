// import React , {useState} from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import Box from '@mui/material/Box';
// import Sparkline from './Sparkline';
// import TextField from '@mui/material/TextField';

// const LinkCell = ({ value }) => {
//   return (
//     <a href={value} style={styles.link} target="_blank" rel="noopener noreferrer">
//       {value}
//     </a>
//   );
// };

// const styles = {
//   link: {
//     color: 'inherit', // Keep the default text color
//     textDecoration: 'none', // Remove the underline
//     cursor: 'pointer', // Add pointer cursor for better UX
//     fontWeight: '500', // Optional: make the link text slightly bolder
//     '&:hover': {
//       textDecoration: 'underline', // Optional: underline on hover
//     },
//   },
//   button: {
//     padding: '10px 20px',
//     borderRadius: '5px',
//     border: 'none',
//     backgroundColor: '#007bff',
//     color: 'white',
//     cursor: 'pointer',
//     fontSize: '16px',
//     fontWeight: '500',
//     transition: 'background-color 0.3s, transform 0.2s',
//     marginTop: '16px',
//     display: 'inline-block',
//     textAlign: 'center',
//     outline: 'none',
//   },
//   buttonHover: {
//     backgroundColor: '#0056b3',
//     transform: 'scale(1.05)',
//   },
// };

// export const TrendingPages = ({ trendingPages }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [viewAll, setViewAll] = useState(false);

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleToggleView = () => {
//     setViewAll(!viewAll);
//   };

//   const filteredPages = trendingPages.filter((page) =>
//     page.page.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

//   const columns = [
//     { 
//       field: 'page', 
//       headerName: 'Page', 
//       width: 750,
//       renderCell: (params) => <LinkCell value={params.value} />,
//     },
//     { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
//     { field: 'newClicks', headerName: 'New Clicks', width: 125 },
//     { 
//       field: 'trend',
//       headerName: 'Trend (%)',
//       width: 150,
//       renderCell: (params) => <Sparkline data={params.row.trendData} />,
//     },
//   ];

//   return (
//     <Box>
//       <h2>Top Trending Pages</h2>
//       <Box sx={{ marginBottom: '16px' }}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={searchQuery}
//           onChange={handleSearchChange}
//         />
//       </Box>
//       <DataGrid
//         rows={displayedPages}
//         columns={columns}
//         pageSize={5}
//         autoHeight
//         disableSelectionOnClick
//         getRowId={(row) => row.page} 
//       />
//       <Box sx={{ marginTop: '16px' }}>
//         <button
//           onClick={handleToggleView}
//           style={{
//             ...styles.button,
//             ...(viewAll ? styles.buttonHover : {}),
//           }}
//         >
//           {viewAll ? 'Show Top 10' : 'Show All'}
//         </button>
//       </Box>
//     </Box>
//   );
// };

// export const DecliningPages = ({ decliningPages }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [viewAll, setViewAll] = useState(false);

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleToggleView = () => {
//     setViewAll(!viewAll);
//   };

//   const filteredPages = decliningPages.filter((page) =>
//     page.page.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

//   const columns = [
//     { 
//       field: 'page', 
//       headerName: 'Page', 
//       width: 750,
//       renderCell: (params) => <LinkCell value={params.value} />,
//     },
//     { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
//     { field: 'newClicks', headerName: 'New Clicks', width: 125 },
//     { 
//       field: 'trend',
//       headerName: 'Trend (%)',
//       width: 150,
//       renderCell: (params) => <Sparkline data={params.row.trendData} />,
//     },
//   ];

//   return (
//     <Box>
//       <h2>Top Declining Pages</h2>
//       <Box sx={{ marginBottom: '16px' }}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={searchQuery}
//           onChange={handleSearchChange}
//         />
//       </Box>
//       <DataGrid
//         rows={displayedPages}
//         columns={columns}
//         pageSize={5}
//         autoHeight
//         disableSelectionOnClick
//         getRowId={(row) => row.page} 
//       />
//       <Box sx={{ marginTop: '16px' }}>
//         <button
//           onClick={handleToggleView}
//           style={{
//             ...styles.button,
//             ...(viewAll ? styles.buttonHover : {}),
//           }}
//         >
//           {viewAll ? 'Show Top 10' : 'Show All'}
//         </button>
//       </Box>
//     </Box>
//   );
// };



// import React, { useState } from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import Box from '@mui/material/Box';
// import Sparkline from './Sparkline';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import FilterListIcon from '@mui/icons-material/FilterList';

// const LinkCell = ({ value }) => {
//   return (
//     <a href={value} style={styles.link} target="_blank" rel="noopener noreferrer">
//       {value}
//     </a>
//   );
// };

// const styles = {
//   link: {
//     color: 'inherit',
//     textDecoration: 'none',
//     cursor: 'pointer',
//     fontWeight: '500',
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   button: {
//     padding: '10px 20px',
//     borderRadius: '5px',
//     border: 'none',
//     backgroundColor: '#007bff',
//     color: 'white',
//     cursor: 'pointer',
//     fontSize: '16px',
//     fontWeight: '500',
//     transition: 'background-color 0.3s, transform 0.2s',
//     marginLeft: '16px', // Adjust margin to align with search bar
//     display: 'inline-block',
//     textAlign: 'center',
//     outline: 'none',
//   },
//   buttonHover: {
//     backgroundColor: '#0056b3',
//     transform: 'scale(1.05)',
//   },
//   searchBox: {
//     display: 'flex',
//     alignItems: 'center',
//     marginBottom: '16px',
//   },
//   searchField: {
//     width
//     flexGrow: 1,
//     borderRadius: '20px', // Rounded corners
//     transition: 'box-shadow 0.3s ease-in-out',
//     '&:hover': {
//       boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
//     },
//   },
// };

// export const TrendingPages = ({ trendingPages }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [viewAll, setViewAll] = useState(false);

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleToggleView = () => {
//     setViewAll(!viewAll);
//   };

//   const filteredPages = trendingPages.filter((page) =>
//     page.page.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

//   const columns = [
//     { 
//       field: 'page', 
//       headerName: 'Page', 
//       width: 750,
//       renderCell: (params) => <LinkCell value={params.value} />,
//     },
//     { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
//     { field: 'newClicks', headerName: 'New Clicks', width: 125 },
//     { 
//       field: 'trend',
//       headerName: 'Trend (%)',
//       width: 150,
//       renderCell: (params) => <Sparkline data={params.row.trendData} />,
//     },
//   ];

//   return (
//     <Box>
//       <h2>Top Trending Pages</h2>
//       <Box sx={styles.searchBox}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={searchQuery}
//           onChange={handleSearchChange}
//           sx={styles.searchField}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <IconButton>
//                   <FilterListIcon />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//         <button
//           onClick={handleToggleView}
//           style={{
//             ...styles.button,
//             ...(viewAll ? styles.buttonHover : {}),
//           }}
//         >
//           {viewAll ? 'Show Top 10' : 'Show All'}
//         </button>
//       </Box>
//       <DataGrid
//         rows={displayedPages}
//         columns={columns}
//         pageSize={5}
//         autoHeight
//         disableSelectionOnClick
//         getRowId={(row) => row.page} 
//       />
//     </Box>
//   );
// };

// export const DecliningPages = ({ decliningPages }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [viewAll, setViewAll] = useState(false);

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleToggleView = () => {
//     setViewAll(!viewAll);
//   };

//   const filteredPages = decliningPages.filter((page) =>
//     page.page.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

//   const columns = [
//     { 
//       field: 'page', 
//       headerName: 'Page', 
//       width: 750,
//       renderCell: (params) => <LinkCell value={params.value} />,
//     },
//     { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
//     { field: 'newClicks', headerName: 'New Clicks', width: 125 },
//     { 
//       field: 'trend',
//       headerName: 'Trend (%)',
//       width: 150,
//       renderCell: (params) => <Sparkline data={params.row.trendData} />,
//     },
//   ];

//   return (
//     <Box>
//       <h2>Top Declining Pages</h2>
//       <Box sx={styles.searchBox}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           fullWidth
//           value={searchQuery}
//           onChange={handleSearchChange}
//           sx={styles.searchField}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <IconButton>
//                   <FilterListIcon />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//         />
//         <button
//           onClick={handleToggleView}
//           style={{
//             ...styles.button,
//             ...(viewAll ? styles.buttonHover : {}),
//           }}
//         >
//           {viewAll ? 'Show Top 10' : 'Show All'}
//         </button>
//       </Box>
//       <DataGrid
//         rows={displayedPages}
//         columns={columns}
//         pageSize={5}
//         autoHeight
//         disableSelectionOnClick
//         getRowId={(row) => row.page} 
//       />
//     </Box>
//   );
// };



import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Sparkline from './Sparkline';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';

const LinkCell = ({ value }) => {
  return (
    <a href={value} style={styles.link} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  );
};

const styles = {
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: '500',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  button: {
    padding: '10px 20px',
    borderRadius: '20px', // Match button border-radius with search input
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: '500',
    transition: 'background-color 0.3s, transform 0.2s',
    marginLeft: '16px', // Space between search input and button
    display: 'inline-block',
    textAlign: 'center',
    outline: 'none',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
    transform: 'scale(1.05)',
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  searchField: {
    width: '100%', // Full width of the container
    maxWidth: '400px', // Maximum width to prevent it from being too wide
    borderRadius: '20px', // Rounded corners
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 0 10px rgba(0, 123, 255, 0.5)',
    },
  },
};

export const TrendingPages = ({ trendingPages }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewAll, setViewAll] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleToggleView = () => {
    setViewAll(!viewAll);
  };

  const filteredPages = trendingPages.filter((page) =>
    page.page.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

  const columns = [
    { 
      field: 'page', 
      headerName: 'Page', 
      width: 750,
      renderCell: (params) => <LinkCell value={params.value} />,
    },
    { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
    { field: 'newClicks', headerName: 'New Clicks', width: 125 },
    { 
      field: 'trend',
      headerName: 'Trend (%)',
      width: 150,
      renderCell: (params) => <Sparkline data={params.row.trendData} />,
    },
  ];

  return (
    <Box>
      <h2 className='mt-3'>Top Trending Pages</h2>
      <Box sx={styles.searchBox} className='mt-5'>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          sx={styles.searchField}
        
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <button
          onClick={handleToggleView}
          style={{
            ...styles.button,
            ...(viewAll ? styles.buttonHover : {}),
          }}
        >
          {viewAll ? 'Show Top 10' : 'Show All'}
        </button>
      </Box>
      <DataGrid
        rows={displayedPages}
        columns={columns}
        pageSize={5}
        autoHeight
        disableSelectionOnClick
        getRowId={(row) => row.page} 
      />
    </Box>
  );
};

export const DecliningPages = ({ decliningPages }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewAll, setViewAll] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleToggleView = () => {
    setViewAll(!viewAll);
  };

  const filteredPages = decliningPages.filter((page) =>
    page.page.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedPages = viewAll ? filteredPages : filteredPages.slice(0, 10);

  const columns = [
    { 
      field: 'page', 
      headerName: 'Page', 
      width: 750,
      renderCell: (params) => <LinkCell value={params.value} />,
    },
    { field: 'oldClicks', headerName: 'Old Clicks', width: 125 },
    { field: 'newClicks', headerName: 'New Clicks', width: 125 },
    { 
      field: 'trend',
      headerName: 'Trend (%)',
      width: 150,
      renderCell: (params) => <Sparkline data={params.row.trendData} />,
    },
  ];

  return (
    <Box>
      <h2 className='mt-3'>Top Declining Pages</h2>
      <Box sx={styles.searchBox} className='mt-5'>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          sx={styles.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <button
          onClick={handleToggleView}
          style={{
            ...styles.button,
            ...(viewAll ? styles.buttonHover : {}),
          }}
        >
          {viewAll ? 'Show Top 10' : 'Show All'}
        </button>
      </Box>
      <DataGrid
        rows={displayedPages}
        columns={columns}
        pageSize={5}
        autoHeight
        disableSelectionOnClick
        getRowId={(row) => row.page} 
      />
    </Box>
  );
};
