import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDomain } from '../context/DomainContext'; 
import NotFoundPage from '../Notfound';

const SitemapsPage = () => {
  const { selectedLink } = useDomain(); 
  const [sitemaps, setSitemaps] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedLink) {
      fetchSitemaps(selectedLink);
    }
  }, [selectedLink]);
  
  const fetchSitemaps = async (siteUrl) => {
    if (!siteUrl) {
      setError('No site URL selected');
      return;
    }

    try {
      const response = await axios.get('http://localhost:8080/api/sitemap', { params: { siteUrl } });

      // Check if response data is an array
      if (Array.isArray(response.data)) {
        setSitemaps(response.data);
        setError('');
      } else {
        setError('Unexpected data format received');
      }
    } catch (error) {
      console.error('Error fetching sitemaps:', error);
      setError('Error fetching sitemaps');
    }
  };

  return (
    <div>
      <NotFoundPage/>
      {/* <h1>Sitemaps</h1>
      {error && <p>{error}</p>}
      <ul>
        {sitemaps.length > 0 ? (
          sitemaps.map((sitemap, index) => (
            <li key={index}>{sitemap}</li>
          ))
        ) : (
          <p>No sitemaps found</p>
        )}
      </ul> */}
    </div>
  );
};

export default SitemapsPage;
