import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from 'react-google-charts';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'; 
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import '../App.css';
import styled from 'styled-components';
import { Divider } from '@mui/material';
import { Menu } from '@mui/material';
import { useDomain } from './context/DomainContext'; 
import CustomTabs from './CustomTabs';
import { Tabs, Tab,  Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import {
  QueryModal,
  PageModal,
  CountryModal,
  DeviceModal,
  SearchAppearanceModal,
} from './Model';
import AddIcon from '@mui/icons-material/Add';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StatsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const StatBox = styled.div`
  background: ${props => props.bgColor || '#fff'};
  color: #fff;
  padding: 20px;
  // margin-right: 10px;
  // border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  height:120px;
  text-align: center;
`;

const StatTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;

const StatValue = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const SiteData = () => {
  const { selectedLink } = useDomain(); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState('Last 3 months');
  const [chartData, setChartData] = useState([]);
  const [totalClicks, setTotalClicks] = useState(0);
  const [totalImpressions, setTotalImpressions] = useState(0);
  const [averageCTR, setAverageCTR] = useState(0);
  const [averagePosition, setAveragePosition] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [openPageModal, setOpenPageModal] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [openSearchAppearanceModal, setOpenSearchAppearanceModal] = useState(false);
  const [clicksData, setClicksData] = useState([]);
  const [impressionsData, setImpressionsData] = useState([]);
  const [ctrData, setCTRData] = useState([]);
  const [positionData, setPositionData] = useState([]);


  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };



  const handleClick = (event) => {
    // console.log("Button clicked");  // Debug: Button click event
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    // console.log("Menu closed");  // Debug: Menu close event
    setAnchorEl(null);
  };

  const handleOpenModal = (modal) => {
    const trimmedModal = modal.trim(); // Trim the string to remove any leading/trailing spaces

    // console.log(`Opening modal: ${trimmedModal}`);  // Debug: Opening specific modal
    
    // Reset all modals before opening the selected one
    setOpenQueryModal(false);
    setOpenPageModal(false);
    setOpenCountryModal(false);
    setOpenDeviceModal(false);
    setOpenSearchAppearanceModal(false);

    // Open the specific modal based on the modal parameter
    switch (trimmedModal) {
      case 'Query..': // Matching exactly with the menu item
        setOpenQueryModal(true);
        // console.log('Query modal opened'); // Debug: Query modal opened
        break;
      case 'Page..': // Matching exactly with the menu item
        setOpenPageModal(true);
        // console.log('Page modal opened'); // Debug: Page modal opened
        break;
      case 'Country..': // Matching exactly with the menu item
        setOpenCountryModal(true);
        // console.log('Country modal opened'); // Debug: Country modal opened
        break;
      case 'Device..': // Matching exactly with the menu item
        setOpenDeviceModal(true);
        // console.log('Device modal opened'); // Debug: Device modal opened
        break;
      case 'Search appearance..': // Matching exactly with the menu item
        setOpenSearchAppearanceModal(true);
        // console.log('Search appearance modal opened'); // Debug: Search appearance modal opened
        break;
      default:
        // console.log('No matching modal'); // Debug: No matching modal
        break;
    }
    handleCloseMenu();
  };

  

  useEffect(() => {
    // Set default date range to the last 3 months
    updateDateRange('Last 3 months');
  }, []);

 

  useEffect(() => {
    if (selectedLink && startDate && endDate) {
      fetchData(selectedLink, startDate, endDate);
    }
  }, [selectedLink, startDate, endDate]);




  const fetchData = async (siteUrl, startDate, endDate) => {
    try {
      const response = await axios.get('https://brank.supportplus.app/v1/api/searchconsole', {
        params: { siteUrl, startDate, endDate },
      });
  
      if (Array.isArray(response.data.rows)) {
        const dates = response.data.rows.map(row => row.keys[0]);
        const clicks = response.data.rows.map(row => row.clicks);
        const impressions = response.data.rows.map(row => row.impressions);
        const ctr = response.data.rows.map(row => (row.ctr * 100).toFixed(2));
        const position = response.data.rows.map(row => row.position.toFixed(1));
  
        const totalClicks = clicks.reduce((a, b) => a + b, 0);
        const totalImpressions = impressions.reduce((a, b) => a + b, 0);
        const averageCTR = ((clicks.reduce((a, b) => a + b, 0) / impressions.reduce((a, b) => a + b, 0)) * 100).toFixed(2);
        const averagePosition = (position.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) / position.length).toFixed(1);
  
        setTotalClicks(totalClicks);
        setTotalImpressions(totalImpressions);
        setAverageCTR(averageCTR);
        setAveragePosition(averagePosition);
  
        const formattedClicksData = [
          ['Date', 'Clicks'],
          ...dates.map((date, index) => [date, clicks[index]]),
        ];
  
        const formattedImpressionsData = [
          ['Date', 'Impressions'],
          ...dates.map((date, index) => [date, impressions[index]]),
        ];
  
        const formattedCTRData = [
          ['Date', 'Average CTR (%)'],
          ...dates.map((date, index) => [date, parseFloat(ctr[index])]),
        ];
  
        const formattedPositionData = [
          ['Date', 'Average Position'],
          ...dates.map((date, index) => [date, parseFloat(position[index])]),
        ];
  
        setClicksData(formattedClicksData);
        setImpressionsData(formattedImpressionsData);
        setCTRData(formattedCTRData);
        setPositionData(formattedPositionData);
      } else {
        console.error('Invalid data format received from backend');
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };
  
  
  const handleStatBoxClick = (index) => {
    setTabIndex(index);
  };

  const updateDateRange = (range) => {
    const end = new Date().toISOString().split('T')[0];
    let start;

    switch (range) {
      case 'Last 7 days':
        start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Last 28 days':
        start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
        break;
      case 'Last 12 months':
        start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
        break;
      case 'Last 16 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
        break;
      default:
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
    }

    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };


  const formatNumber = (number) => {
    if (number >= 1_000_000) {
      return `${(number / 1_000_000).toFixed(1)}M`; // Format as millions
    } else if (number >= 1_000) {
      return `${(number / 1_000).toFixed(1)}K`; // Format as thousands
    } else {
      return number.toString(); // No formatting needed for smaller numbers
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  

  return (
    <>

<div className='sticky-top'>
  {/* Fixed Header Section */}
  <Box  
    sx={{ 
      position: 'fixed',
      top: '59px', 
      left: '300px', 
      right: '0px',
      zIndex: 1000, 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      padding: '10px 20px', 
      backgroundColor: '#f0f3f8',
    }}
  >
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemText
          primary="Performance on Search results"
          primaryTypographyProps={{
            sx: {
              color: 'rgb(31, 31, 31)',
              fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
              fontWeight: 500,
              fontSize: '20px',
              lineHeight: '20px',
            },
          }}
        />
      </ListItemButton>
    </ListItem>
    {/* <Button
      variant="outlined"
      startIcon={<DownloadIcon />}
      sx={{
        color: 'rgb(31, 31, 31)',
        borderColor: 'rgb(31, 31, 31)',
        textTransform: 'none',
      }}
    >
      Export
    </Button> */}
  </Box>

  <Divider sx={{ marginTop: '95px', my: 1 }} /> {/* Adjusted margin for visibility */}

  {/* Fixed Date Picker and Last Updated Section */}
  <Box sx={{ 
    position: 'fixed',
    top: '120px', 
    left: '300px', 
    right: '0px',
    zIndex: 1000, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between', 
    padding: '10px 20px', 
    backgroundColor: '#f0f3f8', 
    // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
  }}>
    <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
      <Select
        labelId="date-range-label"
        id="date-range-select"
        value={dateRange}
        // label="Date Range"
        onChange={(e) => updateDateRange(e.target.value)}
        sx={{
          backgroundColor: 'rgb(211, 227, 253)',
          borderRadius: '25px',
          '& .MuiSelect-select': {
            padding: '10px',
            borderRadius: '25px',
          },
        }}
      >
        <MenuItem value="Last 7 days">Last 7 days</MenuItem>
        <MenuItem value="Last 28 days">Last 28 days</MenuItem>
        <MenuItem value="Last 3 months">Last 3 months</MenuItem>
        <MenuItem value="Last 6 months">Last 6 months</MenuItem>
        <MenuItem value="Last 12 months">Last 12 months</MenuItem>
        <MenuItem value="Last 16 months">Last 16 months</MenuItem>
        <MenuItem value="Custom">Custom</MenuItem>
      </Select>
    </FormControl>
    {dateRange === 'Custom' && (
      <>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
          sx={{ minWidth: 250 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          required
          sx={{ minWidth: 250, ms: 4 }}
        />
      </>
    )}




<div>
      {/* <Button
        startIcon={<AddIcon fontSize='large' />}
        sx={{
          color: 'rgb(31, 31, 31)',
          textTransform: 'none',
          borderRadius: '50px',
          backgroundColor: '#cfd8dd',
          width: 100,
        }}
        onClick={handleClick}
      >
        New
      </Button> */}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        {['Query..', 'Page..', 'Country..', 'Device..', 'Search appearance..'].map((item) => (
          <MenuItem key={item} onClick={() => handleOpenModal(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>

         <QueryModal
        open={openQueryModal}
        onClose={() => setOpenQueryModal(false)}
        dropdownOptions={['Queries containing', 'Queries not containing', 'Exact query', 'Custom (regex)']}
      />
      <PageModal
        open={openPageModal}
        onClose={() => setOpenPageModal(false)}
        dropdownOptions={['URLs containing', 'URLs not containing', 'Exact URL', 'Custom (regex)']}
      />
      <CountryModal
        open={openCountryModal}
        onClose={() => setOpenCountryModal(false)}
        dropdownOptions={[
          'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 
          'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 
          'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 
          'Burkina Faso', 'Burundi', 'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 
          'Chile', 'China', 'Colombia', 'Comoros', 'Congo, Democratic Republic of the', 'Congo, Republic of the', 
          'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 
          'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 
          'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 
          'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 
          'Ireland', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 
          'Korea, North', 'Korea, South', 'Kosovo', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 
          'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 
          'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 
          'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 
          'Nigeria', 'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 
          'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 
          'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 
          'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 
          'South Africa', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 
          'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 
          'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 
          'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
        ]}
      />
      <DeviceModal
        open={openDeviceModal}
        onClose={() => setOpenDeviceModal(false)}
        dropdownOptions={['Desktop', 'Mobile', 'Tablet']}
      />
      <SearchAppearanceModal
        open={openSearchAppearanceModal}
        onClose={() => setOpenSearchAppearanceModal(false)}
        dropdownOptions={['Review snippets', 'Product snippets']}
      />
    </div>
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
      <ListItem disablePadding>
        <ListItemText
          primary="Last updated: 3 hours ago"
          primaryTypographyProps={{
            sx: {
              color: 'grey',
              fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '20px',
            },
          }}
        />
        <Tooltip title="All dates recorded and displayed in Pacific Time Zone (PT)">
          <IconButton>
            <HelpOutlineIcon sx={{ color: 'grey' }} />
          </IconButton>
        </Tooltip>
      </ListItem>
    </Box>
  </Box>
</div>


 <Tabs value={tabIndex} onChange={handleTabChange} aria-label="chart tabs" centered>
        </Tabs>
     <div className='card mt-4' style={{borderRadius:'50px'}}>


{/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px'  , marginTop:'60px'}}>
      <StatsContainer sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', mb: '20px' }}>
        <StatBox
          bgColor="#4286f5"
          className='curved'
          style={{ borderRadius: '20px 0 0 20px', cursor: 'pointer' }}
          onClick={() => handleStatBoxClick(0)}
        >
          <StatTitle>Total Clicks</StatTitle>
          <StatValue>{formatNumber(totalClicks)}</StatValue>
        </StatBox>
        <StatBox
          bgColor="#5a36b0"
          style={{ cursor: 'pointer' }}
          onClick={() => handleStatBoxClick(1)}
        >
          <StatTitle>Total Impressions</StatTitle>
          <StatValue>{formatNumber(totalImpressions)}</StatValue>
        </StatBox>
        <StatBox
          bgColor="#00887a"
          style={{ cursor: 'pointer' }}
          onClick={() => handleStatBoxClick(2)}
        >
          <StatTitle>Average CTR</StatTitle>
          <StatValue>{averageCTR}%</StatValue>
        </StatBox>
        <StatBox
          bgColor="#e87109"
          className='curved'
          style={{ borderRadius: '0 20px 20px 0', cursor: 'pointer' }}
          onClick={() => handleStatBoxClick(3)}
        >
          <StatTitle>Average Position</StatTitle>
          <StatValue>{averagePosition}</StatValue>
        </StatBox>
      </StatsContainer>

     
       

      <Box sx={{ width: '100%', maxWidth: '1200px' , marginTop:'-15px' }}>
        {tabIndex === 0 && (
          <Chart
            height={'390px'}
            chartType="LineChart"
            loader={<div>Loading Clicks Chart...</div>}
            data={clicksData}
            options={{
              title: 'Clicks Over Time',
              hAxis: { title: 'Date' },
              vAxis: { title: 'Clicks' },
              curveType: 'function',
              legend: { position: 'top', alignment: 'start' },
            }}
          />
        )}
        {tabIndex === 1 && (
          <Chart
            height={'390px'}
            chartType="LineChart"
            loader={<div>Loading Impressions Chart...</div>}
            data={impressionsData}
            options={{
              title: 'Impressions Over Time',
              hAxis: { title: 'Date' },
              vAxis: { title: 'Impressions' },
              curveType: 'function',
              legend: { position: 'top', alignment: 'start' },
              colors: ['#5a36b0'],
            }}
          />
        )}
        {tabIndex === 2 && (
          <Chart
            height={'390px'}
            chartType="LineChart"
            loader={<div>Loading CTR Chart...</div>}
            data={ctrData}
            options={{
              title: 'Average CTR Over Time',
              hAxis: { title: 'Date' },
              vAxis: { title: 'CTR (%)' },
              curveType: 'function',
              legend: { position: 'top', alignment: 'start' },
              colors: ['#00887a']
            }}
          />
        )}
        {tabIndex === 3 && (
          <Chart
            height={'390px'}
            chartType="LineChart"
            loader={<div>Loading Position Chart...</div>}
            data={positionData}
            options={{
              title: 'Average Position Over Time',
              hAxis: { title: 'Date' },
              vAxis: { title: 'Position' },
              curveType: 'function',
              legend: { position: 'top', alignment: 'start' },
              colors: ['#e87109'],
            }}
          />
        )}
         </Box>
      </Box> */}
       <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px' }}>
        <StatsContainer sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', mb: '20px' }}>
          <StatBox
            bgColor="#4286f5"
            className='curved'
            style={{ borderRadius: '20px 0 0 20px', cursor: 'pointer' }}
            onClick={() => handleStatBoxClick(0)}
          >
            <StatTitle>Total Clicks</StatTitle>
            <StatValue>{formatNumber(totalClicks || 0)}</StatValue>
          </StatBox>
          <StatBox
            bgColor="#5a36b0"
            style={{ cursor: 'pointer' }}
            onClick={() => handleStatBoxClick(1)}
          >
            <StatTitle>Total Impressions</StatTitle>
            <StatValue>{formatNumber(totalImpressions || 0)}</StatValue>
          </StatBox>
          <StatBox
            bgColor="#00887a"
            style={{ cursor: 'pointer' }}
            onClick={() => handleStatBoxClick(2)}
          >
            <StatTitle>Average CTR</StatTitle>
            <StatValue>{averageCTR || '0.00'}%</StatValue>
          </StatBox>
          <StatBox
            bgColor="#e87109"
            className='curved'
            style={{ borderRadius: '0 20px 20px 0', cursor: 'pointer' }}
            onClick={() => handleStatBoxClick(3)}
          >
            <StatTitle>Average Position</StatTitle>
            <StatValue>{averagePosition || '0.0'}</StatValue>
          </StatBox>
        </StatsContainer>

        <Box sx={{ width: '100%', maxWidth: '1200px', marginTop: '-15px' }}>
          <AnimatePresence>
            {tabIndex === 0 && (
              <motion.div
                key="clicks"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Chart
                  height={'390px'}
                  chartType="LineChart"
                  loader={<div>Loading Clicks Chart...</div>}
                  data={clicksData || []}
                  options={{
                    title: 'Clicks Over Time',
                    hAxis: { title: 'Date' },
                    vAxis: { title: 'Clicks' },
                    curveType: 'function',
                    legend: { position: 'top', alignment: 'start' },
                  }}
                />
              </motion.div>
            )}
            {tabIndex === 1 && (
              <motion.div
                key="impressions"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Chart
                  height={'390px'}
                  chartType="LineChart"
                  loader={<div>Loading Impressions Chart...</div>}
                  data={impressionsData || []}
                  options={{
                    title: 'Impressions Over Time',
                    hAxis: { title: 'Date' },
                    vAxis: { title: 'Impressions' },
                    curveType: 'function',
                    legend: { position: 'top', alignment: 'start' },
                    colors: ['#5a36b0'],
                  }}
                />
              </motion.div>
            )}
            {tabIndex === 2 && (
              <motion.div
                key="ctr"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Chart
                  height={'390px'}
                  chartType="LineChart"
                  loader={<div>Loading CTR Chart...</div>}
                  data={ctrData || []}
                  options={{
                    title: 'Average CTR Over Time',
                    hAxis: { title: 'Date' },
                    vAxis: { title: 'CTR (%)' },
                    curveType: 'function',
                    legend: { position: 'top', alignment: 'start' },
                    colors: ['#00887a']
                  }}
                />
              </motion.div>
            )}
            {tabIndex === 3 && (
              <motion.div
                key="position"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <Chart
                  height={'390px'}
                  chartType="LineChart"
                  loader={<div>Loading Position Chart...</div>}
                  data={positionData || []}
                  options={{
                    title: 'Average Position Over Time',
                    hAxis: { title: 'Date' },
                    vAxis: { title: 'Position' },
                    curveType: 'function',
                    legend: { position: 'top', alignment: 'start' },
                    colors: ['#e87109'],
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Box>
      </div>


{/* Custom Tabs Section */}
<div className='mt-5 py-5'>
  <CustomTabs />
</div>


    </>
  );
};

export default SiteData;
