// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import FormControl from '@mui/material/FormControl';
// import {
//   Container,
//   Box,
//   Button,
//   Typography,
//   CircularProgress,
//   Card,
//   CardContent,
//   CardHeader,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   List,
//   ListItem,
//   ListItemText,
//   MenuItem,
//   Select
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
// import PropertyDropdown from './PropertyDropdown';
// import PropertyContext from './context/PropertyContext';
// import FunnelDataList from './Funnelactivity';
// import CoreReporting from './Corereporting';

// const AnalyticsDataList = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [reportType, setReportType] = useState('multiple-dimensions');
//   const [isRealtime, setIsRealtime] = useState(true);

//   const { propertyId } = useContext(PropertyContext);

//   useEffect(() => {
//     if (isRealtime && propertyId) {
//       fetchRealtimeData();
//     }
//   }, [isRealtime, reportType, propertyId]);

//   const fetchRealtimeData = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const endpoint =
//         reportType === 'multiple-dimensions'
//           ? 'http://localhost:8080/analytics/v2/api/realtime/multiple-dimensions'
//           : 'http://localhost:8080/analytics/v2/api/realtime/multiple-metrics';

//       const response = await axios.get(endpoint, {
//         params: { propertyId },
//       });
//       console.log('API Response:', response.data);
//       aggregateData(response.data.rows || []);
//     } catch (err) {
//       console.error('API Error:', err);
//       setError('Failed to fetch data. Please check the console for more details.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const aggregateData = (rows) => {
//     const countryData = {};

//     rows.forEach((row) => {
//       const country = row.dimensionValues[0]?.value || 'Unknown';
//       const city = row.dimensionValues[1]?.value || null;
//       const users = parseInt(row.metricValues[0]?.value || '0', 10);

//       if (!countryData[country]) {
//         countryData[country] = { users: 0, cities: {} };
//       }

//       countryData[country].users += users;

//       if (city) {
//         if (countryData[country].cities[city]) {
//           countryData[country].cities[city] += users;
//         } else {
//           countryData[country].cities[city] = users;
//         }
//       }
//     });

//     const aggregatedData = Object.keys(countryData).map((country) => ({
//       country,
//       users: countryData[country].users,
//       cities: Object.keys(countryData[country].cities).map((city) => ({
//         city,
//         users: countryData[country].cities[city],
//       })),
//     }));

//     setData(aggregatedData);
//   };

//   if (loading) {
//     return (
//       <Container>
//         <CircularProgress />
//         <Typography variant="h6" className="text-center mt-5 py-5">
//           Loading data...
//         </Typography>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Container>
//         <Typography variant="h6" color="error">
//           Error: {error}
//         </Typography>
//       </Container>
//     );
//   }

//   return (
//     <Container>
//         <Typography variant="h5" gutterBottom className="text-center">
//           Google Analytics Real-Time Data
//         </Typography>
//     <div className='col-3 float-end mt-4'>
      
//         <PropertyDropdown /> 
//           </div>
//       <Box sx={{ marginBottom: 2 , borderRadius:8}}>
//         <FormControl sx={{ minWidth: 200, marginTop: 2 }}>
//           <Select
//             labelId="report-type-label"
//             id="report-type-select"
//             value={reportType}
//             onChange={(e) => setReportType(e.target.value)}
//           >
//             <MenuItem value="multiple-dimensions">Multiple Dimensions</MenuItem>
//             <MenuItem value="multiple-metrics">Multiple Metrics</MenuItem>
//           </Select>
//         </FormControl>
//       </Box>
      
//       <Box>
//         <Card sx={{ marginBottom: 4 }} style={{ borderRadius: '10px' }}>
//           <CardHeader title="Users in Last 30 Minutes" />
//           <CardContent>
//             <Typography variant="h3" component="div">
//               {data.reduce((acc, item) => acc + item.users, 0)}
//             </Typography>
//             <Typography variant="body2">Total Users</Typography>
//           </CardContent>
//         </Card>
//         <Card style={{ borderRadius: '10px' }}>
//           <CardHeader title="Users Per Minute" />
//           <CardContent>
//             <BarChart width={950} height={250} data={data}>
//               <XAxis dataKey="country" />
//               <YAxis />
//               <Tooltip />
//               <Bar dataKey="users" fill="#8884d8" />
//             </BarChart>
//           </CardContent>
//         </Card>
//       </Box>
//       <Box sx={{ marginTop: 4 }}>
//         <Card style={{ borderRadius: '10px' }}>
//           <CardHeader title="Top Countries by Active Users" />
//           <CardContent sx={{ maxHeight: 300, overflowY: 'auto' }}>
//             <List>
//               {data.map((item, index) => (
//                 <Accordion key={index}>
//                   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                     <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//                       <Typography>{item.country}</Typography>
//                       <Typography>{item.users}</Typography>
//                     </Box>
//                   </AccordionSummary>
//                   {item.cities?.length > 0 && (
//                     <AccordionDetails>
//                       <List>
//                         {item.cities.map((cityItem, cityIndex) => (
//                           <ListItem key={cityIndex}>
//                             <ListItemText
//                               primary={
//                                 <Box
//                                   sx={{
//                                     display: 'flex',
//                                     justifyContent: 'space-between',
//                                     width: '100%',
//                                   }}
//                                 >
//                                   <span>{cityItem.city}</span>
//                                   <span>{cityItem.users}</span>
//                                 </Box>
//                               }
//                             />
//                           </ListItem>
//                         ))}
//                       </List>
//                     </AccordionDetails>
//                   )}
//                 </Accordion>
//               ))}
//             </List>
//           </CardContent>
//         </Card>
//       </Box>
//       <div>
//         {/* <FunnelDataList/> */}
//       </div>
//       <div>
//         <CoreReporting/>
//       </div>
//     </Container>
//   );
// };

// export default AnalyticsDataList;



import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import FormControl from '@mui/material/FormControl';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import PropertyDropdown from './PropertyDropdown';
import PropertyContext from './context/PropertyContext';
import FunnelDataList from './Funnelactivity';
import CoreReporting from './Corereporting';

const AnalyticsDataList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [reportType, setReportType] = useState('multiple-dimensions');
  const [isRealtime, setIsRealtime] = useState(true);

  const { propertyId } = useContext(PropertyContext);

  useEffect(() => {
    if (isRealtime && propertyId) {
      fetchRealtimeData();
    }
  }, [isRealtime, reportType, propertyId]);

  const fetchRealtimeData = async () => {
    setLoading(true);
    setError(null);
    try {
      const endpoint =
        reportType === 'multiple-dimensions'
          ? 'https://brank.supportplus.app/analytics/v2/api/realtime/multiple-dimensions'
          : 'https://brank.supportplus.app/analytics/v2/api/realtime/multiple-metrics';
          // ? 'http://localhost:8080/analytics/v2/api/realtime/multiple-dimensions'
          // : 'http://localhost:8080/analytics/v2/api/realtime/multiple-metrics';

      const response = await axios.get(endpoint, {
        params: { propertyId },
      });
      // console.log('API Response:', response.data);
      aggregateData(response.data.rows || []);
    } catch (err) {
      console.error('API Error:', err);
      setError('Failed to fetch data. Please check the console for more details.');
    } finally {
      setLoading(false);
    }
  };

  const aggregateData = (rows) => {
    const countryData = {};

    rows.forEach((row) => {
      const country = row.dimensionValues[0]?.value || 'Unknown';
      const city = row.dimensionValues[1]?.value || null;
      const users = parseInt(row.metricValues[0]?.value || '0', 10);

      if (!countryData[country]) {
        countryData[country] = { users: 0, cities: {} };
      }

      countryData[country].users += users;

      if (city) {
        if (countryData[country].cities[city]) {
          countryData[country].cities[city] += users;
        } else {
          countryData[country].cities[city] = users;
        }
      }
    });

    const aggregatedData = Object.keys(countryData).map((country) => ({
      country,
      users: countryData[country].users,
      cities: Object.keys(countryData[country].cities).map((city) => ({
        city,
        users: countryData[country].cities[city],
      })),
    }));

    setData(aggregatedData);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h5" gutterBottom className="text-center">
        Google Analytics Real-Time Data
      </Typography>
   <div className='col-3 '>

        <PropertyDropdown />
   </div>
     
      <Box sx={{ marginBottom: 2, borderRadius: 8, mt: 6 }}>
        <FormControl sx={{ minWidth: 200, marginTop: 2 }}>
          <Select
            labelId="report-type-label"
            id="report-type-select"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
          >
            <MenuItem value="multiple-dimensions">Multiple Dimensions</MenuItem>
            <MenuItem value="multiple-metrics">Multiple Metrics</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Card sx={{ marginBottom: 4 }} style={{ borderRadius: '10px' }}>
          <CardHeader title="Users in Last 30 Minutes" />
          <CardContent>
            <Typography variant="h3" component="div">
              {data.reduce((acc, item) => acc + item.users, 0)}
            </Typography>
            <Typography variant="body2">Total Users</Typography>
          </CardContent>
        </Card>
        <Card style={{ borderRadius: '10px' }}>
          <CardHeader title="Users Per Minute" />
          <CardContent>
            <BarChart width={950} height={250} data={data}>
              <XAxis dataKey="country" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="users" fill="#8884d8" />
            </BarChart>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ marginTop: 4 }}>
        <Card style={{ borderRadius: '10px' }}>
          <CardHeader title="Top Countries by Active Users" />
          <CardContent sx={{ maxHeight: 300, overflowY: 'auto' }}>
            <List>
              {data.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography>{item.country}</Typography>
                      <Typography>{item.users}</Typography>
                    </Box>
                  </AccordionSummary>
                  {item.cities?.length > 0 && (
                    <AccordionDetails>
                      <List>
                        {item.cities.map((cityItem, cityIndex) => (
                          <ListItem key={cityIndex}>
                            <ListItemText
                              primary={
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <span>{cityItem.city}</span>
                                  <span>{cityItem.users}</span>
                                </Box>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  )}
                </Accordion>
              ))}
            </List>
          </CardContent>
        </Card>
      <Box mt={5}>
      {/* <Typography variant="h5" gutterBottom className="text-center">
        Google Analytics Core Reporting
      </Typography> */}
      {/* <Card style={{ borderRadius: '10px'  }}>
      <CardContent sx={{ maxHeight: 500, overflowY: 'auto' }}>
          <CoreReporting />
          </CardContent>
        </Card> */}
        </Box>
      <Box>
      </Box>
        {/* Uncomment these components if needed */}
        {/* <FunnelDataList /> */}
      </Box>
   
    </Container>
  );
};

export default AnalyticsDataList;
