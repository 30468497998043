// import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import PropTypes from 'prop-types';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Box from '@mui/material/Box';

// const CustomDataGrid = ({ rows, columns }) => {
//   return (
//     // <Card sx={{ borderRadius: '16px', boxShadow: 3 }}>
//     //   <CardContent>
//         <Box sx={{ height: 500, width: '100%' }}>
//           <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
//         </Box>
//     //   </CardContent>
//     // </Card>
//   );
// };

// CustomDataGrid.propTypes = {
//   rows: PropTypes.array.isRequired,
//   columns: PropTypes.array.isRequired,
// };

// export default CustomDataGrid;



import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
// import './CustomTabs.css';

const CustomDataGrid = ({ rows, columns }) => {
  return (
<Card sx={{ borderRadius: '16px', boxShadow: ''}}>
    <Box sx={{ height: 500, width: '100%', '& .MuiDataGrid-root': { borderRadius: '8px', } }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        sx={{
          '& .MuiDataGrid-cell': {
            fontSize: '14px',
            borderBottom: '1px solid #ddd',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            // borderBottom: '2px solid #ddd',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#f5f5f5',
            // borderTop: '2px solid #ddd',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#e0e0e0',
          },
        }}
      />
    </Box>
    </Card>
  );
};

CustomDataGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

export default CustomDataGrid;
