  import React from 'react';
  import {
    Box,
    Modal,
    Tabs,
    Tab,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    InputLabel,
    Divider,
    Button
  } from '@mui/material';
  import './model.css'
  import { styled } from '@mui/material/styles';



  // Styled component for Tabs
  const CustomTabs = styled(Tabs)(({ theme }) => ({
      '& .MuiTab-root': {
        color: 'black', // Default tab color
        fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
      },
      '& .MuiTab-root.Mui-selected': {
        color: 'blue', // Color of the selected tab
      },
      '& .MuiTabs-indicator': {
          width:'10px',
        backgroundColor: 'blue', // Color of the underline

      },
    }));

    const CustomFormControl = styled(FormControl)(({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        borderRadius: '14px', // Apply border-radius
        '& fieldset': {
            borderColor: 'black', // Default border color

            height:'55px'
        },
        '&:hover fieldset': {
          borderColor: 'blue', // Blue border on hover
          borderWidth: '2px', // Increase border width on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'blue', // Blue border when focused
          borderWidth: '2px', // Increase border width when focused
        },
      },
    }));
    


    // Styled component for TextField
    const CustomTextField = styled(TextField)(({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'black', // Default border color
        },
        '&:hover fieldset': {
          borderColor: 'blue', // Blue border on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'blue', // Blue border when focused
        },
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.text.primary,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)', // Label moves up when field is focused or has text
      },
    }));

    const ModalHeader = styled(Typography)(({ theme }) => ({
      marginBottom: theme.spacing(),
      fontWeight: 500,
      fontSize: '1.25rem',
      color:'black',
      marginTop:-20,
    }));

    const CustomButton = styled(Button)(({ theme }) => ({
      marginLeft: theme.spacing(1),
    }));


    const ApplyButton = styled(Button)(({ theme }) => ({
      backgroundColor: 'blue', // Blue background
      color: 'white', // White text
      borderRadius: '50px', // Pill shape
      textTransform: 'none', // Preserve the text case
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'darkblue', // Darker blue on hover
      },
    }));
    
    // Styled component for the Cancel button
    const CancelButton = styled(Button)(({ theme }) => ({
      color: 'blue', // Blue text
      backgroundColor: 'transparent', // No background
      borderRadius: '50px', // Pill shape
      textTransform: 'none', // Preserve the text case
      marginLeft: theme.spacing(1),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 255, 0.1)', // Light blue background on hover
      },
    }));
  export function QueryModal({ open, onClose, dropdownOptions  }) {
    const [activeTab, setActiveTab] = React.useState(0);
    const [inputValue, setInputValue] = React.useState('');
    const [dropdownValue, setDropdownValue] = React.useState('');

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleDropdownChange = (event) => {
      setDropdownValue(event.target.value);
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,

          }}
          className='box'
        >
          <ModalHeader>
            {activeTab === 0 ? 'Query' : 'Compare'}
          </ModalHeader>
          <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Filter" />
            <Tab label="Compare" />
          </CustomTabs>
          <Divider/>
          {activeTab === 0 && (
              <>
              
            
            <Box 
            sx={{ mt: 2  ,
            width:450,
            }}>
            <CustomFormControl>
        <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === '') {
              return <em>Select Query</em>;
            }
            return selected;
          }}
        >
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
              </Box>
              <>
              
              
        
              <Box sx={{ mt: 2 }}>
              <CustomTextField
          label="Keyword"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
        />
            </Box>
            </>
            </>
          )}
          {activeTab === 1 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Compare by Query</Typography>
              {/* Add comparison logic here */}
            </Box>
          )}
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </CancelButton>
          <ApplyButton variant="contained" onClick={() => console.log('')}>
            Apply
          </ApplyButton>
        </Box>
        </Box>
      
      </Modal>
    );
  }

  // Similar modals for Page, Country, Device, Search appearance
  export function PageModal({ open, onClose, dropdownOptions }) {
    const [activeTab, setActiveTab] = React.useState(0);
    const [inputValue, setInputValue] = React.useState('');
    const [dropdownValue, setDropdownValue] = React.useState('');

    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };

    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };

    const handleDropdownChange = (event) => {
      setDropdownValue(event.target.value);
    };

    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          //   width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
          className='box'
        >
            <ModalHeader>
            {activeTab === 0 ? 'Page' : 'Compare'}
          </ModalHeader>
          <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Filter" />
            <Tab label="Compare" />
          </CustomTabs>
          <Divider/>
          {activeTab === 0 && (
            <Box sx={{ mt: 2 }}>
            <CustomFormControl>

        
                {/* <InputLabel>Select Page</InputLabel> */}
                <Select
          value={dropdownValue}
          onChange={handleDropdownChange}
          displayEmpty
          renderValue={(selected) => {
            if (selected === '') {
              return <em>Select URLs</em>;
            }
            return selected;
          }}
        >
          {dropdownOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
                </CustomFormControl>
              <CustomTextField
                label="http://www.example.com/"
                value={inputValue}
                onChange={handleInputChange}
                fullWidth
                sx={{ mt: 2 }}
              />
            </Box>
          )}
          {activeTab === 1 && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Compare by Page</Typography>
              {/* Add comparison logic here */}
            </Box>
          )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </CancelButton>
          <ApplyButton variant="contained" onClick={() => console.log('')}>
            Apply
          </ApplyButton>
        </Box>
        </Box>
      </Modal>
    );
  }



  // export function CountryModal({ open, onClose, dropdownOptions }) {
  //     const [activeTab, setActiveTab] = React.useState(0);
  //     const [selectedCountry, setSelectedCountry] = React.useState('');
    
  //     const handleTabChange = (event, newValue) => {
  //       setActiveTab(newValue);
  //     };
    
  //     const handleCountryChange = (event) => {
  //       setSelectedCountry(event.target.value);
  //     };
    
  //     return (
  //       <Modal open={open} onClose={onClose}>
  //         <Box
  //           sx={{
  //             position: 'absolute',
  //             top: '50%',
  //             left: '50%',
  //             transform: 'translate(-50%, -50%)',
  //             // width: 400,
  //             bgcolor: 'background.paper',
  //             boxShadow: 24,
  //             height:600,
  //             p: 4,
  //           }}
  //           className='box'
  //         >
  //           <Tabs value={activeTab} onChange={handleTabChange}
  //           sx={{
  //             '& .MuiTab-root': {
  //               color: 'black', // Default color for tabs
  //               fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
  //             },
  //             '& .Mui-selected': {
  //               color: 'blue', // Color for active tab
  //             },
  //           }}>
  //             <Tab label="Filter" />
  //             <Tab label="Compare" />
  //           </Tabs>
  //           {activeTab === 0 && (
  //             <Box sx={{ mt: 2 }}>
  //               <FormControl component="fieldset">
  //                 <FormLabel component="legend">Select Country</FormLabel>
  //                 <RadioGroup value={selectedCountry} onChange={handleCountryChange}>
  //                   {dropdownOptions.map((option, index) => (
  //                     <FormControlLabel
  //                       key={index}
  //                       value={option}
  //                       control={<Radio />}
  //                       label={option}
  //                     />
  //                   ))}
  //                 </RadioGroup>
  //               </FormControl>
  //             </Box>
  //           )}
  //           {activeTab === 1 && (
  //             <Box sx={{ mt: 2 }}>
  //               <Typography variant="subtitle1">Compare by Country</Typography>
  //               {/* Add comparison logic here */}
  //             </Box>
  //           )}
            
  //         </Box>
  //       </Modal>
  //     );
  //   }


  export function CountryModal({ open, onClose, dropdownOptions }) {
    const [activeTab, setActiveTab] = React.useState(0);
    const [selectedCountry, setSelectedCountry] = React.useState('');
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
  
    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
    };
  
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            height: 600,
            width: 550,
            borderRadius:5,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ModalHeader>
            {activeTab === 0 ? 'Country' : 'Compare'}
          </ModalHeader>
          
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root': {
                color: 'black', // Default color for tabs
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
              },
              '& .Mui-selected': {
                color: 'blue', // Color for active tab
              },
            }}
          >
            <Tab label="Filter" />
            <Tab label="Compare" />
          </Tabs>
          <Divider sx={{ my: 2 }} />
  
          {/* Scrollable Content Area */}
          <Box 
            sx={{ 
              flex: 1,
              overflowY: 'auto', 
              maxHeight: 'calc(100% - 64px)', // Adjust height based on button height and padding
            }}
          >
            
            {activeTab === 0 && (
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Country</FormLabel>
                <RadioGroup value={selectedCountry} onChange={handleCountryChange}>
                  {dropdownOptions.map((option, index) => (
                   <FormControlLabel
                   key={index}
                   value={option}
                   control={<Radio 
                     sx={{
                       '&.Mui-checked': {
                         color: 'blue', // Change color when selected
                       },
                     }} 
                   />}
                   label={option}
                 />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
  
            {activeTab === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Compare by Country</Typography>
                {/* Add comparison logic here */}
              </Box>
            )}
            <Divider sx={{ my: 2 }} />
          </Box>
  
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              mt: 2,
              gap: 1,
            }}
          >
            <CancelButton variant="outlined" onClick={onClose}>
              Cancel
            </CancelButton>
            <ApplyButton variant="contained" onClick={() => console.log('')}>
              Apply
            </ApplyButton>
          </Box>
        </Box>
      </Modal>
    );
  }


  export function DeviceModal({ open, onClose, dropdownOptions }) {
      const [activeTab, setActiveTab] = React.useState(0);
      const [selectedCountry, setSelectedCountry] = React.useState('');
    
      const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
      };
    
      const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
      };
    
      return (
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
            className='box'
          >
              <ModalHeader>
            {activeTab === 0 ? 'Device' : 'Compare'}
          </ModalHeader>
         <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Filter" />
            <Tab label="Compare" />
          </CustomTabs>
          <Divider/>
            {activeTab === 0 && (
              <Box sx={{ mt: 2 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Country</FormLabel>
                  <RadioGroup value={selectedCountry} onChange={handleCountryChange}>
                    {dropdownOptions.map((option, index) => (
                      <FormControlLabel
                      key={index}
                      value={option}
                      control={<Radio 
                        sx={{
                          '&.Mui-checked': {
                            color: 'blue', // Change color when selected
                          },
                        }} 
                      />}
                      label={option}
                    />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            {activeTab === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Compare by Country</Typography>
                {/* Add comparison logic here */}
              </Box>
            )}
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </CancelButton>
          <ApplyButton variant="contained" onClick={() => console.log('')}>
            Apply
          </ApplyButton>
        </Box>
          </Box>
        </Modal>
      );
    }

  export function SearchAppearanceModal({ open, onClose, dropdownOptions }) {
      const [activeTab, setActiveTab] = React.useState(0);
      const [selectedCountry, setSelectedCountry] = React.useState('');
    
      const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
      };
    
      const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
      };
    
      return (
        <Modal open={open} onClose={onClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              // width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
            className='box'
          >
              <ModalHeader>
            {activeTab === 0 ? 'Search appearance' : 'Compare'}
          </ModalHeader>
           <CustomTabs
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Filter" />
            <Tab label="Compare" />
          </CustomTabs>
          <Divider/>
            {activeTab === 0 && (
              <Box sx={{ mt: 2 }}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Country</FormLabel>
                  <RadioGroup value={selectedCountry} onChange={handleCountryChange}>
                    {dropdownOptions.map((option, index) => (
                       <FormControlLabel
                       key={index}
                       value={option}
                       control={<Radio 
                         sx={{
                           '&.Mui-checked': {
                             color: 'blue', // Change color when selected
                           },
                         }} 
                       />}
                       label={option}
                     />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
            {activeTab === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Compare by Country</Typography>
                {/* Add comparison logic here */}
              </Box>
            )}
             <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <CancelButton variant="outlined" onClick={onClose}>
            Cancel
          </CancelButton>
          <ApplyButton variant="contained" onClick={() => console.log('')}>
            Apply
          </ApplyButton>
        </Box>
          </Box>
        </Modal>
      );
    }


