import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid'; // Correct import for DataGrid
import { useDomain } from './context/DomainContext';
import 'i18n-iso-countries'; // Import the library
import './CustomTabs.css';

// Initialize the library
import countries from 'i18n-iso-countries';
countries.registerLocale(require('i18n-iso-countries/langs/en.json')); // Register locale if needed

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box className="tabs-content">{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function TrendingCountries({ dateRange }) {
  const { selectedLink } = useDomain();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [value, setValue] = useState(0);
  const [trendingCountries, setTrendingCountries] = useState([]);
  const [decliningCountries, setDecliningCountries] = useState([]);

  useEffect(() => {
    if (dateRange) {
      updateDateRange(dateRange);
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedLink && startDate && endDate) {
      fetchCountryData(selectedLink, startDate, endDate);
    }
  }, [selectedLink, startDate, endDate, value]);

  const fetchCountryData = async (link, start, end) => {
    try {
      const response = await axios.get('https://brank.supportplus.app/v1/api/countries', {
        params: { siteUrl: link, startDate: start, endDate: end },
      });

      const fetchedCountryData = response.data.rows || response.data;

      const countryData = fetchedCountryData.map((row, index) => {
        const countryCode = row.keys[0];
        const countryName = countries.getName(countryCode, 'en') || 'Unknown'; // Use library to get country name
        
        return {
          id: index + 1,
          country: countryName,
          clicks: row.clicks || 0,
          impressions: row.impressions || 0,
        };
      });

      const sortedCountries = [...countryData].sort((a, b) => b.clicks - a.clicks);
      setTrendingCountries(sortedCountries.slice(0, 10));

      const sortedDecliningCountries = [...countryData].sort((a, b) => a.clicks - b.clicks);
      setDecliningCountries(sortedDecliningCountries.slice(0, 10));

    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  const updateDateRange = (range) => {
    const end = new Date().toISOString().split('T')[0];
    let start;

    switch (range) {
      case 'Last 7 days':
        start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T')[0];
        break;
      case 'Last 28 days':
        start = new Date(new Date().setDate(new Date().getDate() - 28)).toISOString().split('T')[0];
        break;
      case 'Last 3 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
        break;
      case 'Last 6 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0];
        break;
      case 'Last 12 months':
        start = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
        break;
      case 'Last 16 months':
        start = new Date(new Date().setMonth(new Date().getMonth() - 16)).toISOString().split('T')[0];
        break;
      default:
        start = new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().split('T')[0];
    }

    setStartDate(start);
    setEndDate(end);
  };

  const columns = [
    { field: 'country', headerName: 'Country', width: 800 },
    { field: 'clicks', headerName: 'Clicks', width: 150 },
    { field: 'impressions', headerName: 'Impressions', width: 150 },
  ];

  return (
    <Box className="tabs-container">
      <Box className="tabs-header">
        <CustomTabPanel value={value} index={0}>
          <Box className="mt-3">
            <h2>Top Trending Countries</h2>
            <div className='mt-5'>

            <DataGrid rows={trendingCountries} columns={columns} pageSize={10} />
            </div>
            <h2 className='mt-5'> Top Declining Countries</h2>
            <div className='mt-5'>

            <DataGrid rows={decliningCountries} columns={columns} pageSize={10} />
            </div>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
