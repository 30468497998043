import React, { createContext, useContext, useState } from 'react';

const DomainContext = createContext();

export function useDomain() {
  return useContext(DomainContext);
}

export function DomainProvider({ children }) {
  const [selectedLink, setSelectedLink] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <DomainContext.Provider value={{ selectedLink, setSelectedLink, searchTerm, setSearchTerm }}>
      {children}
    </DomainContext.Provider>
  );
}