// import * as React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
// import Box from '@mui/material/Box';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import NotificationsIcon from '@mui/icons-material/Notifications';
// import AccountCircle from '@mui/icons-material/AccountCircle';
// import MailIcon from '@mui/icons-material/Mail';
// import { Routes, Route, Navigate } from 'react-router-dom';
// import ProtectedRoute from './auth/protectedRoute';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import List from './List';
// import SiteData from './SiteData';
// import KeywordHits from './KeywordHits';
// import IndexedPages from './IndexedPages';
// import { useAuth } from './context/authcontext';
// import Login from './auth/login';
// import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';
// import { alpha } from '@mui/material/styles';
// import { DomainProvider  } from './context/DomainContext';
// import ChatPage from './Aichat';
// import Logo from './logo-rankplus.svg';
// import { Menu, MenuItem } from '@mui/material';
// import SitelinksSearchBoxPage from './pages/SitelinksSearchBoxPage';
// import SitemapsPage from './pages/SitemapsPage';
// import VideoPages from './pages/VideoPages';
// import RemovalsPage from './pages/RemovalsPage';
// import HttpsPage from './pages/HttpsPage';
// import BreadcrumbsPage from './pages/BreadcrumbsPage';
// import CoreWebVitalsPage from './pages/CoreWebVitalsPage';
// import AnalyticsDataList from './AnalyticsDataList';
// import ActivityLog from './Activity';
// import InstallData from './Installdata';
 


// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © RankPlus '}
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }



// const drawerWidth = 300; 

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
//   backgroundColor: '#f0f3f8', // Background color
//   boxShadow: 'none', // Remove the box shadow
// }));

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   marginLeft:'-30px'
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%', // Fixed width for the input
//     [theme.breakpoints.up('md')]: {
//       width: '100%', // Adjusted width for larger screens
//     },
//     marginLeft: 'auto',
//   },
// }));


// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     '& .MuiDrawer-paper': {
//       position: 'relative',
//       whiteSpace: 'nowrap',
//       width: drawerWidth, // Increased width
//       transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       boxSizing: 'border-box',
//       backgroundColor: 'rgb(240,243,248)', 
//       boxShadow: 'none', 
//       ...(!open && {
//         overflowX: 'hidden',
//         transition: theme.transitions.create('width', {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//         width: theme.spacing(7),
//         [theme.breakpoints.up('sm')]: {
//           width: theme.spacing(9),
//         },
//       }),
//     },
//   }),
// );

// const defaultTheme = createTheme({
//   palette: {
//     mode: 'light',
//     background: {
//       default: '#f0f3f8', // Background color
//     },
//     primary: {
//       main: '#f0f3f8', // Primary color
//     },
//     secondary: {
//       main: '#f0f3f8', // Secondary color
//     },
//     paper: {
//       backgroundColor: '#f0f3f8', // Paper color
//     },
//   },
//   components: {
//     MuiAppBar: {
//       styleOverrides: {
//         root: {
//           backgroundColor: '#f0f3f8', // Background color
//           boxShadow: 'none', // Remove the box shadow
          
//         },
//       },
//     },
 
//     MuiDrawer: {
//       styleOverrides: {
//         paper: {
//           backgroundColor: '#f0f3f8', // Background color
//           boxShadow: 'none', // Remove the box shadow
//           border: 'none',
//           flexGrow: 1,
//           height: '100vh',
//           overflow: 'hidden', // Hide the scrollbar by default
//           '&:hover': {
//             overflowY: 'auto', // Show the scrollbar when hovering
//           },
//           '&::-webkit-scrollbar': {
//             width: '8px',
//           },
//           '&::-webkit-scrollbar-thumb': {
//             backgroundColor: '#c5c7c5', // Color of the scrollbar thumb
//             borderRadius: '10px',  // Round the scrollbar thumb
//             minHeight: '10px', // Round the scrollbar thumb
//           },
//           '&::-webkit-scrollbar-thumb:hover': {
//             backgroundColor: '#c5c7c5', // Darker color on hover
//           },
//           '&::-webkit-scrollbar-track': {
//             backgroundColor: '#f0f3f8', // Color of the scrollbar track
//           },
//         },
//       },
//     },
    
//     MuiTypography: {
//       styleOverrides: {
//         root: {
//           color: '#333', // Text color, adjust if needed
//         },
//       },
//     },
//   },
// });

// function DashboardContent() {
//   const [open, setOpen] = React.useState(true);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   // const [anchorEl, setAnchorEl] = useState(null);

//   const toggleDrawer = () => {
//     setOpen(!open);
//   };

//   const { logout ,  authData  } = useAuth();


//   const handleMenu = (event) => {
//     setAnchorEl(event.currentTarget);
//   };


  
 

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleLogout = () => {
//     handleClose();
//     logout();
//   };

//   const handleProfileMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   return (
//     <>
     
// <AppBar position="absolute" open={open} sx={{ backgroundColor: '#f0f3f8' }}>
//       <Toolbar sx={{ pr: '24px' }}>
//         <IconButton
//           edge="start"
//           color="inherit"
//           aria-label="open drawer"
//           onClick={toggleDrawer}
//           sx={{
//             marginRight: '36px',
//             ...(open && { display: 'none' }),
//           }}
//         >
//           <MenuIcon />
//         </IconButton>

//         <Box sx={{ flexGrow: 1, display: 'flex', backgroundColor: '#E0EDFF', borderRadius: '18px' }}>
//           <Search>
//             <SearchIconWrapper>
//               <SearchIcon />
//             </SearchIconWrapper>
//             <StyledInputBase
//               placeholder="Inspect any URL in 'https://www.emlreader.com/'"
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </Search>
//         </Box>

//         <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
//           {authData ? (
//             <Typography variant="h6" sx={{ marginRight: '16px', color: 'text.primary' }}>
//               Welcome, {authData.name}
//             </Typography>
//           ) : null}
//           <IconButton size="large" color="inherit">
//             <HelpOutlineIcon />
//           </IconButton>
//           <IconButton size="large" color="inherit">
//             <Badge badgeContent={34} color="error">
//               <NotificationsIcon />
//             </Badge>
//           </IconButton>
//           <IconButton size="large" color="inherit" onClick={handleMenu}>
//             <AccountCircle />
//           </IconButton>
//           <Menu
//             id="menu-appbar"
//             anchorEl={anchorEl}
//             anchorOrigin={{
//               vertical: 'top',
//               horizontal: 'right',
//             }}
//             keepMounted
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'right',
//             }}
//             open={Boolean(anchorEl)}
//             onClose={handleClose}
//           >
//             {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
//             <MenuItem onClick={handleLogout}>Logout</MenuItem>
//           </Menu>
//         </Box>
//       </Toolbar>
//     </AppBar>
//       <Drawer variant="permanent" open={open}>
//         <Toolbar
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             // justifyContent: 'flex-end',
//             px: [1],
//           }}
//         >
        
//           <IconButton onClick={toggleDrawer}>
//             <ChevronLeftIcon />
//           </IconButton>
//           <img src={Logo}
//         width={300}
//         height={50}
//       className='me-5'
//         />
//           {/*   domain input section */}
//         </Toolbar>
//         <Divider />
//         <List component="nav">
//           {List}
//           <Divider sx={{ my: 1 }} />
//         </List>
//       </Drawer>
//       <Box
//         component="main"
//         sx={{
//           backgroundColor: '#f0f3f8', 
//           flexGrow: 1,
//           height: '100vh',
//           overflow: 'auto',
//         }}
//       >
//         <Toolbar />
//         <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
//           <Grid container spacing={''} lg={12} md={12}>
//             <Grid item xs={12} lg={12} md={12}>
//               <Routes>
//                 <Route path="/sitedata" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SiteData /></ProtectedRoute>} />
//                 <Route path="/chat" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><ChatPage /></ProtectedRoute>} />
//                 <Route path="/keywords" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><KeywordHits /></ProtectedRoute>} />
//                 <Route path="/country" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><KeywordHits /></ProtectedRoute>} />
//                 <Route path="/indexedpages" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><IndexedPages /></ProtectedRoute>} />
//                 <Route path="/sitelinks" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SitelinksSearchBoxPage /></ProtectedRoute>} />
//                 <Route path="/sitemap" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SitemapsPage /></ProtectedRoute>} />
//                 <Route path="/videopages" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><VideoPages /></ProtectedRoute>} />
//                 <Route path="/removals" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><RemovalsPage /></ProtectedRoute>} />
//                 <Route path="/https" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><HttpsPage /></ProtectedRoute>} />
//                 <Route path="/breadcrumbs" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><BreadcrumbsPage /></ProtectedRoute>} />
//                 <Route path="/corewebvital" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><CoreWebVitalsPage /></ProtectedRoute>} />
//                 <Route path="/analytics" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><AnalyticsDataList /></ProtectedRoute>} />
//                 <Route path="/activity" element={<ProtectedRoute roles={['admin']}><ActivityLog /></ProtectedRoute>} />
//                 <Route path="/install" element={<ProtectedRoute roles={['admin' , 'support' , 'developer']}><InstallData/></ProtectedRoute>} />
//                 <Route path="/" element={<Navigate to="/sitedata" />} />
//               </Routes>
//             </Grid>
//           </Grid>
//           <Copyright sx={{ pt: 4 }} />
//         </Container>
//       </Box>
//     </>
//   );
// }



// export default function App() {
//   const { authData, login } = useAuth();

//   const handleLogin = (userData) => {
//     login(userData);
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Box sx={{ display: 'flex' }}>
//         <CssBaseline />
//         {authData && authData.user ? (
//           <DashboardContent />
//         ) : (
//           <Container component="main" maxWidth="md">
//             <CssBaseline />
//             <Box
//               sx={{
//                 marginTop: 8,
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'center',
//               }}
//             >
//               <Login onLogin={handleLogin} />
//             </Box>
//             <Copyright sx={{ mt: 8, mb: 4 }} style={{ marginTop: '150px' }} />
//           </Container>
//         )}
//       </Box>
//     </ThemeProvider>
//   );
// }


import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './auth/protectedRoute';
import List from './List';
import SiteData from './SiteData';
import KeywordHits from './KeywordHits';
import IndexedPages from './IndexedPages';
import { useAuth } from './context/authcontext';
import Login from './auth/login';
import { DomainProvider } from './context/DomainContext';
import ChatPage from './Aichat';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Logo from './logo-rankplus.svg';
import { Menu, MenuItem } from '@mui/material';
import SitelinksSearchBoxPage from './pages/SitelinksSearchBoxPage';
import SitemapsPage from './pages/SitemapsPage';
import VideoPages from './pages/VideoPages';
import RemovalsPage from './pages/RemovalsPage';
import HttpsPage from './pages/HttpsPage';
import BreadcrumbsPage from './pages/BreadcrumbsPage';
import CoreWebVitalsPage from './pages/CoreWebVitalsPage';
import AnalyticsDataList from './AnalyticsDataList';
import ActivityLog from './Activity';
import InstallData from './Installdata';

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: '#f0f3f8',
  boxShadow: 'none',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '0',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      backgroundColor: 'rgb(240,243,248)',
      boxShadow: 'none',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const StyledTooltipContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2),
  maxWidth: '350px',
  fontFamily: 'Arial, sans-serif',
  animation: 'fadeIn 0.5s ease-in-out',
  '& h6': {
    color: '#3f51b5',
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  '& p': {
    color: '#333',
    marginBottom: theme.spacing(1),
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

const defaultTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#f0f3f8',
    },
    primary: {
      main: '#f0f3f8',
    },
    secondary: {
      main: '#f0f3f8',
    },
    paper: {
      backgroundColor: '#f0f3f8',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f3f8',
          boxShadow: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f0f3f8',
          boxShadow: 'none',
          border: 'none',
          flexGrow: 1,
          height: '100vh',
          overflow: 'hidden',
          '&:hover': {
            overflowY: 'auto',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c5c7c5',
            borderRadius: '10px',
            minHeight: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#c5c7c5',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f3f8',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#333',
        },
      },
    },
  },
});

function DashboardContent() {
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open1, setOpen1] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const { logout, authData } = useAuth();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };





  const handleTooltipOpen = () => {
    setOpen1(true);
  };

  const handleTooltipClose = () => {
    setOpen1(false);
  };

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © RankPlus '}
      {new Date().getFullYear()}
    </Typography>
  );
}


  return (
    <>
      <AppBar position="absolute" open={open} sx={{ backgroundColor: '#f0f3f8' }}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: 'flex', backgroundColor: '#E0EDFF', borderRadius: '18px' }}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Inspect any URL in 'https://www.emlreader.com/'"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {authData ? (
              <Typography variant="h6" sx={{ marginRight: '16px', color: 'text.primary' }}>
                Welcome, {authData.name}
              </Typography>
            ) : null}
            <IconButton size="large" color="inherit">
              {/* <HelpOutlineIcon /> */}
              <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          title={
            <StyledTooltipContent>
              <Typography variant="h6" color="inherit">
                Welcome to RankPlus
              </Typography>
              <Typography variant="body2" color="inherit">
                RankPlus is your all-in-one SEO analytics and website performance tool, designed to give you deep insights into how your website is performing on search engines. With our platform, you can track your top keywords, monitor the performance of your most important pages, and uncover critical data about your site's visibility.
              </Typography>
              <Typography variant="body2" color="inherit">
                Whether you're a site owner, SEO professional, or digital marketer, RankPlus helps you:
              </Typography>
              <Typography variant="body2" color="inherit">
                <strong>Analyze Keywords:</strong> Discover which search terms are driving the most traffic to your site and optimize your content accordingly.
              </Typography>
              <Typography variant="body2" color="inherit">
                <strong>Track Top Pages:</strong> Keep an eye on your best-performing pages and identify opportunities to boost underperforming ones.
              </Typography>
              <Typography variant="body2" color="inherit">
                <strong>Monitor Search Trends:</strong> Stay updated with real-time data on user engagement and trends to refine your SEO strategy.
              </Typography>
              <Typography variant="body2" color="inherit">
                Our easy-to-use dashboard provides a comprehensive view of your website's health, allowing you to stay ahead in search rankings and enhance your online visibility.
              </Typography>
            </StyledTooltipContent>
          }
          open={open1}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          placement="bottom-start"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ],
          }}
          TransitionProps={{ timeout: 300 }}
          sx={{
            '& .MuiTooltip-arrow': {
              color: '#ffffff',
            },
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'transparent',
              padding: 0,
              borderRadius: '10px',
            },
          }}
        >
          <IconButton size="large" color="inherit" onClick={handleTooltipOpen}>
            <HelpOutlineIcon sx={{ color: '#3f51b5', fontSize: '2rem' }} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
            </IconButton>
            <IconButton size="large" color="inherit">
              <Badge badgeContent={34} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton size="large" color="inherit" onClick={handleMenu}>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
          <img src={Logo} width={200} height={40} className='me-5' alt="Logo" />
        </Toolbar>
        <Divider />
        <List component="nav">
          {List}
          <Divider sx={{ my: 1 }} />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: '#f0f3f8',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Routes>
                <Route path="/sitedata" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SiteData /></ProtectedRoute>} />
                <Route path="/chat" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><ChatPage /></ProtectedRoute>} />
                <Route path="/keywords" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><KeywordHits /></ProtectedRoute>} />
                <Route path="/country" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><KeywordHits /></ProtectedRoute>} />
                <Route path="/indexedpages" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><IndexedPages /></ProtectedRoute>} />
                <Route path="/sitelinks" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SitelinksSearchBoxPage /></ProtectedRoute>} />
                <Route path="/sitemap" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><SitemapsPage /></ProtectedRoute>} />
                <Route path="/videopages" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><VideoPages /></ProtectedRoute>} />
                <Route path="/removals" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><RemovalsPage /></ProtectedRoute>} />
                <Route path="/https" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><HttpsPage /></ProtectedRoute>} />
                <Route path="/breadcrumbs" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><BreadcrumbsPage /></ProtectedRoute>} />
                <Route path="/corewebvital" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><CoreWebVitalsPage /></ProtectedRoute>} />
                <Route path="/analytics" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><AnalyticsDataList /></ProtectedRoute>} />
                <Route path="/activity" element={<ProtectedRoute roles={['admin']}><ActivityLog /></ProtectedRoute>} />
                <Route path="/install" element={<ProtectedRoute roles={['admin', 'support', 'developer']}><InstallData /></ProtectedRoute>} />
                <Route path="/" element={<Navigate to="/sitedata" />} />
              </Routes>
            </Grid>
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </>
  );
}

export default function App() {
  const { authData, login } = useAuth();

  const handleLogin = (userData) => {
    login(userData);
  };

  
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © RankPlus '}
      {new Date().getFullYear()}
    </Typography>
  );
}


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {authData && authData.user ? (
          <DashboardContent />
        ) : (
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Login onLogin={handleLogin} />
            </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} style={{ marginTop: '150px' }} />
          </Container>
        )}
      </Box>
    </ThemeProvider>
  );
}
