// import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Divider from '@mui/material/Divider';
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import MapIcon from '@mui/icons-material/Map';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import Collapse from '@mui/material/Collapse';
// import { Link, useLocation } from 'react-router-dom';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import AddIcon from '@mui/icons-material/Add';
// import { TextField } from '@mui/material';
// import { useDomain } from './context/DomainContext';
// import {
//     Modal,
//     Button,
//     Typography
//   } from '@mui/material';
//   import axios from 'axios';
  



// export default function BasicList({}) {
//   const [links, setLinks] = useState([]);
//   // console.log(links , "Links");
//   const { selectedLink, setSelectedLink } = useDomain();
//   const [searchTerm, setSearchTerm] = useState('');
//   const [open, setOpen] = React.useState(true);
//   const [openModal, setOpenModal] = useState(false);
//   const [newLink, setNewLink] = useState('');


//   const handleClick = () => {
//     setOpen(!open);
//   };
//   const [open1, setOpen1] = React.useState(true);

//   const handleClick1 = () => {
//     setOpen1(!open1);
//   };
//   const [open2, setOpen2] = React.useState(true);

//   const handleClick2 = () => {
//     setOpen2(!open2);
//   };
//  // State for collapse
//   const location = useLocation(); // To get the current route

//   useEffect(() => {
//     fetch('https://brank.supportplus.app/v1/api/links')
//     // fetch('http://localhost:8080/v1/api/links')
//       .then((response) => response.json())
//       .then((data) => setLinks(data))
    
//       .catch((error) => console.error('Error fetching links:', error));
//   }, []);

//   const handleLinkChange = (event) => {
//     setSelectedLink(event.target.value);
//   };
//   useEffect(() => {
//     // Example to fetch data when selectedLink changes
//     if (selectedLink) {
//       // console.log(`Fetching data for ${selectedLink}`);
//       // Add your data fetching logic here
//     }
//   }, [selectedLink]); 

//   const [anchorEl, setAnchorEl] = useState(null);
  


//   const filteredLinks = links.filter(link => link.toLowerCase().includes(searchTerm.toLowerCase()));

//   const handleOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };


//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };
 
//   const isActive = (path) => location.pathname === path;

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };


//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setNewLink('');
//   };

//   const handleSaveLink = async () => {
//     try {
//       const response = await axios.post('https://brank.supportplus.app/v1/api/paste-link', { link: newLink });
//     //   const response = await axios.post('http://localhost:8080/api/paste-link', { link: newLink });
//       if (response.status === 200) {
//         setLinks([...links, newLink]);
//         handleCloseModal();
//       }
//     } catch (error) {
//       console.error('Error saving link:', error);
//     }
//   };
// <BasicList
//   selectedLink={selectedLink}
//   setSelectedLink={setSelectedLink}
// />

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         maxWidth: 360,
//         bgcolor: '#f0f3f8',
//         fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
//         fontWeight: 500,
//         color: 'rgb(31, 31, 31)',
//         fontSize: '14px',
//         lineHeight: '20px',
//       }}
//     >
//       <nav aria-label="main mailbox folders">
//         <List>
//           <ListItem  sx={{
//     // position: 'fixed',
//     // top: '60px', // Adjust this value to set the vertical position
//     // left: '20px', 
//     // zIndex: 1000, 
//     // width: '300px',
//     // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', 
//     // borderRadius: '10px', 
//     // backgroundColor: 'white',
//   }}>
//     {/* input section for domains  */}
// <FormControl fullWidth >
//           {/* <InputLabel id="link-select-label">Select Domain</InputLabel> */}
//           <Select
//             labelId="link-select-label"
//             id="link-select"
//             value={selectedLink}
//             label="Select Domain"
//             onChange={handleLinkChange}
//             sx={{
//               border: '1px solid black',
//               borderRadius: '25px',
//               padding: '0 10px',
//               '& .MuiSelect-select': {
//                 padding: '10px',
//                 borderRadius: '25px',
//                 fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 color: 'rgb(31, 31, 31)',
//                 fontSize: '14px',
//                 lineHeight: '20px',
//               },
//               '& .MuiOutlinedInput-notchedOutline': {
//                 border: 'none',
//               },
//               '&:hover': {
//                 borderColor: 'black',
//               },
//             }}
//             MenuProps={{
//               PaperProps: {
//                 sx: {
//                   borderRadius: '25px',
//                   border: '1px solid black',
//                   maxHeight: '300px',
//                   overflowY: 'auto',
//                   padding: '10px',
//                 },
//               },
//             }}
//             renderValue={(selected) => selected || 'Select Domain'}
//           >
//             {filteredLinks.map((link, index) => (
//               <MenuItem
//                 key={index}
//                 value={link}
//                 sx={{
//                   fontFamily: 'Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   color: 'rgb(31, 31, 31)',
//                   fontSize: '14px',
//                   lineHeight: '20px',
//                 }}
//               >
//                 {link}
//               </MenuItem>
//             ))}
//             <Divider />
//             <MenuItem
//               sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//               onClick={handleOpenModal}
//             >
//               <ListItemIcon>
//                 <AddIcon />
//               </ListItemIcon>
//               <ListItemText primary="Add New Domain" />
//             </MenuItem>
//           </Select>
//         </FormControl>
        
//         <Modal open={openModal} onClose={handleCloseModal}>
//           <Box
//             sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               width: 400,
//               bgcolor: 'background.paper',
//               border: '2px solid #000',
//               boxShadow: 24,
//               p: 4,
//               borderRadius: '10px',
//             }}
//           >
//             <Typography variant="h6" component="h2">
//               Add New Domain
//             </Typography>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Paste your link"
//               value={newLink}
//               onChange={(e) => setNewLink(e.target.value)}
//               sx={{ mt: 2, mb: 2 }}
//             />
//             <Button variant="contained" color="primary" onClick={handleSaveLink}>
//               Save
//             </Button>
//           </Box>
//         </Modal>
//           </ListItem>
//           <ListItem disablePadding sx={{ms:5}}>
//             <ListItemButton
//               component={Link}
//               to="/sitedata"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 marginTop:'20px',
//                 fontFamily: 'Open Sans, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '25px',
//                 borderRadius: '8px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 },
//                 ...(isActive('/sitedata') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Performance"
//                 primaryTypographyProps={{
//                   sx: {
//                     color: 'rgb(31, 31, 31)',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/keywords"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '15px',
//                 lineHeight: '25px',
//                 borderRadius: '20px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 },
//                 ...(isActive('/keywords') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <DraftsIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Keywords"
//                 primaryTypographyProps={{
//                   sx: {
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '25px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/indexedpages"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '20px',
//                 borderRadius: '25px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 },
//                 ...(isActive('/indexedpages') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <DraftsIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Pages"
//                 primaryTypographyProps={{
//                   sx: {
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
            
//           </ListItem>
//           <ListItemButton
//                   component={Link}
//                   to="/analytics"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/analytics') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Analytics"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//           <ListItemButton
//                   component={Link}
//                   to="/install"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/install') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Download Report"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/chat"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/chat') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <MapIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Chat Page" 
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//         </List>
//       </nav>
//     </Box>
//   );
// }



import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import InsightsIcon from '@mui/icons-material/Insights';
import GetAppIcon from '@mui/icons-material/GetApp';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import PageviewIcon from '@mui/icons-material/Pageview'; 
import { useDomain } from './context/DomainContext';

export default function BasicList() {
  const [links, setLinks] = useState([]);
  const { selectedLink, setSelectedLink } = useDomain();
  const [searchTerm, setSearchTerm] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [newLink, setNewLink] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const location = useLocation(); // To get the current route

  useEffect(() => {
    fetch('https://brank.supportplus.app/v1/api/links')
      .then((response) => response.json())
      .then((data) => setLinks(data))
      .catch((error) => console.error('Error fetching links:', error));
  }, []);

  const handleLinkChange = (event) => {
    setSelectedLink(event.target.value);
  };

  const filteredLinks = links.filter(link =>
    link.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewLink('');
  };

  const handleSaveLink = async () => {
    try {
      const response = await axios.post('https://brank.supportplus.app/v1/api/paste-link', { link: newLink });
      if (response.status === 200) {
        setLinks([...links, newLink]);
        handleCloseModal();
      }
    } catch (error) {
      console.error('Error saving link:', error);
    }
  };

  const isActive = (path) => location.pathname === path;

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        bgcolor: '#f0f3f8',
        fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
        fontWeight: 500,
        color: 'rgb(31, 31, 31)',
        fontSize: '14px',
        lineHeight: '20px',
      }}
    >
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem>
            {/* Input section for domains */}
            <FormControl fullWidth>
              <Select
                labelId="link-select-label"
                id="link-select"
                value={selectedLink}
                onChange={handleLinkChange}
                sx={{
                  border: '1px solid black',
                  borderRadius: '25px',
                  padding: '0 10px',
                  '& .MuiSelect-select': {
                    padding: '10px',
                    borderRadius: '25px',
                    fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    color: 'rgb(31, 31, 31)',
                    fontSize: '14px',
                    lineHeight: '20px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                renderValue={(selected) => selected || 'Select Domain'}
              >
                {filteredLinks.map((link, index) => (
                  <MenuItem
                    key={index}
                    value={link}
                    sx={{
                      fontFamily: 'Roboto, Arial, sans-serif',
                      fontWeight: 500,
                      color: 'rgb(31, 31, 31)',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}
                  >
                    {link}
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  onClick={handleOpenModal}
                >
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add New Domain" />
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/sitedata"
              sx={{
                color: 'black',
                fontFamily: 'Open Sans, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '25px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '15px',
                },
                ...(isActive('/sitedata') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '15px',
                }),
              }}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText
                primary="Performance"
                primaryTypographyProps={{
                  sx: {
                    color: 'rgb(31, 31, 31)',
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/keywords"
              sx={{
                color: 'black',
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '25px',
                borderRadius: '20px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                },
                ...(isActive('/keywords') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                }),
              }}
            >
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Keywords"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '25px',
                  },
                  
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/indexedpages"
              sx={{
                color: 'black',
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                borderRadius: '25px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                },
                ...(isActive('/indexedpages') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                }),
              }}
            >
              <ListItemIcon>
                <PageviewIcon />
              </ListItemIcon>
              <ListItemText
                primary="Pages"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/analytics"
              sx={{
                color: 'black',
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                borderRadius: '25px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                },
                ...(isActive('/analytics') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                }),
              }}
            >
              <ListItemIcon>
                <InsightsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Analytics"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/install"
              sx={{
                color: 'black',
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                borderRadius: '25px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                },
                ...(isActive('/install') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                }),
              }}
            >
              <ListItemIcon>
                <GetAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Download Report"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/chat"
              sx={{
                color: 'black',
                fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '20px',
                borderRadius: '25px',
                '&:hover': {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                },
                ...(isActive('/chat') && {
                  backgroundColor: 'rgb(211 , 227 , 253)',
                  borderRadius: '25px',
                }),
              }}
            >
              <ListItemIcon>
                <ChatIcon />
              </ListItemIcon>
              <ListItemText
                primary="Chat Page"
                primaryTypographyProps={{
                  sx: {
                    fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
        }}
      >
        {drawerContent}
      </Drawer>

      <Box
        component="nav"
        sx={{ width: { sm: 250 }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
          }}
          open
        >
          {drawerContent}
        </Drawer>
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Add New Domain
          </Typography>
          <TextField
            fullWidth
            label="New Domain"
            value={newLink}
            onChange={(e) => setNewLink(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            onClick={handleSaveLink}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
}


// import React, { useEffect, useState } from 'react';
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Divider from '@mui/material/Divider';
// import InboxIcon from '@mui/icons-material/Inbox';
// import DraftsIcon from '@mui/icons-material/Drafts';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
// import MapIcon from '@mui/icons-material/Map';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
// import Collapse from '@mui/material/Collapse';
// import { Link, useLocation } from 'react-router-dom';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import AddIcon from '@mui/icons-material/Add';
// import { TextField } from '@mui/material';
// import { useDomain } from './context/DomainContext';
// import {
//     Modal,
//     Button,
//     Typography
//   } from '@mui/material';
//   import axios from 'axios';
  



// export default function BasicList({}) {
//   const [links, setLinks] = useState([]);
//   // console.log(links , "Links");
//   const { selectedLink, setSelectedLink } = useDomain();
//   const [searchTerm, setSearchTerm] = useState('');
//   const [open, setOpen] = React.useState(true);
//   const [openModal, setOpenModal] = useState(false);
//   const [newLink, setNewLink] = useState('');


//   const handleClick = () => {
//     setOpen(!open);
//   };
//   const [open1, setOpen1] = React.useState(true);

//   const handleClick1 = () => {
//     setOpen1(!open1);
//   };
//   const [open2, setOpen2] = React.useState(true);

//   const handleClick2 = () => {
//     setOpen2(!open2);
//   };
//  // State for collapse
//   const location = useLocation(); // To get the current route

//   useEffect(() => {
//     fetch('https://brank.supportplus.app/v1/api/links')
//     // fetch('http://localhost:8080/v1/api/links')
//       .then((response) => response.json())
//       .then((data) => setLinks(data))
    
//       .catch((error) => console.error('Error fetching links:', error));
//   }, []);

//   const handleLinkChange = (event) => {
//     setSelectedLink(event.target.value);
//   };
//   useEffect(() => {
//     // Example to fetch data when selectedLink changes
//     if (selectedLink) {
//       // console.log(`Fetching data for ${selectedLink}`);
//       // Add your data fetching logic here
//     }
//   }, [selectedLink]); 

//   const [anchorEl, setAnchorEl] = useState(null);
  


//   const filteredLinks = links.filter(link => link.toLowerCase().includes(searchTerm.toLowerCase()));

//   const handleOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };


//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };
 
//   const isActive = (path) => location.pathname === path;

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };


//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setNewLink('');
//   };

//   const handleSaveLink = async () => {
//     try {
//       const response = await axios.post('https://brank.supportplus.app/v1/api/paste-link', { link: newLink });
//     //   const response = await axios.post('http://localhost:8080/api/paste-link', { link: newLink });
//       if (response.status === 200) {
//         setLinks([...links, newLink]);
//         handleCloseModal();
//       }
//     } catch (error) {
//       console.error('Error saving link:', error);
//     }
//   };
// <BasicList
//   selectedLink={selectedLink}
//   setSelectedLink={setSelectedLink}
// />

//   return (
//     <Box
//       sx={{
//         width: '100%',
//         maxWidth: 360,
//         bgcolor: '#f0f3f8',
//         fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
//         fontWeight: 500,
//         color: 'rgb(31, 31, 31)',
//         fontSize: '14px',
//         lineHeight: '20px',
//       }}
//     >
//       <nav aria-label="main mailbox folders">
//         <List>
//           <ListItem  sx={{
//     // position: 'fixed',
//     // top: '60px', // Adjust this value to set the vertical position
//     // left: '20px', 
//     // zIndex: 1000, 
//     // width: '300px',
//     // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', 
//     // borderRadius: '10px', 
//     // backgroundColor: 'white',
//   }}>
//     {/* input section for domains  */}
// <FormControl fullWidth >
//           {/* <InputLabel id="link-select-label">Select Domain</InputLabel> */}
//           <Select
//             labelId="link-select-label"
//             id="link-select"
//             value={selectedLink}
//             label="Select Domain"
//             onChange={handleLinkChange}
//             sx={{
//               border: '1px solid black',
//               borderRadius: '25px',
//               padding: '0 10px',
//               '& .MuiSelect-select': {
//                 padding: '10px',
//                 borderRadius: '25px',
//                 fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 color: 'rgb(31, 31, 31)',
//                 fontSize: '14px',
//                 lineHeight: '20px',
//               },
//               '& .MuiOutlinedInput-notchedOutline': {
//                 border: 'none',
//               },
//               '&:hover': {
//                 borderColor: 'black',
//               },
//             }}
//             MenuProps={{
//               PaperProps: {
//                 sx: {
//                   borderRadius: '25px',
//                   border: '1px solid black',
//                   maxHeight: '300px',
//                   overflowY: 'auto',
//                   padding: '10px',
//                 },
//               },
//             }}
//             renderValue={(selected) => selected || 'Select Domain'}
//           >
//             {filteredLinks.map((link, index) => (
//               <MenuItem
//                 key={index}
//                 value={link}
//                 sx={{
//                   fontFamily: 'Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   color: 'rgb(31, 31, 31)',
//                   fontSize: '14px',
//                   lineHeight: '20px',
//                 }}
//               >
//                 {link}
//               </MenuItem>
//             ))}
//             <Divider />
//             <MenuItem
//               sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//               onClick={handleOpenModal}
//             >
//               <ListItemIcon>
//                 <AddIcon />
//               </ListItemIcon>
//               <ListItemText primary="Add New Domain" />
//             </MenuItem>
//           </Select>
//         </FormControl>
        
//         <Modal open={openModal} onClose={handleCloseModal}>
//           <Box
//             sx={{
//               position: 'absolute',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               width: 400,
//               bgcolor: 'background.paper',
//               border: '2px solid #000',
//               boxShadow: 24,
//               p: 4,
//               borderRadius: '10px',
//             }}
//           >
//             <Typography variant="h6" component="h2">
//               Add New Domain
//             </Typography>
//             <TextField
//               fullWidth
//               variant="outlined"
//               label="Paste your link"
//               value={newLink}
//               onChange={(e) => setNewLink(e.target.value)}
//               sx={{ mt: 2, mb: 2 }}
//             />
//             <Button variant="contained" color="primary" onClick={handleSaveLink}>
//               Save
//             </Button>
//           </Box>
//         </Modal>
//           </ListItem>
//           <ListItem disablePadding sx={{ms:5}}>
//             <ListItemButton
//               component={Link}
//               to="/sitedata"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 marginTop:'20px',
//                 fontFamily: 'Open Sans, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '25px',
//                 borderRadius: '8px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 },
//                 ...(isActive('/sitedata') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Performance"
//                 primaryTypographyProps={{
//                   sx: {
//                     color: 'rgb(31, 31, 31)',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/keywords"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '15px',
//                 lineHeight: '25px',
//                 borderRadius: '20px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 },
//                 ...(isActive('/keywords') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <DraftsIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Keywords"
//                 primaryTypographyProps={{
//                   sx: {
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '25px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/indexedpages"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '20px',
//                 borderRadius: '25px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 },
//                 ...(isActive('/indexedpages') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '25px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <DraftsIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Pages"
//                 primaryTypographyProps={{
//                   sx: {
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
            
//           </ListItem>
//           <ListItemButton
//                   component={Link}
//                   to="/analytics"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/analytics') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Analytics"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//           <ListItemButton
//                   component={Link}
//                   to="/install"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/install') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Download Report"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/chat"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/chat') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <MapIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Chat Page" 
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
             
//           {/* <Divider sx={{  }} />
        
//             <ListItemButton onClick={handleClick}>
//             {open ? <ExpandLess /> : <ExpandMore />}
//               <ListItemText primary="Indexing"
//               primaryTypographyProps={{
//                 sx: {
//                   fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   fontSize: '14px',
//                   lineHeight: '20px',
//                   color:'grey'
//                 },
//               }}
//               />
             
//             </ListItemButton>
//             <Collapse in={open} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItemButton
//                   component={Link}
//                   to="/sitemap"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/sitemap') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <MapIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Sitemaps" 
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/removals"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/removals') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Removals"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/pages"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/pages') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <PageviewIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Pages"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/videopages"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/videopages') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <VideoLibraryIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Video Pages" 
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//               </List>
//             </Collapse>
    
//       <Divider sx={{  }} />
//       <ListItemButton onClick={handleClick1}>
//       {open1 ? <ExpandLess /> : <ExpandMore />}
//               <ListItemText primary="Experience"
//               primaryTypographyProps={{
//                 sx: {
//                   fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   fontSize: '14px',
//                   lineHeight: '20px',
//                   color:'grey'
//                 },
//               }}
//               />
//             </ListItemButton>
//             <Collapse in={open1} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
                
//                 <ListItemButton
//                   component={Link}
//                   to="/https"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/https') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <PageviewIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="HTTPS"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//               </List>
//             </Collapse>
//             <Divider sx={{}}/>
//             <ListItemButton onClick={handleClick2}>
//             {open2 ? <ExpandLess /> : <ExpandMore />}
//               <ListItemText primary="Enhancements"
//               primaryTypographyProps={{
//                 sx: {
//                   fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                   fontWeight: 500,
//                   fontSize: '14px',
//                   lineHeight: '20px',
//                   color:'grey'
//                 },
//               }}
//               />
//             </ListItemButton>
//             <Collapse in={open2} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 <ListItemButton
//                   component={Link}
//                   to="/breadcrumbs"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/breadcrumbs') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <MapIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Breadcrumbs" 
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/sitelinks"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/sitelinks') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <RemoveCircleIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Sitelinks searchbox"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//                 <ListItemButton
//                   component={Link}
//                   to="/videopages"
//                   sx={{
//                     pl: 4,
//                     color: 'black',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '14px',
//                     lineHeight: '20px',
//                     borderRadius: '25px',
//                     '&:hover': {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     },
//                     ...(isActive('/videopages') && {
//                       backgroundColor: 'rgb(211 , 227 , 253)',
//                       borderRadius: '25px',
//                     }),
//                   }}
//                 >
//                   <ListItemIcon>
//                     <PageviewIcon />
//                   </ListItemIcon>
//                   <ListItemText primary="Videos"
                  
//                   primaryTypographyProps={{
//                     sx: {
//                       fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                       fontWeight: 500,
//                       fontSize: '15px',
//                       lineHeight: '20px',
//                     },
//                   }}
//                   />
//                 </ListItemButton>
//               </List>
//             </Collapse>
//             <Divider sx={{my:2}}/>
//             <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/activity"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: 'Open Sans, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '20px',
//                 borderRadius: '8px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 },
//                 ...(isActive('/activity') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Activity"
//                 primaryTypographyProps={{
//                   sx: {
//                     color: 'rgb(31, 31, 31)',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '20px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem>
//           <ListItem disablePadding>
//             <ListItemButton
//               component={Link}
//               to="/settings"
//               sx={{
//                 marginLeft:'15px',
//                 color: 'black',
//                 fontFamily: 'Open Sans, sans-serif',
//                 fontWeight: 500,
//                 fontSize: '14px',
//                 lineHeight: '20px',
//                 borderRadius: '8px',
//                 '&:hover': {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 },
//                 ...(isActive('/settings') && {
//                   backgroundColor: 'rgb(211 , 227 , 253)',
//                   borderRadius: '15px',
//                 }),
//               }}
//             >
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText
//                 primary="Settings"
//                 primaryTypographyProps={{
//                   sx: {
//                     color: 'rgb(31, 31, 31)',
//                     fontFamily: '"Google Sans", Roboto, Arial, sans-serif',
//                     fontWeight: 500,
//                     fontSize: '15px',
//                     lineHeight: '15px',
//                   },
//                 }}
//               />
//             </ListItemButton>
//           </ListItem> */}
//         </List>
//       </nav>
//     </Box>
//   );
// }
