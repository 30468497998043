// // import React, { useState } from 'react';
// // import axios from 'axios';
// // import {
// //   Container,
// //   Box,
// //   TextField,
// //   Button,
// //   CircularProgress,
// //   Typography,
// //   Card,
// //   CardContent,
// //   Grid,
// // } from '@mui/material';
// // import DatePicker from 'react-datepicker';
// // import 'react-datepicker/dist/react-datepicker.css';
// // import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';

// // const CoreReporting = () => {
// //   const [startDate, setStartDate] = useState(null);
// //   const [endDate, setEndDate] = useState(null);
// //   const [eventName, setEventName] = useState('');
// //   const [loading, setLoading] = useState(false);
// //   const [data, setData] = useState(null);
// //   const [error, setError] = useState(null);

// //   const fetchReport = async () => {
// //     setLoading(true);
// //     setError(null);

// //     try {
// //       const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
// //         params: {
// //           propertyId: '415009764',
// //           startDate: startDate ? startDate.toISOString().split('T')[0] : '30daysAgo',
// //           endDate: endDate ? endDate.toISOString().split('T')[0] : 'today',
// //           eventName: eventName || undefined,
// //         },
// //       });
// //       console.log("Core-Reporting Response:", response);
// //       setData(response.data);
// //     } catch (err) {
// //       setError('Failed to fetch data.');
// //     } finally {
// //       setLoading(false);
// //     }
// //   };

// //   const processDataForChart = (data) => {
// //     return data.rows.map((row) => {
// //       return {
// //         country: row.dimensionValues[0]?.value,
// //         city: row.dimensionValues[1]?.value,
// //         users: Number(row.metricValues[0]?.value),
// //       };
// //     });
// //   };

// //   return (
// //     <Container>
// //       <Box sx={{ marginBottom: 2 }}>
// //         <Typography variant="h5" gutterBottom>
// //           Core Reporting
// //         </Typography>
// //       </Box>
// //       <Box sx={{ display: 'flex', gap: 2, marginBottom: 4 }}>
// //         <DatePicker
// //           selected={startDate}
// //           onChange={(date) => setStartDate(date)}
// //           dateFormat="yyyy-MM-dd"
// //           placeholderText="Start Date"
// //           customInput={<TextField label="Start Date" />}
// //         />
// //         <DatePicker
// //           selected={endDate}
// //           onChange={(date) => setEndDate(date)}
// //           dateFormat="yyyy-MM-dd"
// //           placeholderText="End Date"
// //           customInput={<TextField label="End Date" />}
// //         />
// //         <TextField
// //           label="Event Name"
// //           value={eventName}
// //           onChange={(e) => setEventName(e.target.value)}
// //         />
// //         <Button variant="contained" color="primary" onClick={fetchReport}>
// //           Fetch Report
// //         </Button>
// //       </Box>
// //       {loading && <CircularProgress />}
// //       {error && <Typography color="error">{error}</Typography>}
// //       {data && (
// //         <Grid container spacing={4}>
// //           <Grid item xs={12}>
// //             <Card>
// //               <CardContent>
// //                 <Typography variant="h6">Report Data</Typography>
// //                 <ResponsiveContainer width="100%" height={400}>
// //                   <BarChart data={processDataForChart(data)}>
// //                     <CartesianGrid strokeDasharray="3 3" />
// //                     <XAxis dataKey="country" />
// //                     <YAxis />
// //                     <Tooltip />
// //                     <Legend />
// //                     <Bar dataKey="users" fill="#8884d8" />
// //                   </BarChart>
// //                 </ResponsiveContainer>
// //               </CardContent>
// //             </Card>
// //           </Grid>
// //           <Grid item xs={12}>
// //             <Card>
// //               <CardContent>
// //                 <Typography variant="h6">Raw Data</Typography>
// //                 <pre>{JSON.stringify(data, null, 2)}</pre>      
// //               </CardContent>
// //             </Card>
// //           </Grid>
// //         </Grid>
// //       )}
// //     </Container>
// //   );
// // };

// // export default CoreReporting;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
// import { LocationOn, Public, Pageview, Folder } from '@mui/icons-material';

// const CoreReporting = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId: '',
//             startDate: '30daysAgo',
//             endDate: 'today',
//             eventName: 'YOUR_EVENT_NAME',  // Optional
//           },
//         });
//         console.log("respoonse :" , response.data)
//         setData(response.data);
//         setLoading(false);
//       } catch (err) {
//         setError(err);
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <CircularProgress />;
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error.message}</Typography>;
//   }

//   return (
//     <Grid container spacing={3}>
//       {data.rows?.map((row, index) => (
//         <Grid item xs={12} sm={6} md={4} key={index}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 {row.dimensionValues[1]?.value}, {row.dimensionValues[0]?.value}  {/* City, Country */}
//               </Typography>
//               <Typography variant="body2">
//                 <LocationOn fontSize="small" /> Landing Page: {row.dimensionValues[2]?.value}
//               </Typography>
//               <Typography variant="body2">
//                 <Public fontSize="small" /> Page Location: {row.dimensionValues[4]?.value}
//               </Typography>
//               <Typography variant="body2">
//                 <Pageview fontSize="small" /> Page Path: {row.dimensionValues[5]?.value}
//               </Typography>
//               <Typography variant="body2">
//                 <Folder fontSize="small" /> File Name: {row.dimensionValues[6]?.value}
//               </Typography>
//               <Typography variant="body2">
//                 Active Users: {row.metricValues[0]?.value}
//               </Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default CoreReporting;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
// import { LocationOn, Public, Pageview, Folder } from '@mui/icons-material';

// const CoreReporting = () => {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId: '415009764',  // Replace with actual property ID
//             startDate: '30daysAgo',
//             endDate: 'today',
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         // Check if response data is structured correctly
//         if (response.data && response.data.rows) {
//           setData(response.data.rows);  // Assuming 'rows' contains the data array
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <CircularProgress />;
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   return (
//     <Grid container spacing={3}>
//       {data.map((row, index) => (
//         <Grid item xs={12} sm={6} md={4} key={index}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 {row.dimensionValues[1]?.value || 'N/A'}, {row.dimensionValues[0]?.value || 'N/A'}  {/* City, Country */}
//               </Typography>
//               <Typography variant="body2">
//                 <LocationOn fontSize="small" /> Landing Page: {row.dimensionValues[2]?.value || 'N/A'}
//               </Typography>
//               <Typography variant="body2">
//                 <Public fontSize="small" /> Page Location: {row.dimensionValues[4]?.value || 'N/A'}
//               </Typography>
//               <Typography variant="body2">
//                 <Pageview fontSize="small" /> Page Path: {row.dimensionValues[5]?.value || 'N/A'}
//               </Typography>
//               <Typography variant="body2">
//                 <Folder fontSize="small" /> File Name: {row.dimensionValues[6]?.value || 'N/A'}
//               </Typography>
//               <Typography variant="body2">
//                 Active Users: {row.metricValues[0]?.value || 'N/A'}
//               </Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// };

// export default CoreReporting;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Grid, Card, CardContent, Typography, CircularProgress } from '@mui/material';
// import { LocationOn, Public, Pageview, Folder } from '@mui/icons-material';

// const CoreReporting = () => {
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId: '415009764',  // Replace with actual property ID
//             startDate: '30daysAgo',
//             endDate: 'today',
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         // Debugging: Check the structure of the response
//         console.log("API Response:", response.data);

//         // Check if response data is structured correctly
//         if (response.data && Array.isArray(response.data.rows)) {
//           setData(response.data.rows);  // Assuming 'rows' contains the data array
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   if (loading) {
//     return <CircularProgress />;
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   return (
//     <Grid container spacing={3}>
//       {data.length > 0 ? (
//         data.map((row, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h6" gutterBottom>
//                   {row.dimensionValues[1]?.value || 'N/A'}, {row.dimensionValues[0]?.value || 'N/A'}  {/* City, Country */}
//                 </Typography>
//                 <Typography variant="body2">
//                   <LocationOn fontSize="small" /> Landing Page: {row.dimensionValues[2]?.value || 'N/A'}
//                 </Typography>
//                 <Typography variant="body2">
//                   <Public fontSize="small" /> Page Location: {row.dimensionValues[4]?.value || 'N/A'}
//                 </Typography>
//                 <Typography variant="body2">
//                   <Pageview fontSize="small" /> Page Path: {row.dimensionValues[5]?.value || 'N/A'}
//                 </Typography>
//                 <Typography variant="body2">
//                   <Folder fontSize="small" /> File Name: {row.dimensionValues[6]?.value || 'N/A'}
//                 </Typography>
//                 <Typography variant="body2">
//                   Active Users: {row.metricValues[0]?.value || 'N/A'}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))
//       ) : (
//         <Typography variant="h6">No data available</Typography>
//       )}
//     </Grid>
//   );
// };

// export default CoreReporting;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { CircularProgress, Typography, Box } from '@mui/material';

// const CoreReporting = () => {
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId: '415009764',  // Replace with actual property ID
//             startDate: '30daysAgo',
//             endDate: 'today',
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         console.log("API Response:", response.data);

//         if (response.data && Array.isArray(response.data.rows)) {
//           const formattedData = response.data.rows.map((row, index) => ({
//             id: index,  // Unique id for each row, required by DataGrid
//             city: row.dimensionValues[1]?.value || 'N/A',
//             country: row.dimensionValues[0]?.value || 'N/A',
//             landingPage: row.dimensionValues[2]?.value || 'N/A',
//             pageLocation: row.dimensionValues[4]?.value || 'N/A',
//             pagePath: row.dimensionValues[5]?.value || 'N/A',
//             fileName: row.dimensionValues[6]?.value || 'N/A',
//             activeUsers: row.metricValues[0]?.value || 'N/A',
//           }));

//           setData(formattedData);  // Set the formatted data to state
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);  // Empty dependency array ensures this effect runs only once after the initial render

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   // Define columns for the DataGrid
//   const columns = [
//     { field: 'city', headerName: 'City', flex: 1 },
//     { field: 'country', headerName: 'Country', flex: 1 },
//     { field: 'landingPage', headerName: 'Landing Page', flex: 1 },
//     { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
//     { field: 'pagePath', headerName: 'Page Path', flex: 1 },
//     { field: 'fileName', headerName: 'File Name', flex: 1 },
//     { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
//   ];

//   return (
//     <Box sx={{ height: 300, width: '100%', mt: 5 }}>
//       <DataGrid
//         rows={data}
//         columns={columns}
//         pageSize={10}
//         rowsPerPageOptions={[10, 25, 50]}
//         disableSelectionOnClick
//         autoHeight
//       />
//     </Box>
//   );
// };

// export default CoreReporting;



// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { CircularProgress, Typography, Box } from '@mui/material';
// import PropertyContext from './context/PropertyContext';  // Import the PropertyContext

// const CoreReporting = () => {
//   const { propertyId } = useContext(PropertyContext);  // Get the selected property ID from context
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!propertyId) {
//         setError('No property ID selected');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId,  // Use propertyId from context
//             startDate: '30daysAgo',
//             endDate: 'today',
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         console.log("API Response:", response.data);

//         if (response.data && Array.isArray(response.data.rows)) {
//           const formattedData = response.data.rows.map((row, index) => ({
//             id: index,  // Unique id for each row, required by DataGrid
//             city: row.dimensionValues[1]?.value || 'N/A',
//             country: row.dimensionValues[0]?.value || 'N/A',
//             landingPage: row.dimensionValues[2]?.value || 'N/A',
//             pageLocation: row.dimensionValues[4]?.value || 'N/A',
//             pagePath: row.dimensionValues[5]?.value || 'N/A',
//             fileName: row.dimensionValues[6]?.value || 'N/A',
//             activeUsers: row.metricValues[0]?.value || 'N/A',
//           }));

//           setData(formattedData);  // Set the formatted data to state
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [propertyId]);  // Dependency array now includes propertyId

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   // Define columns for the DataGrid
//   const columns = [
//     { field: 'city', headerName: 'City', flex: 1 },
//     { field: 'country', headerName: 'Country', flex: 1 },
//     { field: 'landingPage', headerName: 'Landing Page', flex: 1 },
//     { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
//     // { field: 'pagePath', headerName: 'Page Path', flex: 1 },
//     { field: 'fileName', headerName: 'File Name', flex: 1 },
//     { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
//   ];

//   return (
//     <Box sx={{ height: 300, width: '100%', mt: 5 }}>
//       <DataGrid
//         rows={data}
//         columns={columns}
//         pageSize={10}
//         rowsPerPageOptions={[10, 25, 50]}
//         disableSelectionOnClick
//         autoHeight
//       />
//     </Box>
//   );
// };

// export default CoreReporting;



// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { CircularProgress, Typography, Box, TextField, Button } from '@mui/material';
// import PropertyContext from './context/PropertyContext';  // Import the PropertyContext
// import dayjs from 'dayjs';  // For date manipulation

// const CoreReporting = () => {
//   const { propertyId } = useContext(PropertyContext);  // Get the selected property ID from context
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));  // Default to 30 days ago
//   const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));  // Default to today

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!propertyId) {
//         setError('No property ID selected');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId,  // Use propertyId from context
//             startDate,
//             endDate,
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         console.log("API Response:", response.data);

//         if (response.data && Array.isArray(response.data.rows)) {
//           const formattedData = response.data.rows.map((row, index) => ({
//             id: index,  // Unique id for each row, required by DataGrid
//             city: row.dimensionValues[1]?.value || 'N/A',
//             country: row.dimensionValues[0]?.value || 'N/A',
//             landingPage: row.dimensionValues[2]?.value || 'N/A',
//             pageLocation: row.dimensionValues[4]?.value || 'N/A',
//             pagePath: row.dimensionValues[5]?.value || 'N/A',
//             fileName: row.dimensionValues[6]?.value || 'N/A',
//             activeUsers: row.metricValues[0]?.value || 'N/A',
//           }));

//           setData(formattedData);  // Set the formatted data to state
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [propertyId, startDate, endDate]);  // Dependency array includes startDate and endDate

//   const handleDateRangeChange = () => {
//     fetchData();  // Refetch data when date range changes
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   // Define columns for the DataGrid
//   const columns = [
//     { field: 'city', headerName: 'City', flex: 1 },
//     { field: 'country', headerName: 'Country', flex: 1 },
//     { field: 'landingPage', headerName: 'Landing Page', flex: 1 },
//     { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
//     // { field: 'pagePath', headerName: 'Page Path', flex: 1 },
//     { field: 'fileName', headerName: 'File Name', flex: 1 },
//     { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
//   ];

//   return (
//     <Box sx={{ p: 3 }}>
//       <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
//         <TextField
//           label="Start Date"
//           type="date"
//           value={startDate}
//           onChange={(e) => setStartDate(e.target.value)}
//           InputLabelProps={{ shrink: true }}
//         />
//         <TextField
//           label="End Date"
//           type="date"
//           value={endDate}
//           onChange={(e) => setEndDate(e.target.value)}
//           InputLabelProps={{ shrink: true }}
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleDateRangeChange}
//           sx={{ alignSelf: 'center' }}
//         >
//           Apply Date Range
//         </Button>
//       </Box>

//       <Box sx={{ height: 600, width: '100%' }}>
//         <DataGrid
//           rows={data}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[10, 25, 50]}
//           disableSelectionOnClick
//           autoHeight
//         />
//       </Box>
//     </Box>
//   );
// };

// export default CoreReporting;



// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { CircularProgress, Typography, Box, TextField, Button, FormControl, Select, MenuItem } from '@mui/material';
// import PropertyContext from './context/PropertyContext';  // Import the PropertyContext
// import dayjs from 'dayjs';  // For date manipulation

// const CoreReporting = () => {
//   const { propertyId } = useContext(PropertyContext);  // Get the selected property ID from context
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));  // Default to 30 days ago
//   const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));  // Default to today
//   const [dateRange, setDateRange] = useState('Last 30 days');  // Default to 'Last 30 days'

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!propertyId) {
//         setError('No property ID selected');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get('https://brank.supportplus.app/analytics/v2/api/core-reporting', {
//         // const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId,  // Use propertyId from context
//             startDate,
//             endDate,
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });

//         // console.log("API Response:", response.data);

//         if (response.data && Array.isArray(response.data.rows)) {
//           const formattedData = response.data.rows.map((row, index) => ({
//             id: index,  // Unique id for each row, required by DataGrid
//             city: row.dimensionValues[1]?.value || 'N/A',
//             country: row.dimensionValues[0]?.value || 'N/A',
//             landingPage: row.dimensionValues[2]?.value || 'N/A',
//             pageLocation: row.dimensionValues[4]?.value || 'N/A',
//             pagePath: row.dimensionValues[5]?.value || 'N/A',
//             fileName: row.dimensionValues[6]?.value || 'N/A',
//             activeUsers: row.metricValues[0]?.value || 'N/A',
//           }));

//           setData(formattedData);  // Set the formatted data to state
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [propertyId, startDate, endDate]);  // Dependency array includes startDate and endDate

//   const updateDateRange = (range) => {
//     const end = dayjs().format('YYYY-MM-DD');
//     let start;

//     switch (range) {
//       case 'Today':
//         start = end;
//         break;
//       case 'Yesterday':
//         start = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 7 days':
//         start = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 28 days':
//         start = dayjs().subtract(28, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 30 days':
//         start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 3 months':
//         start = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 6 months':
//         start = dayjs().subtract(6, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 12 months':
//         start = dayjs().subtract(12, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 16 months':
//         start = dayjs().subtract(16, 'month').format('YYYY-MM-DD');
//         break;
//       default:
//         start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh" >
//         <CircularProgress/>
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   // Define columns for the DataGrid
//   const columns = [
//     { field: 'city', headerName: 'City', flex: 1 },
//     { field: 'country', headerName: 'Country', flex: 1 },
//     { field: 'landingPage', headerName: 'Landing Page', flex: 1 },
//     { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
//     { field: 'fileName', headerName: 'File Name', flex: 1 },
//     { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
//   ];

//   return (
//     <Box sx={{ p: 3 }}>
//       <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
//         <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
//           <Select
//             labelId="date-range-label"
//             id="date-range-select"
//             value={dateRange}
//             onChange={(e) => updateDateRange(e.target.value)}
//             sx={{
//               backgroundColor: 'rgb(211, 227, 253)',
//               borderRadius: '25px',
//               '& .MuiSelect-select': {
//                 padding: '10px',
//                 borderRadius: '25px',
//               },
//             }}
//           >
//             <MenuItem value="Today">Today</MenuItem>
//             <MenuItem value="Yesterday">Yesterday</MenuItem>
//             <MenuItem value="Last 7 days">Last 7 days</MenuItem>
//             <MenuItem value="Last 28 days">Last 28 days</MenuItem>
//             <MenuItem value="Last 30 days">Last 30 days</MenuItem>
//             <MenuItem value="Last 3 months">Last 3 months</MenuItem>
//             <MenuItem value="Last 6 months">Last 6 months</MenuItem>
//             <MenuItem value="Last 12 months">Last 12 months</MenuItem>
//             <MenuItem value="Last 16 months">Last 16 months</MenuItem>
//             <MenuItem value="Custom">Custom</MenuItem>
//           </Select>
//         </FormControl>
//         {dateRange === 'Custom' && (
//           <>
//             <TextField
//               label="Start Date"
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               required
//               sx={{ minWidth: 250 }}
//             />
//             <TextField
//               label="End Date"
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               required
//               sx={{ minWidth: 250, ms: 4 }}
//             />
//           </>
//         )}
//         {/* <Button
//           variant="contained"
//           color="primary"
//           onClick={() => fetchData()}  // Fetch data with the selected date range
//           sx={{ alignSelf: 'center' }}
//         >
//           Apply Date Range
//         </Button> */}
//       </Box>

//       <Box sx={{ height: 600, width: '100%' }}>
//         <DataGrid
//           rows={data}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[10, 25, 50]}
//           disableSelectionOnClick
//           autoHeight
//         />
//       </Box>
//     </Box>
//   );
// };

// export default CoreReporting;





// import React, { useEffect, useState, useContext } from 'react';
// import axios from 'axios';
// import { DataGrid } from '@mui/x-data-grid';
// import { CircularProgress, Typography, Box, TextField, Button, FormControl, Select, MenuItem } from '@mui/material';
// import PropertyContext from './context/PropertyContext';  // Import the PropertyContext
// import dayjs from 'dayjs';  // For date manipulation

// const CoreReporting = () => {
//   const { propertyId } = useContext(PropertyContext);  // Get the selected property ID from context
//   const [data, setData] = useState([]);  // Default to an empty array
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));  // Default to 30 days ago
//   const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));  // Default to today
//   const [dateRange, setDateRange] = useState('Last 30 days');  // Default to 'Last 30 days'

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!propertyId) {
//         setError('No property ID selected');
//         setLoading(false);
//         return;
//       }

//       try {
//         const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting', {
//         // const response = await axios.get('https://brank.supportplus.app/analytics/v2/api/core-reporting', {
//           params: {
//             propertyId,  // Use propertyId from context
//             startDate,
//             endDate,
//             eventName: '',  // Optional, replace with actual event name if needed
//           },
//         });
// console.log("REPONSE :" , response)
//         if (response.data && Array.isArray(response.data.rows)) {
//           const formattedData = response.data.rows.map((row, index) => ({
//             id: index,  // Unique id for each row, required by DataGrid
//             city: row.dimensionValues[1]?.value || 'N/A',
//             country: row.dimensionValues[0]?.value || 'N/A',
//             landingPage: row.dimensionValues[2]?.value || 'N/A',
//             pageLocation: row.dimensionValues[4]?.value || 'N/A',
//             pagePath: row.dimensionValues[5]?.value || 'N/A',
//             fileName: row.dimensionValues[6]?.value || 'N/A',
//             activeUsers: row.metricValues[0]?.value || 'N/A',  // Active users
//             downloads: row.metricValues[1]?.value || 'N/A',  // Downloads metric
//           }));
// console.log("formattedData :" , formattedData)
//           setData(formattedData);  // Set the formatted data to state
//         } else {
//           throw new Error('Invalid data format received from the API');
//         }

//         setLoading(false);
//       } catch (err) {
//         setError(err.message || 'Error fetching data');
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [propertyId, startDate, endDate]);  // Dependency array includes startDate and endDate

//   const updateDateRange = (range) => {
//     const end = dayjs().format('YYYY-MM-DD');
//     let start;

//     switch (range) {
//       case 'Today':
//         start = end;
//         break;
//       case 'Yesterday':
//         start = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 7 days':
//         start = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 28 days':
//         start = dayjs().subtract(28, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 30 days':
//         start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
//         break;
//       case 'Last 3 months':
//         start = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 6 months':
//         start = dayjs().subtract(6, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 12 months':
//         start = dayjs().subtract(12, 'month').format('YYYY-MM-DD');
//         break;
//       case 'Last 16 months':
//         start = dayjs().subtract(16, 'month').format('YYYY-MM-DD');
//         break;
//       default:
//         start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
//     }

//     setStartDate(start);
//     setEndDate(end);
//     setDateRange(range);
//   };

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" height="100vh" >
//         <CircularProgress/>
//       </Box>
//     );
//   }

//   if (error) {
//     return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
//   }

//   // Define columns for the DataGrid
//   const columns = [
//     { field: 'city', headerName: 'City', flex: 1 },
//     { field: 'country', headerName: 'Country', flex: 1 },
//     { field: 'landingPage', headerName: 'Landing Page', flex: 1 },
//     { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
//     { field: 'fileName', headerName: 'File Name', flex: 1 },
//     { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
//     { field: 'downloads', headerName: 'Downloads', flex: 1 },  // New column for downloads
//   ];
//   console.log("Columns :" , columns.downloads)

//   return (
//     <Box sx={{ p: 3 }}>
//       <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
//         <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
//           <Select
//             labelId="date-range-label"
//             id="date-range-select"
//             value={dateRange}
//             onChange={(e) => updateDateRange(e.target.value)}
//             sx={{
//               backgroundColor: 'rgb(211, 227, 253)',
//               borderRadius: '25px',
//               '& .MuiSelect-select': {
//                 padding: '10px',
//                 borderRadius: '25px',
//               },
//             }}
//           >
//             <MenuItem value="Today">Today</MenuItem>
//             <MenuItem value="Yesterday">Yesterday</MenuItem>
//             <MenuItem value="Last 7 days">Last 7 days</MenuItem>
//             <MenuItem value="Last 28 days">Last 28 days</MenuItem>
//             <MenuItem value="Last 30 days">Last 30 days</MenuItem>
//             <MenuItem value="Last 3 months">Last 3 months</MenuItem>
//             <MenuItem value="Last 6 months">Last 6 months</MenuItem>
//             <MenuItem value="Last 12 months">Last 12 months</MenuItem>
//             <MenuItem value="Last 16 months">Last 16 months</MenuItem>
//             <MenuItem value="Custom">Custom</MenuItem>
//           </Select>
//         </FormControl>
//         {dateRange === 'Custom' && (
//           <>
//             <TextField
//               label="Start Date"
//               type="date"
//               value={startDate}
//               onChange={(e) => setStartDate(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               required
//               sx={{ minWidth: 250 }}
//             />
//             <TextField
//               label="End Date"
//               type="date"
//               value={endDate}
//               onChange={(e) => setEndDate(e.target.value)}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               required
//               sx={{ minWidth: 250, ms: 4 }}
//             />
//           </>
//         )}
//       </Box>

//       <Box sx={{ height: 600, width: '100%' }}>
//         <DataGrid
//           rows={data}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[10, 25, 50]}
//           disableSelectionOnClick
//           autoHeight
//         />
//       </Box>
//     </Box>
//   );
// };

// export default CoreReporting;



import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Typography, Box, TextField, Button, FormControl, Select, MenuItem } from '@mui/material';
import PropertyContext from './context/PropertyContext';  // Import the PropertyContext
import dayjs from 'dayjs';  // For date manipulation

const CoreReporting = () => {
  const { propertyId } = useContext(PropertyContext);  // Get the selected property ID from context
  const [data, setData] = useState([]);  // Default to an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('YYYY-MM-DD'));  // Default to 30 days ago
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));  // Default to today
  const [dateRange, setDateRange] = useState('today');  // Default to 'Last 30 days'

  useEffect(() => {
    const fetchData = async () => {
      if (!propertyId) {
        setError('No property ID selected');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('http://localhost:8080/analytics/v2/api/core-reporting-db', {
          params: {
            propertyId,  // Use propertyId from context
            startDate,
            endDate,
          },
        });

        if (response.data && Array.isArray(response.data.data)) {
          const formattedData = response.data.data.map((row, index) => ({
            id: index,  // Unique id for each row, required by DataGrid
            city: row.city || 'N/A',
            country: row.country || 'N/A',
            unifiedScreenName: row.unifiedScreenName || 'N/A',
            pageLocation: row.pageLocation || 'N/A',
            fileName: row.fileName || 'N/A',
            activeUsers: row.activeUsers || 0,
            sessions: row.sessions || 0,
            newUsers: row.newUsers || 0,
            sessionsPerUser: row.sessionsPerUser || 0,
            filedownload: row.eventCount || 0,
            engagementRate: row.engagementRate || 0,
          }));

          setData(formattedData);  // Set the formatted data to state
        } else {
          throw new Error('Invalid data format received from the API');
        }

        setLoading(false);
      } catch (err) {
        setError(err.message || 'Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, [propertyId, startDate, endDate]);  // Dependency array includes startDate and endDate

  const updateDateRange = (range) => {
    const end = dayjs().format('YYYY-MM-DD');
    let start;

    switch (range) {
      case 'Today':
        start = end;
        break;
      case 'Yesterday':
        start = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
        break;
      case 'Last 7 days':
        start = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
        break;
      case 'Last 28 days':
        start = dayjs().subtract(28, 'day').format('YYYY-MM-DD');
        break;
      case 'Last 30 days':
        start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
        break;
      case 'Last 3 months':
        start = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
        break;
      case 'Last 6 months':
        start = dayjs().subtract(6, 'month').format('YYYY-MM-DD');
        break;
      case 'Last 12 months':
        start = dayjs().subtract(12, 'month').format('YYYY-MM-DD');
        break;
      case 'Last 16 months':
        start = dayjs().subtract(16, 'month').format('YYYY-MM-DD');
        break;
      default:
        start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
    }

    setStartDate(start);
    setEndDate(end);
    setDateRange(range);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" >
        <CircularProgress/>
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">Error fetching data: {error}</Typography>;
  }

  // Define columns for the DataGrid
  const columns = [
    { field: 'city', headerName: 'City', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'unifiedScreenName', headerName: 'Unified Screen Name', flex: 1 },
    { field: 'pageLocation', headerName: 'Page Location', flex: 1 },
    { field: 'fileName', headerName: 'File Name', flex: 1 },
    { field: 'activeUsers', headerName: 'Active Users', flex: 1 },
    { field: 'sessions', headerName: 'Sessions', flex: 1 },
    { field: 'newUsers', headerName: 'New Users', flex: 1 },
    { field: 'sessionsPerUser', headerName: 'Sessions Per User', flex: 1 },
    { field: 'filedownload', headerName: 'File Download', flex: 1 },
    { field: 'engagementRate', headerName: 'Engagement Rate', flex: 1 },
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
        <FormControl sx={{ minWidth: 200, borderRadius: '25px', padding: '0 10px', my: 2 }}>
          <Select
            labelId="date-range-label"
            id="date-range-select"
            value={dateRange}
            onChange={(e) => updateDateRange(e.target.value)}
            sx={{
              backgroundColor: 'rgb(211, 227, 253)',
              borderRadius: '25px',
              '& .MuiSelect-select': {
                padding: '10px',
                borderRadius: '25px',
              },
            }}
          >
            <MenuItem value="Today">Today</MenuItem>
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
            <MenuItem value="Last 28 days">Last 28 days</MenuItem>
            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
            <MenuItem value="Last 3 months">Last 3 months</MenuItem>
            <MenuItem value="Last 6 months">Last 6 months</MenuItem>
            <MenuItem value="Last 12 months">Last 12 months</MenuItem>
            <MenuItem value="Last 16 months">Last 16 months</MenuItem>
            <MenuItem value="Custom">Custom</MenuItem>
          </Select>
        </FormControl>
        {dateRange === 'Custom' && (
          <>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{ minWidth: 250 }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{ minWidth: 250, ms: 4 }}
            />
          </>
        )}
      </Box>

      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          disableSelectionOnClick
          autoHeight
        />
      </Box>
    </Box>
  );
};

export default CoreReporting;
