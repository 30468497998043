// import React from 'react';
// import image from './404.jpg';

// const NotFoundPage = () => {
//   const backgroundImageUrl = {image}

//   return (
//     <div style={styles.container(backgroundImageUrl)}>
//       <h1 style={styles.heading}>404</h1>
//       <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
//       <a href="/" style={styles.homeLink}>Go Back to Home</a>
//     </div>
//   );
// };

// const styles = {
//   container: (backgroundImageUrl) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundImage: `url(${backgroundImageUrl})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     color: 'white',
//     textAlign: 'center',
//     textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
//   }),
//   heading: {
//     fontSize: '96px',
//     fontWeight: 'bold',
//     marginBottom: '20px',
//   },
//   message: {
//     fontSize: '24px',
//     marginBottom: '40px',
//   },
//   homeLink: {
//     fontSize: '20px',
//     color: '#ffeb3b',
//     textDecoration: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     transition: 'background-color 0.3s',
//   },
//   homeLinkHover: {
//     backgroundColor: 'rgba(0, 0, 0, 0.7)',
//   },
// };

// export default NotFoundPage;



// import React from 'react';
// import image from './image.jpg';

// const NotFoundPage = () => {
//   return (
//     <div style={styles.container(image)}>
//       <h1 style={styles.heading}>404</h1>
//       <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
//       <a href="/" style={styles.homeLink}>Go Back to Home</a>
//     </div>
//   );
// };

// const styles = {
//   container: (backgroundImageUrl) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundImage: `url(${backgroundImageUrl})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     color: 'white',
//     textAlign: 'center',
//     textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
//   }),
//   heading: {
//     fontSize: '96px',
//     fontWeight: 'bold',
//     marginBottom: '20px',
//   },
//   message: {
//     fontSize: '24px',
//     marginBottom: '40px',
//   },
//   homeLink: {
//     fontSize: '20px',
//     color: '#ffeb3b',
//     textDecoration: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     transition: 'background-color 0.3s',
//   },
// };

// export default NotFoundPage;



// import React from 'react';
// import image from './image.jpg';

// const NotFoundPage = () => {
//   return (
//     <div style={styles.container(image)}>
//       <h1 style={styles.heading}>404</h1>
//       <p style={styles.message}>Oops! The page you're looking for is currently a work in progress.</p>
//       <p style={styles.inspirationalMessage}>
//   Take a deep breath and enjoy the beauty around you. Remember, even unexpected detours can lead to wonderful discoveries. Stay positive, keep smiling—your smile is as beautiful as the nature that surrounds you.
// </p>
//       <a href="/" style={styles.homeLink}>Go Back to Home</a>
//     </div>
//   );
// };

// const styles = {
//   container: (backgroundImageUrl) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     height: '100vh',
//     backgroundImage: `url(${backgroundImageUrl})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     color: 'white',
//     textAlign: 'center',
//     textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
//     padding: '0 20px', // Add some padding for better text visibility on narrow screens
//   }),
//   heading: {
//     fontSize: '96px',
//     fontWeight: 'bold',
//     marginBottom: '20px',
//   },
//   message: {
//     fontSize: '24px',
//     marginBottom: '20px',
//   },
//   inspirationalMessage: {
//     fontSize: '20px',
//     marginBottom: '40px',
//     fontStyle: 'italic',
//     maxWidth: '600px', // Limit the width to make it more readable
//   },
//   homeLink: {
//     fontSize: '20px',
//     color: '#ffeb3b',
//     textDecoration: 'none',
//     padding: '10px 20px',
//     borderRadius: '5px',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     transition: 'background-color 0.3s',
//   },
// };

// export default NotFoundPage;



import React from 'react';
import image from './image.jpg';

const NotFoundPage = () => {
  return (
    <div style={styles.container(image)}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.message}>Oops! The page you're looking for is currently a work in progress.</p>
      <p style={styles.inspirationalMessage}>
        Take a deep breath and enjoy the beauty around you. Remember, even unexpected detours can lead to wonderful discoveries. Stay positive, keep smiling—your smile is as beautiful as the nature that surrounds you.
      </p>
      <a href="/" style={styles.homeLink}>Go Back to Home</a>
    </div>
  );
};

const styles = {
  container: (backgroundImageUrl) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    textAlign: 'center',
    textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
    padding: '0 20px',
    paddingTop: '30vh', // Push content down to show more of the background image
  }),
  heading: {
    fontSize: '96px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  message: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  inspirationalMessage: {
    fontSize: '20px',
    marginBottom: '40px',
    fontStyle: 'italic',
    maxWidth: '600px',
  },
  homeLink: {
    fontSize: '20px',
    color: '#ffeb3b',
    textDecoration: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'background-color 0.3s',
  },
};

export default NotFoundPage;
