import React, { useState, useContext } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  Modal,
  TextField,
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PropertyContext from './context/PropertyContext';

const PropertyDropdown = () => {
  const {
    propertyName,
    setPropertyName,
    propertyId,
    setPropertyId,
    properties,
    addProperty,
    fetchPropertyById,
  } = useContext(PropertyContext);
  
  const [openModal, setOpenModal] = useState(false);
  const [newPropertyName, setNewPropertyName] = useState('');
  const [newPropertyId, setNewPropertyId] = useState('');

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewPropertyName('');
    setNewPropertyId('');
  };

  const handleSaveProperty = async () => {
    if (newPropertyName.trim() === '' || newPropertyId.trim() === '') {
      alert('Please enter both property name and property ID.');
      return;
    }

    await addProperty(newPropertyName, newPropertyId);
    handleCloseModal();
  };

  const handleChange = async (e) => {
    const selectedPropertyName = e.target.value;
    const selectedProperty = properties.find(p => p.propertyName === selectedPropertyName);

    if (selectedProperty) {
      setPropertyName(selectedProperty.propertyName);
      setPropertyId(selectedProperty.propertyId);
      await fetchPropertyById(selectedProperty.propertyId); // Fetch property details
    } else {
      console.error('Selected property not found.');
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          value={propertyName}
          onChange={handleChange}
          displayEmpty
          sx={{
            border: '1px solid black',
            borderRadius: '25px',
            padding: '0 10px',
            '& .MuiSelect-select': {
              padding: '10px',
              borderRadius: '25px',
              fontFamily: 'Google Sans, Roboto, Arial, sans-serif',
              fontWeight: 500,
              color: 'rgb(31, 31, 31)',
              fontSize: '14px',
              lineHeight: '20px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover': {
              borderColor: 'black',
            },
          }}
          renderValue={(selected) => selected || 'Select Property'}
        >
          {properties.length > 0 ? (
            properties.map((property) => (
              <MenuItem key={property.propertyId} value={property.propertyName}>
                {property.propertyName}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No properties available</MenuItem>
          )}
          <Divider />
          <MenuItem onClick={handleOpenModal}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Property" />
          </MenuItem>
        </Select>
      </FormControl>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '10px',
          }}
        >
          <Typography variant="h6" component="h2">
            Add New Property
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Property Name"
            value={newPropertyName}
            onChange={(e) => setNewPropertyName(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Property ID"
            value={newPropertyId}
            onChange={(e) => setNewPropertyId(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSaveProperty}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default PropertyDropdown;
